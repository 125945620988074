import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

const ProductPageQuery = gql`
  query HomepageQuery {
    entry(type:Homepage) {
      id

      ...on Homepage {
        featuredContent {
          ...on FeaturedContentExternalContent {
            id
            description

            issue {
              id
              slug
              title
            }

            image {
              id
              title
              url
            }

            externalUrl
            externalName
            externalType
          }

          ...on FeaturedContentEntry {
            entry {
              __typename
              id
              postDate
              slug
              title
              uri

              ...on Events {
                eventStartDate
                shortDescription

                eventIssue {
                  id
                  slug
                  title
                }

                eventPhotos {
                  id
                  url
                  title
                }
              }

              ...on InTheNews {
                authors {
                  __typename
                  id
                  slug
                  title

                  ...on Staff {
                    ...Staff
                  }

                  ...on ExternalAuthors {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }

                blurb
                postDate
                publicationName
                publicationLogo {
                  url
                  id
                }
                newsItemUrl
                newsItemFeaturedImage {
                  id
                  url
                  title
                }

                issues {
                  id
                  slug
                  title
                }
              }

              ...on Products {
                productBlurb
                productReadingTime

                productAuthors {
                  __typename
                  id
                  slug
                  title

                  ...on Staff {
                    ...Staff
                  }

                  ...on ExternalAuthors {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }

                productFeaturedImage {
                  id
                  url
                  title
                }

                productFeaturedImageUrl

                productIssue {
                  id
                  slug
                  title
                }

                productType {
                  id
                  slug
                  title
                }
              }

              ... on Series {
                seriesFeaturedImage {
                  id
                  url
                  title
                }
              }
            }
          }
        }
      }
    }
  }

  ${StaffFragment}
`;

export default ProductPageQuery;
