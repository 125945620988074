import React from 'react';
import { graphql } from 'react-apollo';
import { Redirect } from 'react-router-dom';

import FellowshipComponent from '../../components/Fellowship';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import dataShape from '../../shapes/dataShape';
import query from './query';

const Fellowship = ({ data }) => {
  const { error, fellowship, loading } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!fellowship) { return (<Redirect to="/about/fellows-program" />); }

  return (
    <FellowshipComponent fellowship={fellowship} />
  );
};

Fellowship.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ slug }) => ({
    variables: {
      slug,
    },
  }),
})(Fellowship);
