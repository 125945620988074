import gql from 'graphql-tag';

export default gql`
  query ImpactPageQuery {
    entries(section: impact) {
      id
      slug
      ...on Impact {
        impactShortTitle
      }
    }
  }
`;
