import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

import EntryCard from '../../EntryCard';
import TopicLabel from '../../TopicLabel';

const ContentRow = ({ items, heading, url }) => {
  if (!items) { return null; }
  const { title, size = "large" } = heading;

  return (
    <span className="ContentRow">
      {title &&
        <Row>
          <Col xs={12}>
            {size === "large" &&
              <h1 className="mb-4">{title}</h1>
            }
            {size === "small" &&
              <TopicLabel
                className="mb-4"
                name={title}
              />
            }
          </Col>
        </Row>
      }

      <Row>
        {items.map((item, i) => (
          <Col xs={12} lg={4} key={i}>
            <EntryCard item={item} large />
          </Col>
        ))}
      </Row>

      {url &&
        <Row>
          <Col xs={12}>
            <Link to={url} className="label text-uppercase mb-5">
              See All&hellip;
            </Link>
          </Col>
        </Row>
      }
    </span>
  );
};

export default ContentRow;
