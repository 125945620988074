import moment from 'moment-timezone';
import momentDurationFormat from 'moment-duration-format';

momentDurationFormat(moment);

// duration should be in seconds
const formatDuration = (duration) => {
  if (!duration) { return false; }

  const readingTime = parseInt(duration, 0);
  const minReadingTime = readingTime < 30 ? 30 : readingTime;
  return minReadingTime > 1
    ? moment
        .duration(minReadingTime, 'seconds')
        .format('h [hour], m [minute]')
        .replace(/s/g, '')
    : null;
};

export default formatDuration;
