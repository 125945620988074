import React from 'react';
import { graphql } from 'react-apollo';
import get from 'lodash-es/get';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import PressReleasePageComponent from '../../components/PressReleasePage';
import query from './query';
import { itemsFromRelated } from '../../utils/itemFromEntry';

const PressReleasePage = ({ data }) => {
  const { contactInfo, error, loading, pressInfo, pressRelease } = data;

  let related;

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!pressRelease) {
    return <NotFoundPage />;
  }

  if (pressRelease.relatedProducts && pressRelease.relatedProducts.length > 0) {
    related = itemsFromRelated(pressRelease.relatedProducts);
  }

  return (
    <PressReleasePageComponent
      contactInfo={get(contactInfo, 'companyInfo', {})}
      pressInfo={get(pressInfo, 'companyInfo.pressContact', [])}
      pressRelease={pressRelease}
      related={related}
    />
  );
};

export default graphql(query, {
  options: (ownProps) => ({
    variables: { slug: String(ownProps.match.params.slug) },
  }),
})(PressReleasePage);
