import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tab.css';

class Tab extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    tabIndex: PropTypes.number,
    active: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  handleClick() {
    this.props.onSelect(this.props.tabIndex);
  }

  render() {
    const { active, className } = this.props;

    return (
      <div
        className={classNames('Tab', { active }, className)}
        onClick={(e) => {
          e.preventDefault();
          if (!active) {
            this.handleClick();
          }
        }}
      >
        <h3>{this.props.title}</h3>
      </div>
    );
  }
}

export default Tab;
