import gql from 'graphql-tag';

export default gql`
  query aboutQuery {
    aboutContent: entry(section: about) {
      ... on About {
        aboutBlock {
          __typename
          ... on AboutBlockAboutBlock {
            blockTitle
            blockText {
              content
            }
            blockImage {
              url
            }
            blockMediaAlignment
            blockYoutubeId
          }
        }
      }
    }
  }
`;
