import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppSearchComponent from '../../components/AppSearch';
import withSearch from '../../wrappers/withSearch';

class AppSearch extends Component {
  static propTypes = {
    onSearchClose: PropTypes.func,
    onSearchOpen: PropTypes.func,
  };

  constructor() {
    super();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.setSearchInputRef = this.setSearchInputRef.bind(this);

    this.state = {
      inputOpen: false,
      searchQuery: '',
    };
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (!this.state.inputOpen) { return null; }

    if (this.searchInputRef && !this.searchInputRef.contains(event.target)) {
      this.closeMenu();
    }
  }

  onChange(event) {
    event.preventDefault();
    this.setState({ searchQuery: event.target.value });
  }

  onSubmit(event) {
    const { search } = this.props;
    const { searchQuery } = this.state;

    event.preventDefault();

    if (!searchQuery) { return null; }

    this.closeMenu();
    this.setState({ searchQuery: '' });
    return search.onSearchQueryChange(searchQuery, '/search');
  }

  setSearchInputRef(node) {
    this.searchInputRef = node;
  }

  closeMenu() {
    this.setState({ inputOpen: false });
    if (this.props.onSearchClose) { this.props.onSearchClose(); }
  }

  openMenu() {
    this.setState({ inputOpen: true }, () => {
      if (this.searchInputRef) {
        this.searchInputRef.focus();
      }
    });

    if (this.props.onSearchOpen) { this.props.onSearchOpen(); }
  }

  render() {
    const { inputOpen, searchQuery } = this.state;

    return (
      <AppSearchComponent
        inputOpen={inputOpen}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        openMenu={this.openMenu}
        searchQuery={searchQuery}
        setSearchInputRef={this.setSearchInputRef}
      />
    );
  }
};

export default withSearch(AppSearch);
