import React from 'react';
import { graphql } from 'react-apollo';
import findIndex from 'lodash-es/findIndex';
import has from 'lodash-es/has';

import SeriesSideNavComponent from '../../components/SeriesSideNav';
import SeriesBarComponent from '../../components/SeriesBar';

import query from './query';

const SeriesSideNav = ({ data, id, bannerStyle = false }) => {
  const { series, loading } = data;

  // We are returned an array for series, but we can only show the first series
  // association Also, most pages wont be part of a series so no point in
  // showing that we are loading data.
  if (loading) {
    return null;
  }

  if (
    !has(series, '[0]') ||
    !has(series, '[0].relatedProducts') ||
    !has(series, '[0].title') ||
    !has(series, '[0].uri')
  ) {
    return null;
  }

  const currentIndex = findIndex(series[0].relatedProducts, (o) => {
    return o.id === id;
  });

  const items = series[0].relatedProducts;

  return (
    <span>
      {!bannerStyle && (
        <SeriesSideNavComponent
          items={items}
          series={series[0]}
          selectedId={id}
        />
      )}
      {bannerStyle && series && (
        <SeriesBarComponent currentIndex={currentIndex} series={series[0]} />
      )}
    </span>
  );
};

export default graphql(query, {
  options: ({ id }) => ({ variables: { id } }),
})(SeriesSideNav);
