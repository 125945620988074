import gql from 'graphql-tag';

export default gql`
  query PressPageResults(
    $limit: Int,
    $offset: Int,
    $relatedElement: [RelatedToInputType],
    $section: [SectionsEnum],
  ) {
    entriesConnection(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc"
      postDate: "> 0",
      public: true,
      relatedTo: $relatedElement,
      section: $section,
    ) {
      totalCount
      results: entries {
        id
        postDate
        title
        slug

        section {
          id
          name
        }

        ...on PressReleases {
          postDate
          issues {
            title
            id
          }
        }

        ...on InTheNews {
          publicationName
          newsItemUrl

          newsItemFeaturedImage {
            id
            url
            title
          }

          publicationLogo {
            id
            url
            title
          }

          issues {
            title
            id
          }
        }
      }
    }
  }
`;
