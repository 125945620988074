import gql from 'graphql-tag';

const MapPageRelatedMaterialsQuery = gql`
query MapPageRelatedMaterialsQuery($relatedElement: [RelatedToInputType]) {
  entries(section: [products, events, externalContent, pressReleases, inTheNews], relatedTo: $relatedElement) {
    ...on Products {
      title
      uri
      postDate
      productFeaturedImageUrl
      productPublicationUpdateDate
      productReadingTime

      productFeaturedImage {
        id
        title
        url
      }
      productType {
        slug
        id
        title
      }
    }

    ...on Events {
      title
      uri

      eventPhotos {
        id
        url
        title
      }
    }

    ...on PressReleases {
      title
      uri
      postDate
    }

    ...on ExternalContentDefault {
      externalName: title
      externalUrl: newsItemUrl
      externalType: publicationName

      image: newsItemFeaturedImage {
        url
        id
      }
    }

    ...on InTheNews {
      title
      uri: newsItemUrl
      publicationName

      newsItemFeaturedImage {
        url
        id
      }

      publicationLogo {
        url
        id
      }
    }
  }
}`;

export default MapPageRelatedMaterialsQuery;
