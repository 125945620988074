import React from 'react';
import classNames from 'classnames';

import './Divider.css';

/**
 * A page divider.
 */
const Divider = (props) => {
  const classes = classNames('Divider', props.className);

  return(
    <div className={classes}>
      <hr />
    </div>
  );
}

export default Divider;
