import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import AboutPagePress from '../../containers/AboutPagePress';
import AboutPageBlock from './AboutPageBlock'
import Divider from '../Divider';

const AboutPageLanding = ({ blocks }) => (
  <div className="AboutPageLanding">

    {blocks.map((block, i) => (
      <AboutPageBlock {...block} key={i} />
    ))}

    <Row className="hidden visible-lg">
      <Col xs={12}>
        <Divider className="mb-5" />
      </Col>
    </Row>

    <AboutPagePress />
  </div>
);

export default AboutPageLanding;
