import React from 'react';
import Imgix from 'react-imgix';

import fallbackImage from '../../utils/fallbackImage';

import './SeriesPageFeaturedImage.css';

const SeriesPageFeaturedImage = ({ series }) => {
  if (!series) { return null; }

  let imageTitle, imageUrl;

  if (series.seriesFeaturedImage[0]) {
    imageTitle = series.seriesFeaturedImage[0].title;
    imageUrl = series.seriesFeaturedImage[0].url;
  } else {
    imageTitle = series.title;
    imageUrl = fallbackImage(series.productIssue ? series.productIssue.map(issue => issue.slug) : []);
  }

  return (
    <div className="SeriesPageFeaturedImage">
        <Imgix
          aggressiveLoad={false}
          width={2500}
          height={1000}
          imgProps={{
            alt: imageTitle,
            sizes: "100vw",
          }}
          crop="center"
          src={imageUrl}
        />
    </div>
  );
};

export default SeriesPageFeaturedImage;
