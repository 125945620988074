import React from 'react';
import classNames from 'classnames';
import SocialLink from '../SocialLink';

import './SocialLinks.css';

const SocialLinks = ({ className, facebook, instagram, large = false, linkedin, twitter, youtube, showTitles = false }) => (
  <div className={classNames('SocialLinks', className)}>
    {twitter &&
      <SocialLink
        className={classNames('twitter', { large })}
        icon="twitter"
        title={showTitles ? `@${twitter}` : null}
        url={`https://twitter.com/${twitter}`}
      />
    }

    {instagram &&
      <SocialLink
        className={classNames('instagram', { large })}
        icon="instagram"
        title={showTitles ? `@${instagram}` : null}
        url={`https://instagram.com/${instagram}`}
      />
    }

    {facebook &&
      <SocialLink
        className={classNames('facebook', { large })}
        icon="facebook-f"
        title={showTitles ? facebook : null}
        url={`https://www.facebook.com/${facebook}`}
      />
    }

    {linkedin &&
      <SocialLink
        className={classNames('linkedin', { large })}
        icon="linkedin-in"
        title={showTitles ? linkedin : null}
        url={`https://www.linkedin.com/company/${linkedin}`}
      />
    }

    {youtube &&
      <SocialLink
        className={classNames('youtube', { large })}
        icon="youtube"
        title={showTitles ? youtube : null}
        url={`https://youtube.com/user/${youtube}`}
      />
    }
  </div>
);

export default SocialLinks;
