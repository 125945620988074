import React from 'react';
import { filter, find } from 'lodash';
import { graphql } from 'react-apollo';
import withContentFilters from '../../wrappers/withContentFilters';
import dataShape from '../../shapes/dataShape';
import { itemsFromEntries } from '../../utils/itemFromEntry';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import SeriesPageComponent from '../../components/SeriesPage';
import withIssueFilter from '../../wrappers/withIssueFilter';

import query from './query';

function filterByIssue(items, issueId) {
  return filter(items, (item) => find(item.itemIssues, (issue) => issue.id === issueId));
}

function filterByContentType(items, contentType) {
  return items.filter((item) => item.typename.toLowerCase() === contentType.toLowerCase());
}

const SeriesPage = ({ data, issueFilter, contentTypeFilter, authorFilter }) => {
  const { entry, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (!entry) { return (<NotFoundPage />); }

  let items = itemsFromEntries(entry.relatedProducts);

  const groups = [];

  data.entry.seriesGroups.forEach((group, i) => {
    const itemGroup = {...group};

    itemGroup.relatedProducts = itemsFromEntries([...group.relatedProducts]);

    const groupTeam = itemGroup.relatedProducts.map((product) => product.itemAuthors);

    groups.push(itemGroup);
  });

  if (issueFilter.currentIssueID) {
    if (groups.length) {
      groups.forEach((group) => {
        group.relatedProducts = filterByIssue(group.relatedProducts, issueFilter.currentIssueID);
      });
    } else if (items.length) {
      items = filterByIssue(items, issueFilter.currentIssueID);
    }
  }

  if (contentTypeFilter.currentContentType) {
    if (groups.length) {
      groups.forEach((group) => {
        group.relatedProducts = filterByContentType(group.relatedProducts, contentTypeFilter.currentContentType.toLowerCase());
      });
    } else {
      items = filterByContentType(items, contentTypeFilter.currentContentType.toLowerCase());
    }
  }

  return (
    <SeriesPageComponent
      items={items}
      groups={groups}
      team={data.entry.authors}
      series={data.entry}
    />
  );
};

SeriesPage.propTypes = {
  data: dataShape,
};

export default withContentFilters(
  withIssueFilter(
    graphql(query, {
      options: ({ match: { params } }) => ({
        variables: { slug: params.slug },
      }),
    })(SeriesPage),
  )
);
