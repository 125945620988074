import gql from 'graphql-tag';

export default gql`
  query AboutPageBioEntriesQuery(
    $limit: Int,
    $offset: Int,
    $relatedElement: [RelatedToInputType],
    $section: [SectionsEnum],
    $slug: String,
  ) {
    entriesConnection(slug: $slug, type: Staff) {
      entries {
        __typename
        id
        slug
        title
      }
      edges {
        relatedEntries(
          limit: $limit,
          offset: $offset
          orderBy: "postDate desc",
          public: true,
          relatedTo: $relatedElement,
          section: $section,
        ) {
          totalCount

          entries {
            id
            postDate
            slug
            title
            uri

            ...on Products {
              productReadingTime
              productIssue {
                id
                slug
                title
              }

              productFeaturedImage {
                url
                id
              }

              productFeaturedImageUrl

              productType {
                id
                slug
                title
              }

              productAuthors {
                ...authors
              }
            }

            ...on Series {
              seriesBlurb

              seriesFeaturedImage {
                url
                id
              }

              issues {
                slug
                title
                id
              }

              authors {
                ...authors
              }
            }

            ...on InTheNews {
              publicationName
              newsItemUrl

              newsItemFeaturedImage {
                url
                id
              }

              publicationLogo {
                url
                id
              }

              issues {
                title
                id
              }
            }
          }
        }
      }
    }
  }

  fragment authors on EntryInterface {
    __typename
    id
    title
    uri
    ...staff
    ...external
  }

  fragment staff on Staff {
    position
    photo {
      url
      id
    }
  }

  fragment external on ExternalAuthors {
    id
    position
    photo {
      url
      id
    }
  }
`;
