import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Modal.css';

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    highlight: PropTypes.bool,
    label: PropTypes.string,
    onClickOutside: PropTypes.func,
  };

  static defaultProps = {
    onClickOutside: () => { },
    highlight: false,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.getElementById('modal-root').appendChild(this.el);
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.getElementById('modal-root').removeChild(this.el);
    document.body.style.overflow = 'unset';
  }

  handleClickOutside(event) {
    const { onClickOutside } = this.props;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      onClickOutside(event);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    const { highlight } = this.props;

    return ReactDOM.createPortal(
      <div className={classNames('Modal', this.props.className)} aria-modal="true" role="dialog" aria-label={this.props.label}>
        <div className="modal-overlay"></div>
        <div ref={this.setWrapperRef} className={classNames("modal-container", { highlight })}>
          {this.props.children}
        </div>
      </div>,
      this.el,
    );
  }
}

export default Modal;
