import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Error = ({ error }) => {
  console.log('An error occurred.', error);

  return(
    <div className="Error">
      <Grid fluid className="py-5">
        <Row>
          <Col xs={12}>
            <h1>Error.</h1>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default Error;
