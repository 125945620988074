import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './OpportunitiesNav.css';

const OpportunitiesNav = ({ className }) => (
  <div className={classNames('OpportunitiesNav', className)}>
    <NavLink
      to={{ pathname: '/careers', state: { noScroll: true } }}
      activeStyle={{ order: '-1' }}
    >
      Careers
    </NavLink>
    <NavLink
      to={{ pathname: '/about/fellows-program', state: { noScroll: true } }}
      activeStyle={{ order: '-1' }}
    >
      Fellows Program
    </NavLink>
    <NavLink
      to={{ pathname: '/internships', state: { noScroll: true } }}
      activeStyle={{ order: '-1' }}
    >
      Internships
    </NavLink>
    <NavLink
      to={{ pathname: '/culture', state: { noScroll: true } }}
      activeStyle={{ order: '-1' }}
    >
      Culture at Third Way
    </NavLink>
  </div>
);

export default OpportunitiesNav;
