import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import Content from '../Content';

import './ProductEndnotes.css';

const ProductEndnotes = ({ endnotes, endnotesOpen, toggleEndnotes }) => (
  <div className="ProductEndnotes mt-5">
    <h4
      className={classNames({ 'mb-3': endnotesOpen })}
      onClick={toggleEndnotes}
    >
      Endnotes
      <FontAwesomeIcon
        icon={['fas', 'caret-right']}
        size="lg"
        className={classNames('icon-caret-right', { active: endnotesOpen })}
      />
    </h4>

    <Content
      content={endnotes}
      sanitizeContent
      className={classNames({ active: endnotesOpen })}
    />
  </div>
);

export default ProductEndnotes;
