import React, { Component } from 'react';

import AppHeader from '../AppHeader';
import AppFooter from '../AppFooter';
import AppMenu from '../../containers/AppMenu';

import './Layout.css';

class Layout extends Component {
  render() {
    return (
      <div className="Layout">
        <AppHeader />

        <div className="main-content">
          {this.props.children}
        </div>

        <AppMenu>
          <AppFooter />
        </AppMenu>
      </div>
    );
  }
}

export default Layout;
