import gql from 'graphql-tag';

export default gql`
  {
    entry(section: about) {
      ... on About {
        inTheNews: aboutPressFeatured {
          __typename
          id
          postDate
          slug
          title
          ... on InTheNews {
            newsItemUrl
            publicationName
            newsItemFeaturedImage {
              id
              url
              title
            }
            publicationLogo {
              id
              url
              title
            }
            issues {
              id
              slug
              title
            }
          }
        }
      }
    }
  }
`;
