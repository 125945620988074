import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import Button from '../Button';
import Highlight from '../Highlight';
import SubscribeModal from '../SubscribeModal';
import noop from '../../utils/noop';

import './SubscribeBox.css';

class SubscribeBox extends Component {
  static defaultProps = {
    email: '',
    handleShow: noop,
    handleHide: noop,
    heading: 'Subscribe',
    description: 'Get updates whenever new content is added. We\'ll never share your email with anyone.',
    iframeUrl: null,
    onClickOutside: noop,
    size: 'full',
  };

  static propTypes = {
    description: PropTypes.string,
    email: PropTypes.string,
    handleShow: PropTypes.func,
    handleHide: PropTypes.func,
    heading: PropTypes.string,
    iframeUrl: PropTypes.string,
    onChangeEmail: PropTypes.func.isRequired,
    onClickOutside: PropTypes.func,
    showingModal: PropTypes.bool.isRequired,
    small: PropTypes.string,
  };

  render() {
    const {
      content,
      className,
      description,
      email,
      handleShow,
      handleHide,
      heading,
      iframeUrl,
      onChangeEmail,
      onClickOutside,
      showingModal,
      size,
    } = this.props;

    return (
      <div
        className={classNames('SubscribeBox', className, `SubscribeBox--${size}`,)}
      >
        <Highlight>
          <div className="content">
            <Row>
              <Col xs={12} lg={size === 'full' ? 7 : 12}>
                <div className="SubscribeBox__header">
                  <h3 className="SubscribeBox__title">{heading}</h3>
                  <p className="SubscribeBox__description">
                    {description}
                  </p>
                </div>
              </Col>
              <Col xs={12} lg={size === 'full' ? 5 : 12}>
                <Row>
                  {size !== 'compact' && (
                    <Col xs={12} xl={7}>
                      <label htmlFor="" className="form-label sr-only">
                        Email Address
                      </label>
                      <input
                        type="text"
                        autoComplete="email"
                        className="SubscribeBox__input"
                        value={email}
                        onChange={onChangeEmail}
                        placeholder="Email address"
                        />
                    </Col>
                  )}
                  <Col xs={12} xl={size === 'compact' ? 12 : 5}>
                    <Button
                      primary={size === 'compact'}
                      secondary={size !== 'compact'}
                      className="SubscribeBox__button text-uppercase"
                      onClick={handleShow}
                      large
                    >
                      Subscribe
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Highlight>

        {showingModal && (
          <SubscribeModal
            handleHide={handleHide}
            iframeUrl={iframeUrl}
            content={content}
            email={email}
            onChangeEmail={onChangeEmail}
            onClickOutside={onClickOutside}
          />
        )}
      </div>
    );
  }
}

export default SubscribeBox;
