import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const withPagination = (WrappedComponent, options = {
  limit: 10,
}) => {
  class PaginationWrapper extends Component {
    constructor() {
      super();

      this.onPageChange = this.onPageChange.bind(this);
      this.setPageAndOffset = this.setPageAndOffset.bind(this);
      this.setPageAndOffsetFromQueryString = this.setPageAndOffsetFromQueryString.bind(this);

      this.state = {
        currentPage: 1,
        limit: options.limit,
        offset: 0,
      };
    }

    componentWillMount() {
      this.setPageAndOffsetFromQueryString();
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.pageFromQueryString(this.props.location.search);
      const nextPage = this.pageFromQueryString(nextProps.location.search);

      if (currentPage !== nextPage) {
        this.setPageAndOffset(nextPage);
      }
    }

    currentPage() {
      return this.pageFromQueryString(this.props.location.search) || 1;
    }

    onPageChange(page = 1, pathname) {
      const parsed = queryString.parse(this.props.location.search);
      parsed.page = page;

      this.props.history.push({
        pathname: pathname || this.props.match.url,
        search: `?${queryString.stringify(parsed)}`,
      });

      // scrolls you to the top of the pagination container when you paginate
      if (this.refs.withPagination) {
        const node = ReactDOM.findDOMNode(this.refs.withPagination);
        if (node) { node.scrollIntoView(); }
      }
    }

    pageFromQueryString(search) {
      const { page = 1 } = queryString.parse(search);
      return parseInt(page, 10) > 0 ? page : 1;
    }

    setPageAndOffset(page) {
      const { limit } = this.state;

      return this.setState({
        currentPage: parseInt(page, 10),
        offset: (parseInt(page, 10) - 1) * limit,
      })
    }

    setPageAndOffsetFromQueryString() {
      const page = this.currentPage();
      return this.setPageAndOffset(page);
    }

    render() {
      const { currentPage, limit, offset } = this.state;

      return (
        <WrappedComponent
          ref="withPagination"
          pagination={{
            currentPage,
            limit,
            offset,
            onPageChange: this.onPageChange,
          }}
          {...this.props}
        />
      );
    }
  }

  return withRouter(PaginationWrapper);
}

export default withPagination;
