import React from 'react';
import faFile from '@fortawesome/fontawesome-free-regular/faFile';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { filter, find, forEach } from 'lodash-es';

import OutboundLink from '../OutboundLink';

// FontAwesome icons
import faFileAudio from '@fortawesome/fontawesome-free-regular/faFileAudio';
import faFileImage from '@fortawesome/fontawesome-free-regular/faFileImage';
import faFilePdf from '@fortawesome/fontawesome-free-regular/faFilePdf';
import faFileVideo from '@fortawesome/fontawesome-free-regular/faFileVideo';

import './ProductDownloads.css';

const ProductDownloads = ({ productDownloads = [] }) => {
  if (!productDownloads.length > 0) { return null; }

  let sortedDownloads = [
    {
      type: 'PDF',
      items: [],
      icon: faFilePdf,
    },
    {
      type: 'Image',
      items: [],
      icon: faFileImage,
    },
    {
      type: 'Video',
      items: [],
      icon: faFileVideo,
    },
    {
      type: 'Audio',
      items: [],
      icon: faFileAudio,
    },
    {
      type: 'Other',
      items: [],
      icon: faFile,
    },
  ];

  forEach(productDownloads, download => {
    if(!download.url) return;

    const type = download.mimeType.split('/')[0].toLowerCase();
    const parent = find(sortedDownloads, downloadType => downloadType.type.toLowerCase() === type);

    if (parent) {
      parent.items.push(download);
    } else if (download.mimeType === 'application/pdf') {
      find(sortedDownloads, downloadType => downloadType.type.toLowerCase() === 'pdf').items.push(download);
    } else {
      find(sortedDownloads, downloadType => downloadType.type.toLowerCase() === 'other').items.push(download);
    }
  });

  sortedDownloads = filter(sortedDownloads, downloadType => downloadType.items.length > 0);

  return (
    <div className="ProductDownloads mb-4">
      <h3 className="mb-3">Downloads</h3>

      {sortedDownloads.map((downloadType, index) => (
        <div key={index}>
          <h5 className="download-label">{downloadType.type}</h5>
          <ul className="download-list mb-3">
            {downloadType.items.map(download => (
              <li key={download.id}>
                <FontAwesomeIcon
                  className="icon"
                  icon={downloadType.icon}
                />

                <OutboundLink
                  eventLabel={download.url.split('?')[0]}
                  target="_blank"
                  to={download.url.split('?')[0]}
                >
                  {download.title}
                </OutboundLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default ProductDownloads;
