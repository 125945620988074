import PropTypes from 'prop-types';

const imageShape = PropTypes.shape({
  dateCreated: PropTypes.number,
  extension: PropTypes.string,
  filename: PropTypes.string,
  id: PropTypes.number.isRequired,
  mimeType: PropTypes.string,
  title: PropTypes.string,
  uri: PropTypes.string,
  url: PropTypes.string,
});

export default imageShape;
