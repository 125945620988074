import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import IssueTagList from '../IssueTagList';
import IssueTagMenu from '../IssueTagMenu';

import './IssueFilters.css';
import SectionLabel from '../SectionLabel';

const IssueFilters = ({
  issueFilter,
  authorFilter = false,
  contentTypeFilter = false,
  team,
  onShowLess,
  onShowMore,
  showMore,
  tags,
  totalCount,
}) => {
  if (!tags.length > 0) { return null; }

  const toggleShowMore = showMore ? (
    <span className="toggle-show-more mt-3" onClick={onShowLess}>Show Less</span>
  ) : (
    <span className="toggle-show-more mt-3" onClick={onShowMore}>Show More</span>
  );

  return (
    <div className="IssueFilters pr-lg-3 pr-md-3">
      <div className="mb-5">
        <h2 className="mb-2">Filters</h2>
        <SectionLabel name="Topics" className="mb-2" />

        <MediaQuery maxWidth={767}>
          <IssueTagMenu
            currentIssueID={issueFilter.currentIssueID}
            onChange={issueFilter.onIssueIDChange}
            tags={tags}
            totalCount={totalCount}
          />
        </MediaQuery>

        <MediaQuery minWidth={768}>
          <IssueTagList
            totalCount={totalCount}
            tags={showMore ? tags : tags.slice(0, 10)}
            onClick={issueFilter.toggleIssueID}
            currentIssueID={issueFilter.currentIssueID}
          />

          {tags.length > 9 &&
            toggleShowMore
          }
        </MediaQuery>
      </div>

      {contentTypeFilter && (
        <div className="ContentFilters mb-5">
          <SectionLabel name="Content Type" className="mb-2" />

          <select
            className="form-select"
            onChange={(event) =>
              contentTypeFilter.onContentTypeChange(event.target.value)
            }
            value={contentTypeFilter.currentContentType}
          >
            <option value="">All Content Types</option>
            {contentTypeFilter.contentTypes
              .filter((contentType) => contentTypeFilter.availableTypes ? contentTypeFilter.availableTypes.includes(contentType.slug.toLowerCase()) : true)
              .map((contentType, i) => (
              <option key={i} value={contentType.slug}>
                {contentType.title}
              </option>
            ))}
          </select>
        </div>
      )}

      {authorFilter && (
        <MediaQuery maxWidth={787}>
          <div className="ContentFilters mb-5 pr-lg-3 pr-md-3">
            <SectionLabel name="Team" className="mb-2" />

            <select
              className="form-select"
              onChange={(event) =>
                authorFilter.onAuthorChange(event.target.value)
              }
              value={authorFilter.currentAuthor}
            >
              <option value="">All Authors</option>
              {team.map((author) => {
                return (author && (
                  <option key={author.id} value={author.id.toString()}>
                    {author.title}
                  </option>
                ))
              })}
            </select>
          </div>
        </MediaQuery>
      )}
    </div>
  );
};

IssueFilters.propTypes = {
  issueFilter: PropTypes.object.isRequired,
  tags: PropTypes.array,
};

export default IssueFilters;
