import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AppFixedHeaderComponent from '../../components/AppFixedHeader';

// This container has to be rendered on each individual route rather than in the layout.
// This is because it depends on data from each individual page to render appropriately.
// The options were to attach a bunch of route dependent queries to this or to render it
// on the top level of a page.

class AppFixedHeader extends Component {
  constructor() {
    super();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onSearchClose = this.onSearchClose.bind(this);
    this.onSearchOpen = this.onSearchOpen.bind(this);
    this.onShareClose = this.onShareClose.bind(this);
    this.onShareOpen = this.onShareOpen.bind(this);
    this.setFixedHeaderRef = this.setFixedHeaderRef.bind(this);
    this.toggleIssues = this.toggleIssues.bind(this);
    this.toggleJumpToOpen = this.toggleJumpToOpen.bind(this);
    this.toggleShareOpen = this.toggleShareOpen.bind(this);

    this.state = {
      fixedHeaderVisible: false,
      issuesMenuOpen: false,
      jumpToOpen: false,
      previousScrollTop: 0,
      searchOpen: false,
      shareOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if(!this.state.issuesMenuOpen) return false;

    if (this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.search !== prevProps.location.search) {
      this.setState({ issuesMenuOpen: false });
    }
  }

  handleScroll() {
    if (!this.fixedHeaderRef) { return null; }

    const { height } = this.fixedHeaderRef.getBoundingClientRect();
    const top  = window.pageYOffset || document.documentElement.scrollTop;

    if (top > (height * 2) && top < this.state.previousScrollTop) {
      return this.setState({ fixedHeaderVisible: true, previousScrollTop: top });
    }

    return this.setState({
      fixedHeaderVisible: false,
      issuesMenuOpen: false,
      previousScrollTop: top,
      shareOpen: false,
    });
  }

  handleClickOutside(e) {
    if (!this.fixedHeaderRef) { return null; }

    if (!this.fixedHeaderRef.contains(e.target)) {
      this.setState({
        issuesMenuOpen: false,
        jumpToOpen: false,
        searchOpen: false,
        shareOpen: false,
      })
    }
  }

  onSearchClose() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: false,
      searchOpen: false,
      shareOpen: false
    });
  }

  onSearchOpen() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: false,
      searchOpen: true,
      shareOpen: false
    });
  }

  onShareClose() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: false,
      searchOpen: false,
      shareOpen: false
    });
  }

  onShareOpen() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: false,
      searchOpen: false,
      shareOpen: true
    });
  }

  setFixedHeaderRef(node) {
    this.fixedHeaderRef = node;
  }

  toggleIssues() {
    this.setState({
      issuesMenuOpen: !this.state.issuesMenuOpen,
      jumpToOpen: false,
      searchOpen: false,
      shareOpen: false,
    })
  }

  toggleShareOpen() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: false,
      shareOpen: !this.state.shareOpen
    });
  }

  toggleJumpToOpen() {
    return this.setState({
      issuesMenuOpen: false,
      jumpToOpen: !this.state.jumpToOpen,
      searchOpen: false,
      shareOpen: false,
    });
  }

  render() {
    return (
      <AppFixedHeaderComponent
        fixedHeaderVisible={this.state.fixedHeaderVisible}
        issuesMenuOpen={this.state.issuesMenuOpen}
        jumpToOpen={this.state.jumpToOpen}
        onSearchClose={this.onSearchClose}
        onSearchOpen={this.onSearchOpen}
        onShareClose={this.onShareClose}
        onShareOpen={this.onShareOpen}
        searchOpen={this.state.searchOpen}
        setFixedHeaderRef={this.setFixedHeaderRef}
        shareOpen={this.state.shareOpen}
        toggleIssues={this.toggleIssues}
        toggleJumpToOpen={this.toggleJumpToOpen}
        toggleShareOpen={this.toggleShareOpen}
        {...this.props}
      />
    );
  }
}

export default withRouter(AppFixedHeader);
