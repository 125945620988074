import React from 'react';

import MapComponent from '../Map';
import formatDate from '../../utils/formatDate';

import './EventDetails.css';

const EventDetails = ({ event, title = 'Event Details' }) => {
  if (!event) { return null; }

  const eventTime = () => {
    if (!event.eventStartDate) { return null; }

    let eventTime = formatDate(event.eventStartDate, 'LT');

    if (event.eventEndDate) {
      eventTime = `${eventTime} to ${formatDate(event.eventEndDate, 'LT')}`;
    }

    return eventTime;
  }

  const eventHasLocationField = () => {
    return event.eventLocationAddress ||
           event.eventLocationCity ||
           event.eventLocationName ||
           event.eventLocationState ||
           event.eventLocationZip;
  }

  const eventCityStateZip = () => {
    const { eventLocationCity, eventLocationState, eventLocationZip } = event;
    let cityStateZip;

    if (!eventLocationCity &&
        !eventLocationState &&
        !eventLocationZip) { return null; }

    if (eventLocationCity) {  cityStateZip = eventLocationCity; }
    if (eventLocationCity && (eventLocationState || eventLocationZip)) { cityStateZip = `${cityStateZip}, `; }
    if (eventLocationState) { cityStateZip = `${cityStateZip} ${eventLocationState}`; }
    if (eventLocationZip) { cityStateZip = `${cityStateZip} ${eventLocationZip}`; }

    return cityStateZip;
  }

  return (
    <div className="EventDetails mb-4">
      {title &&
        <h3 className="mb-3">Event Details</h3>
      }

      {event.eventStartDate &&
        <ul className="when mb-3">
          <li className="strong">When</li>
          <li>{formatDate(event.eventStartDate)}</li>
          <li>{eventTime()}</li>
        </ul>
      }

      {eventHasLocationField() &&
        <ul className="where mb-3">
          <li className="strong">Where</li>

          {event.eventLocationName &&
            <li>{event.eventLocationName}</li>
          }

          {event.eventLocationAddress &&
            <li>{event.eventLocationAddress}</li>
          }

          {eventCityStateZip() &&
            <li>{eventCityStateZip()}</li>
          }
        </ul>
      }

      {eventHasLocationField() &&
        <MapComponent address={event.eventLocationAddress} />
      }
    </div>
  );
}

export default EventDetails;
