import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query IssuePageRelatedProducts(
    $limit: Int,
    $offset: Int,
    $filters: [RelatedToInputType],
    $section: [SectionsEnum],
    $slug: String,
  ) {
    categoriesConnection(groupId: 3, level: 1, slug: $slug) {
      edges {
        relatedProducts: relatedEntries(
          limit: $limit,
          offset: $offset,
          orderBy: "postDate desc",
          public: true,
          relatedTo: $filters,
          section: $section,
        ) {
          totalCount
          ...on EntryConnection {
            entries {
              id
              postDate
              slug
              title
              uri

              ...on Products {
                productBlurb
                productReadingTime
                productAuthors {
                  __typename
                  id
                  slug
                  title

                  ...on Staff {
                    ...Staff
                  }

                  ...on ExternalAuthors {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }

                productFeaturedImage {
                  id
                  url
                  title
                }

                productFeaturedImageUrl

                productIssue {
                  id
                  slug
                  title
                }

                productType {
                  id
                  slug
                  title
                }
              }

              ...on Series {
                seriesBlurb

                seriesFeaturedImage {
                  url
                  id
                }

                issues {
                  slug
                  title
                  id
                }

                authors {
                  __typename
                  id
                  slug
                  title

                  ...on Staff {
                    ...Staff
                  }

                  ...on ExternalAuthors {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }
              }

              ...on Events {
                eventStartDate

                eventIssue {
                  slug
                  title
                  id
                }

                eventPhotos {
                  url
                  id
                }
              }

              ...on PressReleases {

                issues {
                  id
                  slug
                  title
                }
              }

              ...on InTheNews {
                publicationName
                newsItemUrl

                newsItemFeaturedImage {
                  url
                  id
                }

                publicationLogo {
                  url
                  id
                }

                issues {
                  title
                  id
                }
              }
            }
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
