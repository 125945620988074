import GoogleMaps from '@google/maps';

export const googleMapsClient = GoogleMaps.createClient({
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
});

export const apiRateLimitMaxRequests =
  process.env.REACT_APP_API_RATE_LIMIT_MAX_REQUESTS;
export const apiRateLimitWindow = process.env.REACT_APP_API_RATE_LIMIT_WINDOW;
export const apiUrl =
  process.env.REACT_APP_API_URL ||
  'https://thirdway-cms-production.herokuapp.com/api';
export const environment = process.env.NODE_ENV;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const hmrEnabled =
  (process.env.REACT_APP_HMR_ENABLED &&
    process.env.REACT_APP_HMR_ENABLED === 'true') ||
  false;
export const imgixSource =
  process.env.REACT_APP_IMGIX_SOURCE || 'https://thirdway.imgix.net/';
export const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const rsvpApiUrl = process.env.REACT_APP_RSVP_API_URL;
export const subscribeApiUrl = process.env.REACT_APP_SUBSCRIBE_API_URL;
export const token = process.env.REACT_APP_API_TOKEN;

export default {
  apiRateLimitMaxRequests,
  apiRateLimitWindow,
  apiUrl,
  environment,
  googleMapsApiKey,
  googleMapsClient,
  hmrEnabled,
  imgixSource,
  rsvpApiUrl,
  stripeKey,
  subscribeApiUrl,
  token,
};
