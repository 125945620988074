import React from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import AboutPageBioComponent from '../../components/AboutPageBio';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';

import query from './query';

const AboutPageBio = ({ data, location }) => {
  if (data.loading) { return (<LoadingSpinner fullPage={false} />); }
  if (data.error) { return (<Error error={data.error} />); }
  if (!data.staff) { return (<NotFoundPage />); }

  const totalInTheNews = get(data, 'entriesConnection.edges[0].inTheNews.totalCount') || 0;
  const totalProducts = get(data, 'entriesConnection.edges[0].products.totalCount') || 0;

  return (
    <AboutPageBioComponent
      location={location}
      staff={data.staff}
      totalInTheNews={totalInTheNews}
      totalProducts={totalProducts}
    />
  );
};

export default graphql(query, {
  options: ({ match: { params } }) => ({
    variables: { slug: params.slug },
  }),
})(AboutPageBio);
