import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import './AppSearch.css';

const AppSearch = ({ inputOpen, onChange, onSubmit, searchQuery, setSearchInputRef, openMenu }) => (
  <div className="AppSearch">
    {inputOpen &&
      <form
        onSubmit={onSubmit}
      >
        <FontAwesomeIcon icon={['fas', 'search']} className="icon-search" />

        <input
          id="search-input"
          onChange={onChange}
          ref={setSearchInputRef}
          type="search"
          value={searchQuery}
        />
      </form>
    }

    {!inputOpen &&
      <FontAwesomeIcon icon={['fas', 'search']} className="c-pointer icon-search" onClick={openMenu} />
    }
  </div>
);


export default AppSearch;
