import React from 'react';

import './Count.css';

const Count = (props) => (
  <span className="Count">
    {props.children}
  </span>
);

export default Count;
