import React, { Component } from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import LoadingSpinner from '../../components/LoadingSpinner';
import SubscribeBoxComponent from '../../components/SubscribeBox';
import Error from '../../components/Error';

import query from './query';

class SubscribeBox extends Component {
  static propTypes = {
    data: dataShape,
  };

  constructor(props) {
    super(props);

    this.state = {
      showingModal: false,
      subscription: {
        email: '',
      },
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
  }

  onClickOutside() {
    this.setState({
      showingModal: false,
    });
  }

  handleShow() {
    this.setState({ showingModal: true });
  }

  handleHide(e) {
    e.preventDefault();
    this.setState({ showingModal: false });
  }

  onChangeEmail(event) {
    this.setState({
      subscription: {
        email: event.target.value,
      },
    });
  }

  render() {
    const { iframeUrl = null, size, heading, description } = this.props;
    const { error, loading, globals } = this.props.data;
    const { showingModal } = this.state;

    if (loading) {
      return <LoadingSpinner fullPage={false} />;
    }
    if (error) {
      return <Error error={error} />;
    }

    const content =
      get(globals, 'subscriptions.globalSubscribe.content') || null;

    return (
      <SubscribeBoxComponent
        content={content}
        email={this.state.subscription.email}
        handleShow={this.handleShow}
        handleHide={this.handleHide}
        heading={heading}
        description={description}
        iframeUrl={iframeUrl}
        onChangeEmail={this.onChangeEmail}
        onClickOutside={this.onClickOutside}
        showingModal={showingModal}
        size={size}
      />
    );
  }
}

export default graphql(query)(SubscribeBox);
