import React, { Component } from 'react';
import classNames from 'classnames';
import formatMoney from '../../utils/formatMoney';
import Highlight from '../Highlight';

import './Donate.css';

class Donate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0,
      buttonLabel: 'Donate',
      inputValue: '',
    };

    this.errorDonate = this.errorDonate.bind(this);
    this.onToken = this.onToken.bind(this);
    this.successDonate = this.successDonate.bind(this);
  }

  amountInCents() {
    return Math.round(100 * parseFloat(this.state.amount));
  }

  formattedAmount() {
    return formatMoney(this.state.amount);
  }

  onChangeAmount(e) {
    const inputValue = e.target.value;

    this.setState(
      {
        amount: this.parseAmount(inputValue),
        inputValue: inputValue,
      },
      () => {
        const amount = this.state.amount;

        if (amount > 0) {
          this.setState({
            buttonLabel: `Donate ${this.formattedAmount(amount)}`,
          });
        } else {
          this.setState({
            amount: 0,
            buttonLabel: `Donate`,
          });
        }
      },
    );
  }

  onToken(token) {
    fetch('/donate', {
      method: 'POST',
      body: JSON.stringify({
        description: 'Third Way One-Time Donation',
        currency: 'usd',
        source: token.id,
        amount: this.amountInCents(),
        receipt_email: token.email,
      }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(this.successDonate)
      .catch(this.errorDonate);
  }

  // Remove non-digits from input value
  parseAmount(amountString) {
    return parseFloat(amountString.replace(/[^0-9-.]/g, ''));
  }

  successDonate(data) {
    // console.log('Payment was successful.');
    // console.log(data);
  }

  errorDonate(data) {
    // console.log('There was a payment Error.');
    // console.log(data);
  }

  render() {
    const { className } = this.props;

    return (
      <div className={classNames('Donate', className)}>
        <Highlight>
          <div className="content">
            <h2>Donate by credit or debit card</h2>
            <p className="mb-4">
              You will be prompted to enter the amount and your card information
              after clicking the “Donate” button.
            </p>

            <div className="form-elements">
              <div>
                <a
                  href="https://donate.stripe.com/6oEg07asNgj47ba7ss"
                  className="Button secondary text-uppercase large"
                >
                  Donate
                </a>
              </div>
            </div>
          </div>
        </Highlight>
      </div>
    );
  }
}

export default Donate;
