import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import paginationShape from '../../shapes/paginationShape';
import { Desktop, Mobile } from '../Devices';

import './Pagination.css';

// This component requires the parent component to be wrapped in the pagination
// wrapper and the pagination prop from that to be passed through to this component.
// See src/wrappers/withPagination
const Pagination = ({ className, pagination, total }) => {
  const { currentPage, limit, offset, onPageChange } = pagination;
  const fullPages = Math.floor(total / limit);
  const totalPages = fullPages * limit === total ? fullPages : fullPages + 1;

  const rangeStart = offset + 1;
  const rangeEnd = (rangeStart + limit) - 1 > total ? total : (rangeStart + limit) - 1;

  const generatePaginationRange = (delta = 2) => {
    const range = [];
    const rangeWithDots = [];
    let l;

    range.push(1);

    if (totalPages <= 1){
      return range;
    }

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i < totalPages && i > 1) {
        range.push(i);
      }
    }

    range.push(totalPages);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  const renderPageNumbers = (delta) => {
    const pageNumbers = generatePaginationRange(delta);

    return pageNumbers.map((pn, i) => {
      if (typeof pn !== "number") {
        return (
          <li key={i}>
            <span className="disabled">{pn}</span>
          </li>
        );
      }

      return (
        <li
          key={i}
          onClick={() => onPageChange(pn)}
        >
          <span className={classNames({ current: currentPage === pn })}>
            {pn}
          </span>
        </li>
      );
    });
  }

  const nextPage = () => {
    if (currentPage < totalPages) { return onPageChange(currentPage + 1); }
    return null;
  }

  const previousPage = () => {
    if (currentPage > 1) { return onPageChange(currentPage - 1); }
    return null;
  }

  return (
    <div className={classNames('Pagination', className)}>
      {totalPages > 1 &&
        <ul className="list-unstyled">
          <li onClick={previousPage}>
            <span className={classNames('previous-page', { disabled: currentPage <= 1 })}>
              «
            </span>
          </li>

          <Desktop>
            {renderPageNumbers()}
          </Desktop>

          <Mobile>
            {renderPageNumbers(1)}
          </Mobile>

          <li onClick={nextPage}>
            <span className={classNames('next-page', { disabled: currentPage >= totalPages })}>
              »
            </span>
          </li>
        </ul>
      }

      <div className="result-info">
        Showing {`${rangeStart}–${rangeEnd}`} of {total}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  pagination: paginationShape.isRequired,
  total: PropTypes.number.isRequired,
};

export default Pagination;
