import React, { Component } from 'react';
import _, { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import IssueFiltersComponent from '../../components/IssueFilters';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withSearch from '../../wrappers/withSearch';

import query from './query';

// The query for this is different enough that we have to have a separate container
// from the standard IssueFilters just for the AboutPageBio. Unfortunate.
class AboutPageBioIssueFilters extends Component {
  constructor() {
    super();

    this.onShowLess = this.onShowLess.bind(this);
    this.onShowMore = this.onShowMore.bind(this);

    this.state = {
      showMore: false,
    };
  }

  onShowLess() {
    this.setState({ showMore: false });
  }

  onShowMore() {
    this.setState({ showMore: true });
  }

  render() {
    const { data, issueFilter } = this.props;
    const { error, loading } = data;

    if (loading) { return null; }
    if (error) { return (<Error error={error} />); }

    const entries = get(data, 'entriesConnection.edges[0].relatedEntries.entries');

    if (!entries || !entries.length > 0) { return null; }

    const tags = _.chain(entries).map(item => item.issues)
                               .flatten()
                               .groupBy('title')
                               .map((items, title) => ({
                                  title,
                                  count: items.length,
                                  id: items[0].id,
                                  slug: items[0].slug,
                                }))
                               .sort((a, b) => b.count - a.count)
                               .value();

    return (
      <IssueFiltersComponent
        issueFilter={issueFilter}
        showMore={this.state.showMore}
        onShowLess={this.onShowLess}
        onShowMore={this.onShowMore}
        totalCount={entries.length}
        tags={tags}
      />
    );
  }
}

const withGraphql = graphql(query, {
  options: ({ match: { params, path }, navLinks }) => {
    let variables;

    const productsVariables = {
      slug: params.slug,
      section: 'products',
    };

    const inTheNewsVariables = {
      slug: params.slug,
      section: 'inTheNews',
    };

    // If we only have the one type of entry to display just show those
    if (navLinks.length === 1) {
      variables = navLinks[0].title === 'Products' ? productsVariables : inTheNewsVariables;
    } else {
      // Otherwise show based on route
      switch(path) {
        case '/about/:category/:slug/media':
          variables = inTheNewsVariables;
          break;

        default:
          variables = productsVariables;
          break;
      }
    }

    return ({
      variables,
    });
  },
})(AboutPageBioIssueFilters);


export default withIssueFilter(withSearch(withGraphql));
