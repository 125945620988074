import gql from 'graphql-tag';

export default gql`
  query EventsPageEventListQuery(
    $limit: Int,
    $offset: Int,
  ) {
    entriesConnection(
      limit: $limit,
      offset: $offset,
      orderBy: "eventStartDate desc",
      public: true,
      section: events,
    ) {
      totalCount
      events: entries {
        id
        slug
        title

        section {
          id
          name
        }

        ...on Events {
          eventStartDate

          eventPhotos {
            url
            id
          }

          eventIssue {
            id
            title
          }
        }
      }
    }
  }
`;
