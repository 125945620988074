import React from 'react';
import { graphql } from 'react-apollo';

import CultureComponent from '../../components/Culture';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import dataShape from '../../shapes/dataShape';
import query from './query';

const Culture = ({ data }) => {
  const { error, culture, loading } = data;

  if (loading) {
    return <LoadingSpinner fullPage={false} />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!culture) {
    return <NotFoundPage />;
  }

  return <CultureComponent culture={culture} />;
};

Culture.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ slug }) => ({
    variables: {
      slug,
    },
  }),
})(Culture);
