import gql from 'graphql-tag';

export default gql`
  query CareerQuery($slug: String) {
    career: entry(slug: $slug) {
      id
      slug
      title
      postDate
      ...on Positions {
        body {
          content
        }
        positionUrl
      }
    }
  }
`;
