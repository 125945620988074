import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Input.css';

/**
 * An input form control.
 */
class Input extends Component {
  static propTypes = {
    /**
     * The type of input.
     */
    type: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
  }

  render() {
    return(
      <div className="Input">
        <input {...this.props} />
      </div>
    );
  }
}

export default Input;
