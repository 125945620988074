import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Divider from '../Divider';
import MapComponent from '../Map';
import OutboundLink from '../OutboundLink';
import SocialLinks from '../../containers/SocialLinks';
import SubscribeBox from '../../containers/SubscribeBox';
import SupportThirdWay from '../SupportThirdWay';

import './ContactPage.css';

const ContactPage = ({ companyInfo, eventContact, issues, pressContact }) => (
  <div className="ContactPage">
    <AppHelmet title="Contact" />

    <AppFixedHeader />

    <Grid fluid>
      <Row>
        <Col xs={12}>
          <h1 className="title mb-4">Contact Us</h1>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="mb-5 mt-2" />
        </Col>
      </Row>

      <Row>
        {companyInfo && (
          <Col xs={12} md={6} lg={3} className="mb-3">
            <h2 className="mb-2">Third Way</h2>

            {companyInfo.globalAddressLine1 && (
              <p className="mb-0">{companyInfo.globalAddressLine1}</p>
            )}

            {companyInfo.globalAddressLine2 && (
              <p className="mb-0">{companyInfo.globalAddressLine2}</p>
            )}

            {companyInfo.globalAddressLine3 && (
              <p className="mb-0 strong">{companyInfo.globalAddressLine3}</p>
            )}
          </Col>
        )}

        {companyInfo && (
          <Col xs={12} md={6} lg={3} className="mb-3">
            <h2 className="mb-2">General</h2>

            {companyInfo.globalPhone && (
              <p className="mb-0">{companyInfo.globalPhone}</p>
            )}

            {companyInfo.globalFax && (
              <p className="mb-0">{companyInfo.globalFax}</p>
            )}

            {companyInfo.globalEmail && (
              <p className="mb-0">
                <a href={`mailto:${companyInfo.globalEmail}`}>
                  {companyInfo.globalEmail}
                </a>
              </p>
            )}
          </Col>
        )}

        {pressContact && (
          <Col xs={12} md={6} lg={3} className="mb-3">
            <h2 className="mb-2">Press Contact</h2>

            {pressContact.title && (
              <p className="mb-0 strong">{pressContact.title}</p>
            )}

            {pressContact.position && (
              <p className="mb-0 italics">{pressContact.position}</p>
            )}

            {pressContact.phoneNumber && (
              <p className="mb-0">{pressContact.phoneNumber}</p>
            )}

            {pressContact.email && (
              <p className="mb-0">
                <a href={`mailto:${pressContact.email}`}>
                  {pressContact.email}
                </a>
              </p>
            )}
          </Col>
        )}

        {eventContact && (
          <Col xs={12} md={6} lg={3} className="mb-3">
            <h2 className="mb-2">Event Contact</h2>

            {eventContact.title && (
              <p className="mb-0 strong">{eventContact.title}</p>
            )}

            {eventContact.position && (
              <p className="mb-0 italics">{eventContact.position}</p>
            )}

            {eventContact.phoneNumber && (
              <p className="mb-0">{eventContact.phoneNumber}</p>
            )}

            {eventContact.email && (
              <p className="mb-0">
                <a href={`mailto:${eventContact.email}`}>
                  {eventContact.email}
                </a>
              </p>
            )}
          </Col>
        )}
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="my-4" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={6}>
          <div className="map pb-3">
            <h2 className="mb-2">Map</h2>
            <MapComponent address={companyInfo.globalAddressLine1} />
          </div>
        </Col>

        <Col xs={12} lg={6}>
          <h2 className="mb-4">Find us on Social Media</h2>

          <SocialLinks large showTitles className="mb-2" />

          <h3 className="mb-4">Our Programs on Social Media</h3>

          <ul className="issue-contacts">
            {issues.map((issue) => (
              <li className="mb-2" key={issue.id}>
                <i className={`icon icon-${issue.issueIcon}`}></i>
                <h4>{issue.title}</h4>
                <div className="share-links mb-2 mt-1">
                  {issue.issueSocialMediaAccount.map((account, i) =>
                    account.twitterHandle ? (
                      <OutboundLink
                        className="twitter-link"
                        eventLabel={`https://twitter.com/${account.twitterHandle}`}
                        key={i}
                        to={`https://twitter.com/${account.twitterHandle}`}
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          className="mr-1"
                          size="lg"
                        />
                        @{account.twitterHandle}
                      </OutboundLink>
                    ) : (
                      <OutboundLink
                        className="instagram-link"
                        eventLabel={`https://instagram.com/${account.instagramName}`}
                        key={i}
                        to={`https://instagram.com/${account.instagramName}`}
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'instagram']}
                          className="mr-2"
                          size="lg"
                        />
                        {account.instagramName}
                      </OutboundLink>
                    ),
                  )}
                </div>
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="my-4" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={6}>
          <SupportThirdWay className="mb-5 mb-lg-auto" />
        </Col>

        <Col xs={12} lg={6}>
          <SubscribeBox className="mb-5 mb-lg-auto" size={"small"} />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default ContactPage;
