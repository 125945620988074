import React from 'react';
import { graphql } from 'react-apollo';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import MapPageComponent from '../../components/MapPage';

import query from './query';
import { itemsFromEntries } from '../../utils/itemFromEntry';

const MapPage = ({ data }) => {
  const { error, loading, mapContent, recentSnapshots, energyTechnology } = data;

  let related;

  if (loading) { return <LoadingSpinner />; }
  if (error) { return <Error error={error} />; }
  if (!mapContent) { return null; }

  if (mapContent.relatedContent && mapContent.relatedContent.length > 0) {
    related = itemsFromEntries(mapContent.relatedContent);
  }

  return (
    <div>
      <MapPageComponent
        content={mapContent}
        related={related}
        recentSnapshots={recentSnapshots}
        energyTechnology={energyTechnology}
      />
    </div>
  );
};

MapPage.propTypes = {
  data: dataShape,
};


export default graphql(query)(MapPage);

