import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import RelatedContent from '../../components/RelatedContent';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

const EventPageRelatedContent = ({ data }) => {
  const { error, loading, event } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!event || !event.eventRelatedContent.length) { return null; }

  const items = itemsFromEntries(event.eventRelatedContent);

  return (
    <RelatedContent
      items={items}
      heading={{
        title: 'Related Content',
        size: 'small'
      }}
    />
  );
};

export default withRouter(graphql(query, {
  options: ({ match: { params } }) => ({
    variables: { slug: params.slug },
  }),
})(EventPageRelatedContent));
