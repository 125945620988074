import React from 'react';
import { find, get } from 'lodash';
import { graphql } from 'react-apollo';
import { Redirect, Route } from 'react-router-dom';

import Error from '../../components/Error';
import NotFoundPageComponent from '../../components/NotFoundPage';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

const RedirectWithStatus = ({ from, to, status }) => (
  <Route
    render={({ staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
      if (staticContext) staticContext.status = status;
      return <Redirect from={from} to={to} />;
    }}
  />
);

const NotFoundPage = ({ data, location }) => {
  if (data.loading) { return (<LoadingSpinner />); }
  if (data.error) { return (<Error error={data.error} />); }

  const redirects = get(data, 'globals.redirects.globalRedirects') || [];
  const redirect = find(redirects, r => r.requestedUrl === location.pathname);

  // This handles any redirects defined on the CraftCMS. If the pathname is not included
  // in the redirects, we will instead proceed to the 404 page.
  if (redirect && redirect.requestedUrl !== redirect.redirectUrl) {
    return (
      <RedirectWithStatus status={301} from={redirect.requestedUrl} to={redirect.redirectUrl} />
    );
  }

  return (
    <NotFoundPageComponent />
  );
}

export default graphql(query)(NotFoundPage);
