import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { isEmpty } from 'lodash-es';
import classNames from 'classnames';

import Content from '../Content';
import VideoEmbed from '../VideoEmbed';

import './AboutPageBlock.css';

const AboutPageBlock = ({
  blockImage,
  blockMediaAlignment,
  blockText,
  blockTitle,
  blockYoutubeId,
}) => (
  <section className="AboutPageBlock">
    <Row>
      <Col xs={12}>
        <h1 className="mb-4">{blockTitle}</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="pb-3">
        {(!isEmpty(blockYoutubeId) || blockImage.length > 0) && (
          <div
            className={classNames('mb-2 w-100 w-lg-50', {
              'float-right ml-lg-4': blockMediaAlignment === 'right',
              'float-left mr-lg-4': blockMediaAlignment === 'left',
            })}
          >
            {!isEmpty(blockYoutubeId) && (
              <VideoEmbed
                url={`https://www.youtube.com/embed/${blockYoutubeId}`}
                title={blockTitle}
              />
            )}
            {isEmpty(blockYoutubeId) &&
              blockImage.length > 0 && (
                <img
                  src={blockImage[0].url}
                  className="outset inset-lg w-lg-100"
                  alt={blockTitle}
                />
              )}
          </div>
        )}
        {blockText &&
          blockText.content && <Content content={blockText.content} />}
      </Col>
    </Row>
  </section>
);

export default AboutPageBlock;
