import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';

import SideNav from '../SideNav';

const ProductSideNav = ({ className, items }) => {
  const formattedItems = items.map((item, i) => {
    return {
      label: item.__typename === 'Events' ? 'events' : get(item, 'productType[0].slug'),
      ...item,
    };
  });

  return (
    <SideNav
      className={className}
      items={formattedItems}
      title="Related"
    />
  );
};

ProductSideNav.propTypes = {
  /**
   * An array of related products
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
};

export default ProductSideNav;
