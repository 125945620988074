import React from 'react';
import { graphql } from 'react-apollo';

import InternshipComponent from '../../components/Internship';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import dataShape from '../../shapes/dataShape';
import query from './query';

const Internship = ({ data }) => {
  const { error, internship, loading } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!internship) { return (<NotFoundPage />); }

  return (
    <InternshipComponent internship={internship} />
  );
};

Internship.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ slug }) => ({
    variables: {
      slug,
    },
  }),
})(Internship);
