import React from 'react';
import classNames from 'classnames';
import queryString from 'query-string';
import { find } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import IssueFilters from '../../containers/IssueFilters';
import SearchPageResults from '../../containers/SearchPageResults';
import SectionLabel from '../SectionLabel';

import './SearchPage.css';

const SearchPage = ({ location }) => {
  const searchQuery = queryString.parse(location.search).q;

  if (!searchQuery) {
    return (
      <Redirect to="/" />
    );
  }

  const navLinks = [
    { pathname: '/search', title: 'Products' },
    { pathname: '/search/events', title: 'Events' },
    { pathname: '/search/press-releases', title: 'Press Releases' },
    { pathname: '/search/media', title: 'Media' },
  ];

  const currentPageTitle = find(navLinks, navLink => navLink.pathname === location.pathname).title;

  return (
    <div className="SearchPage">
      <AppHelmet title={currentPageTitle ? `Search ${currentPageTitle}` : 'Search'} />

      <AppFixedHeader />

      <Grid fluid>
        {searchQuery &&
          <Row>
            <Col xs={12}>
              <SectionLabel name="Search results for" />
              <h1 className="search-term mt-2 mb-4">{searchQuery}</h1>
            </Col>
          </Row>
        }

        <Row>
          <Col xs={12} md={4} lg={3}>
            <IssueFilters />
          </Col>

          <Col xs={12} md={8} lg={9}>
            <Row>
              <Col xs={12} className="pb-4">
                {navLinks.map((link, i) => (
                  <h2
                    className={classNames('search-nav-link', {
                      active: location.pathname === link.pathname,
                    })}
                    key={i}
                  >
                    <Link
                      to={`${link.pathname}?q=${searchQuery}`}
                    >
                      {link.title}
                    </Link>
                  </h2>
                ))}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                {/*
                  We could just render the SearchPageResults component here, but in
                  doing so the pagination nav for the previous section will be displayed
                  while the next section you're navigating to is loading.
                */}
                {navLinks.map((link, i) => <Route exact path={link.pathname} component={SearchPageResults} key={i} />)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default SearchPage;
