import { Component } from 'react';
import { withRouter } from 'react-router';
import get from 'lodash-es/get';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    // This will not scroll to top on changes to the search params, only to the
    // pathname. The wrappers for filters/pagination will have to include a scroll
    // mechanism.

    const shouldScroll = !get(this.props, 'location.state.noScroll', false) === true;

    if (shouldScroll && (this.props.location.pathname !== prevProps.location.pathname)) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop)
