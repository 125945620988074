import React from 'react';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import EventsPageComponent from '../../components/EventsPage';

import query from './query';

const EventsPage = ({ data }) => {
  const { error, event, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }

  return (
    <EventsPageComponent
      event={event}
    />
  );
}

export default graphql(query)(EventsPage);
