import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TwitterFeedComponent from '../../components/TwitterFeed';

// import mockData from './mockData';

class TwitterFeed extends Component {
  static propTypes = {
    handle: PropTypes.string,
  };

  static defaultProps = {
    handle: 'ThirdwayTweet',
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    // Commenting this out until we decide for sure to get rid of it
    // const { handle } = this.props;

    // fetch('/twitter/user', {
    //   body: JSON.stringify({ username: handle, count: 4 }),
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // })
    //   .then((res) => {
    //     if (!res.ok) {
    //       throw Error(res.statusText);
    //     }
    //     return res.json();
    //   })
    //   .then((json) => this.setState({ data: json.data }))
    //   .catch((err) => console.error('error', err));
  }

  render() {
    const { data } = this.state;

    if (data.length === 0) {
      return null;
    }

    return <TwitterFeedComponent data={data} />;
  }
}

export default TwitterFeed;
