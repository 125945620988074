import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import issueFilterShape from '../../shapes/issueFilterShape';
import LoadingSpinner from '../../components/LoadingSpinner';
import PaginatedEntries from '../../components/PaginatedEntries';
import paginationShape from '../../shapes/paginationShape';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withPagination from '../../wrappers/withPagination';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

class AboutPageBioEntries extends Component {
  static propTypes = {
    data: dataShape.isRequired,
    issueFilter: issueFilterShape.isRequired,
    navLinks: PropTypes.array.isRequired,
    pagination: paginationShape.isRequired,
    staffID: PropTypes.number.isRequired,
  }

  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps)  {
    // If the issue filter has changed and we are refetching based on variable changes (i.e: not from the cache)
    // show the loading spinner so the pagination isn't displaying when we don't have up to date pagination info
    const previousIssue = get(this.props, 'issueFilter.currentIssueID');
    const nextIssue = get(nextProps, 'issueFilter.currentIssueID');
    const networkStatus = get(nextProps, 'data.networkStatus');
    const currentSearchQuery = get(this.props, 'search.currentSearchQuery');
    const nextSearchQuery = get(nextProps, 'search.currentSearchQuery');

    if ((previousIssue !== nextIssue || currentSearchQuery !== nextSearchQuery) && networkStatus === 2) {
      return this.setState({ loading: true });
    }

    return this.setState({ loading: false });
  }

  render() {
    const { data, pagination } = this.props;
    const { error, loading, entriesConnection } = data;

    if ((loading && !entriesConnection) || this.state.loading) { return (<LoadingSpinner fullPage={false} />); }
    if (error) { return (<Error error={error} />); }

    const entries = get(entriesConnection, 'edges[0].relatedEntries.entries');
    const totalCount = get(entriesConnection, 'edges[0].relatedEntries.totalCount');

    if (!entries) { return null; }

    const items = itemsFromEntries(entries);

    return (
      <PaginatedEntries
        items={items}
        loading={loading}
        noItemsMessage="No results found"
        pagination={pagination}
        totalItems={totalCount}
      />
    );
  }
}

const withGraphql = graphql(query, {
  options: ({ issueFilter: { currentIssueID }, match: { params, path }, navLinks, pagination, staffID }) => {
    let variables;

    const productsVariables = {
      limit: pagination.limit || 5,
      offset: pagination.offset || 0,
      relatedElement: currentIssueID ? [{ targetElement: staffID }, { targetElement: currentIssueID }] : undefined,
      slug: params.slug,
      section: ['products', 'series'],
    };

    const inTheNewsVariables = {
      limit: pagination.limit || 5,
      offset: pagination.offset || 0,
      relatedElement: currentIssueID ? [{ targetElement: staffID }, { targetElement: currentIssueID }] : [{ targetElement: staffID }],
      slug: params.slug,
      section: 'inTheNews',
    };

    // If we only have the one type of entry to display just show those
    if (navLinks.length === 1) {
      variables = navLinks[0].title === 'Products' ? productsVariables : inTheNewsVariables;
    } else {
      // Otherwise show based on route
      switch(path) {
        case '/about/:category/:slug/media':
          variables = inTheNewsVariables;
          break;

        default:
          variables = productsVariables;
          break;
      }
    }

    return ({
      variables,
    });
  },
})(AboutPageBioEntries);

export default withIssueFilter(withPagination(withGraphql, { limit: 5 }));
