import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner';
import Modal from '../Modal';
import './SubscribeModal.css';

const SubscribeModal = ({ iframeUrl = null, email, onClickOutside }) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef(null);

  const iframeLoaded = () => {
    setLoading(false);
    if (!iframeRef.current) return null;

    iframeRef.current.contentWindow.postMessage(
      { call: 'setEmail', value: email },
      'https://subscribe.thirdway.org',
    );
  };

  return (
    <Modal className="SubscribeModal" onClickOutside={onClickOutside}>
      <div className="modal-body">
        <div className="content">
          {loading ? <LoadingSpinner fullPage={false} /> : null}
          <iframe
            height="500"
            id="subscription_modal_frame"
            onLoad={iframeLoaded}
            ref={iframeRef}
            src={
              iframeUrl
                ? iframeUrl
                : 'https://subscribe.thirdway.org/l/886923/2021-07-26/35853i'
            }
            title="Subscription Form"
            type="text/html"
            width="100%"
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

SubscribeModal.propTypes = {
  email: PropTypes.string,
  onClickOutside: PropTypes.func,
};

export default SubscribeModal;
