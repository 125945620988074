import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const withSearch = (WrappedComponent, options) => {
  class SearchWrapper extends Component {
    constructor() {
      super();

      this.onSearchQueryChange = this.onSearchQueryChange.bind(this);
      this.setSearchQuery = this.setSearchQuery.bind(this);
      this.setSearchQueryFromQueryString = this.setSearchQueryFromQueryString.bind(this);

      this.state = {
        currentSearchQuery: '',
      };
    }

    componentWillMount() {
      this.setSearchQueryFromQueryString();
    }

    componentWillReceiveProps(nextProps) {
      const currentSearchQuery = this.currentSearchQuery();
      const nextSearch = this.searchQueryFromQueryString(nextProps.location.search);

      if (currentSearchQuery !== nextSearch) {
        this.setSearchQuery(nextSearch);
      }
    }

    currentSearchQuery() {
      return this.searchQueryFromQueryString(this.props.location.search) || '';
    }

    onSearchQueryChange(searchQuery = '', pathname) {
      // when changing the search query, we remove the page number and filters
      this.props.history.push({
        pathname: pathname || this.props.match.url,
        search: `?${queryString.stringify({ q: searchQuery })}`
      });
    }

    searchQueryFromQueryString(query) {
      const { q = '' } = queryString.parse(query);
      const sanitizedQ = q.replace(/\W+/g, ' ').substring(0, 30)
      return sanitizedQ;
    }

    setSearchQuery(searchQuery) {
      return this.setState({ currentSearchQuery: searchQuery });
    }

    setSearchQueryFromQueryString() {
      const currentSearchQuery = this.currentSearchQuery();
      return this.setSearchQuery(currentSearchQuery);
    }

    render() {
      const { currentSearchQuery } = this.state;

      return (
        <WrappedComponent
          search={{
            currentSearchQuery,
            onSearchQueryChange: this.onSearchQueryChange,
          }}
          {...this.props}
        />
      );
    }
  }

  return withRouter(SearchWrapper);
}

export default withSearch;
