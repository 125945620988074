import React from 'react';

import AppLogo from '../AppLogo';
import AppNav from '../AppNav';
import AppMenu from '../../containers/AppMenu';

import './AppHeader.css';

const AppHeader = () => (
  <header className="AppHeader">
    <div className="header-container">
      <AppLogo />
      <AppMenu>
        <AppNav />
      </AppMenu>
    </div>
  </header>
);

export default AppHeader;
