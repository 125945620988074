import React from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import SupportContact from '../SupportContact';

const SupportThirdWay = ({ className }) => (
  <div className={classNames('SupportThirdWay', className)}>
    <h2 className="mb-3">
      Support Third Way
      <Link to="/donate" className="Button small text-uppercase mb-1">
        Make a Donation
      </Link>
    </h2>
    <p>
      Third Way is a non-profit organization. Our policy innovations, rigorous
      research, and issue campaigns are made possible by the sustained
      engagement and generous contributions of our supporters. For more
      information on how to support the work of Third Way, please contact:
    </p>
    <SupportContact />
  </div>
);

export default SupportThirdWay;
