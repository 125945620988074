import React from 'react';
import Responsive from 'react-responsive';

const Mobile = props => <Responsive {...props} maxWidth={991} />;
const Desktop = props => <Responsive {...props} minWidth={992} />;
const Default = props => <Responsive {...props} minWidth={992} />;

export {
  Mobile,
  Desktop,
  Default,
};
