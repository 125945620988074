import React from 'react';

import './Label.css';

/**
 * A label for inputs.
 */
const Label = (props) => (
  <div className="Label">
    <label {...props} />
  </div>
);

export default Label;
