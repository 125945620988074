import PropTypes from 'prop-types';

const companyInfoShape = PropTypes.shape({
  companyName: PropTypes.string,
  globalEmail: PropTypes.string,
  globalPhone: PropTypes.string,
  globalFax: PropTypes.string,
  globalFacebook: PropTypes.string,
  globalLinkedin: PropTypes.string,
  globalTumblr: PropTypes.string,
  globalTwitter: PropTypes.string,
  googleMapApiKey: PropTypes.string,
});

export default companyInfoShape;
