import fetch from 'node-fetch';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

import { apiUrl, token } from '../config/env';
import introspectionQueryResultData from './introspectionQueryResultData.json';

// https://github.com/apollographql/react-docs/blob/master/source/initialization.md
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher }).restore(typeof window !== 'undefined' ? window.__APOLLO_STATE__ : null);

const previewTokenQuery = document.location.href.match(/\btoken=([^&]+)/);
const previewToken = previewTokenQuery ? previewTokenQuery[1] : '';

const httpLink = createHttpLink({
  // removes extra whitespace (%20) characters from urls to avoid 414 errors.
  // you'd think apollo or the graphl tag would do this by default, but nope.
  fetch: (uri, options) => fetch(uri.replace(/(%20)+/g, "%20"), options),
  uri: `${apiUrl}${previewToken.length ? '?token=' + previewToken : ''}`,
  useGETForQueries: true,
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null,
    }
  }
});

const client = new ApolloClient({
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
    watchQuery: {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  },
  link: authLink.concat(httpLink),
  cache,
});

export default client;
