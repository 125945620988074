import React from 'react';
import Imgix from 'react-imgix';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import { Route } from 'react-router-dom';


import './MapPageHero.css';
import Content from '../../Content';

const MapPageHero = ({ content }) => {

  const SecondaryImage = (image) => (
    <Imgix
      key={image.id}
      aggressiveLoad
      imgProps={{
        alt: image.title
      }}
      src={image.url}
    />
  )

  return (
    <section className="MapPageHero" style={{ backgroundImage: 'url(' + content.heroImage[0].url + ')' }}>
      <Grid fluid>
        <Row className="heading">
          <Col xs={12}>
            <p className="text-gamma inverse">{content.heroOverline}</p>
            <h1 className="text-alpha inverse">{content.heroHeading}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="content-container">
            <div className="text text-paragraph text-lg-paragraph-large inverse">
              <Content content={content.heroBody.content} />
            </div>
          </Col>
          {/* <Col xs={12} md={6} className="content-container mt-3 mt-md-0">
            <div className="images">
              {content.secondaryImages.map(SecondaryImage)}
            </div>
          </Col> */}
        </Row>
      </Grid>
    </section>
  );
}




export default MapPageHero;
