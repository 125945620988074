import React, { useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import EntryCard from '../../EntryCard';
import TechnologyIcon, { technologyStyles } from '../TechnologyIcon';
import './MapPageRelatedMaterials.css';

const MapPageRelatedMaterials = ({ content, defaultItems, filteredItems, selectedEnergyTechnologyEntry }) => {
  const [items, setItems] = useState(defaultItems);

  useEffect(() => {
    setItems(selectedEnergyTechnologyEntry && filteredItems.length ? filteredItems : defaultItems);
  }, [selectedEnergyTechnologyEntry]);

  if (!items) {
    return null;
  }

  return (
    <section className="MapPageRelatedMaterials">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <div className="heading mb-4 mb-lg-5">
              <h2 className="text-delta">{content.relatedContentHeading}</h2>
              {selectedEnergyTechnologyEntry && (
                  <span className="FilterButton related">
                    <span className="button-text text-theta text-uppercase">{selectedEnergyTechnologyEntry.title}</span>
                    <div className="MapIcons ml-2">
                      <TechnologyIcon slug={selectedEnergyTechnologyEntry.slug} />
                    </div>
                  </span>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {items.map((item, index) => (
            <Col xs={12} sm={6} xl={3} key={index}>
              <EntryCard item={item} reduceCardGutters={true}/>
            </Col>
          ))}
        </Row>
      </Grid>
    </section>
  )
}

export default MapPageRelatedMaterials;
