import React from 'react';
import Imgix from 'react-imgix';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import Author from '../../Author';
import Content from '../../Content';
import formatDate from '../../../utils/formatDate';
import itemShape from '../../../shapes/itemShape';
import OutboundLink from '../../OutboundLink';
import TopicLabel from '../../TopicLabel';
import urlIsExternal from '../../../utils/urlIsExternal';

import './FeaturedHomeEntryItem.css';

const FeaturedHomeEntryItem = ({ item }) => {
  if (!item) { return null; }

  const FeaturedHomeEntryItemImage = (
    <Imgix
      aggressiveLoad
      className="responsive mb-sm-2 mb-lg-4"
      crop="center"
      height={608}
      imgProps={{
        alt: item.imageTitle
      }}
      src={item.imageUrl}
      width={912}
    />
  );

  return (
    <div className="FeaturedHomeEntryItem">
      {urlIsExternal(item.itemUrl) ? (
        <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
          {FeaturedHomeEntryItemImage}
        </OutboundLink>
      ) : (
        <Link to={item.itemUrl}>
          {FeaturedHomeEntryItemImage}
        </Link>
      )}
      <div className="mx-3 mx-lg-0">
        <div>
          <TopicLabel
            className="mb-2"
            name={item.topicName}
            publishedAt={item.itemPublicationDate}
            publicationName={item.itemPublicationName}
            engagement={item.itemReadingTime}
          />

          {item.eventStartDate &&
            <span className="event-start-date text-right">
              <em>
                {formatDate(item.eventStartDate, 'MMM DD, YYYY')}
              </em>
            </span>
          }
        </div>

        <h2 className="mb-2">
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
              {item.itemTitle}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>
              {item.itemTitle}
            </Link>
          )}
        </h2>

        {item.itemBlurb &&
          <div className="mb-3">
            <Content
              content={item.itemBlurb}
              sanitizeContent={true}
            />
          </div>
        }

        {item.itemAuthors && item.itemAuthors.length > 0 &&
          <div className="authors-list mb-2">
            <Row>
              {item.itemAuthors.map(author => (
                <Col key={author.id} xs={12} sm={6} lg={12} xl={6}>
                  <Author
                    author={author}
                    issues={item.itemIssues}
                    className="mb-3"
                    small
                  />
                </Col>
              ))}
            </Row>
          </div>
        }
      </div>
    </div>
  );
};

FeaturedHomeEntryItem.propTypes = {
  item: itemShape,
};

export default FeaturedHomeEntryItem;
