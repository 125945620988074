import React from 'react';
import { Link } from 'react-router-dom';

import './SecondaryNav.css';

const SecondaryNav = () => (
  <div className="SecondaryNav">
    <ul className="list-unstyled">
      <li>
        <Link to="/about">
          About
        </Link>
      </li>
      <li>
        <Link to="/events">
          Events
        </Link>
      </li>
      <li>
        <Link to="/press">
          Press
        </Link>
      </li>
      <li>
        <Link to="/contact">
          Contact
        </Link>
      </li>
      <li className="donate">
        <Link to="/donate" className="Button secondary">
          Donate
        </Link>
      </li>
      <li>
        <Link to="/careers">
          Careers
        </Link>
      </li>
      <li>
        <Link to="/about/fellows-program">
          Fellows Program
        </Link>
      </li>
      <li>
        <Link to="/internships">
          Internships
        </Link>
      </li>
    </ul>
  </div>
);

export default SecondaryNav;

