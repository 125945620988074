import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query AboutPageBioQuery($slug: String) {
    staff: entry(type: Staff, slug: $slug, previousStaffMember: false) {
      id
      slug
      title

      ...on Staff {
        ...Staff
      }
    }

    entriesConnection(slug: $slug, type: Staff) {
      edges {
        products: relatedEntries(
          public: true,
          section: products,
        ) {
          totalCount

          entries {
            id

            ...on Products {
              id
              public
            }
          }

        }

        inTheNews:relatedEntries(
          section: inTheNews,
        ) {
          totalCount
        }
      }
    }
  }

  ${StaffFragment}
`;
