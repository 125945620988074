import React from 'react';

import OneUpLayout from './OneUpLayout';
import TwoUpLayout from './TwoUpLayout';
import ThreeUpLayout from './ThreeUpLayout';

const FeaturedItems = ({ items }) => {
  const totalFeaturedItems = items.length;

  if (totalFeaturedItems === 1) {
    return <OneUpLayout items={items} />;
  }

  if (totalFeaturedItems === 2) {
    return <TwoUpLayout items={items} />;
  }

  return <ThreeUpLayout items={items} />;
};

export default FeaturedItems;
