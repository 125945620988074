import React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router';

import AppHelmet from '../AppHelmet';

// This sets up a route that you can use to control the image / title in the meta tags as well as what url to redirect to
const TweetableRedirect = ({ location }) => {
  const search = queryString.parse(location.search);

  return (
    <div>
      <AppHelmet
        image={search.image}
        title={search.title}
      />

      <Redirect to={search.path || '/'} />
    </div>
  );
};

export default TweetableRedirect;
