import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import { Route } from 'react-router-dom';

import Content from '../../Content';
import './MapPageIntro.css';

const MapPageIntro = ({ content }) => (
  <section className="MapPageIntro">
    <Grid fluid>
      <Col xs={12}>
        <Row>
          <div className="heading">
            <h2 className="text-gamma mb-2">{content.mapOverline}</h2>
            <p className="text-beta mb-0">{content.mapHeading}</p>
          </div>
          <div className="text text-paragraph">
            <Content content={content.mapBody.content} />
          </div>
        </Row>
      </Col>
    </Grid>
  </section>
)

export default MapPageIntro;