import React from 'react';
import _filter from 'lodash-es/filter';
import _map from 'lodash-es/map';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import IssuesNavComponent from '../../components/IssuesNav';

import query from './query';

const IssuesNav = (props) => {
  const { data } = props;

  if (data.loading) { return (<LoadingSpinner fullPage={false} />); }
  if (data.error) { return (<Error error={data.error} />); }

  const bottom = _filter(data.bottom, item => !item.issueHidden)
  const top = _filter(data.top, item => !item.issueHidden)

  // Create an array of categories from "top" data, each with
  // its own +issues+ array culled from "bottom" data.
  const processedData = _map(top, (category) => {
    const c = Object.assign({}, category);

    c.issues = _filter(bottom, (item) => {
      return item.uri.indexOf(c.uri) > -1;
    });

    return c;
  });

  return (
    <IssuesNavComponent
      issues={processedData}
      {...props}
    />
  );
};

export default graphql(query)(IssuesNav);
