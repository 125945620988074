import React from 'react';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';

import IssueMenu from './IssueMenu';
import SecondaryNav from '../SecondaryNav';

import './IssuesNav.css';

const IssuesNav = ({ className, issues, fixed }) => {
  if (!issues) { return null; }

  return (
    <div className={classNames('IssuesNav', className, {
      fixed: fixed,
    })}>
      <ul className="list-unstyled">
        {issues.map((category, i) => {
          return(
            <li key={category.id}>
              <IssueMenu
                icon={category.issueIcon}
                items={category.issues}
                slug={category.slug}
                title={category.title}
            />
            </li>
          )
        })}
        <MediaQuery maxWidth={991} component="li">
          <SecondaryNav />
        </MediaQuery>
      </ul>
    </div>
  );
};

export default IssuesNav;

