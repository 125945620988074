import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, get } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import IssuesRelatedComponent from '../../components/IssuesRelated';

import query from './query';

class IssuesRelated extends Component {
  static propTypes = {
    issueIDs: PropTypes.array,
  };

  render() {
    const { data } = this.props;
    const { error, loading, categoriesConnection } = data;

    if (loading) { return null; }
    if (error) { return (<Error error={error} />); }

    const tags = filter(get(categoriesConnection, 'edges'),
      issue => get(issue, 'relatedEntries.totalCount') > 0
    ).map(issue => ({
      count: get(issue, 'relatedEntries.totalCount'),
      id: get(issue, 'node.id'),
      slug: get(issue, 'node.slug'),
      title: get(issue, 'node.title'),
      uri: get(issue, 'node.uri'),
    })).sort((a, b) => b.count - a.count);;

    return (
      <IssuesRelatedComponent
        tags={tags}
      />
    );
  }
}

export default graphql(query, {
  options: ({ issueIDs }) => ({
    variables: { issueIDs },
  }),
})(IssuesRelated);
