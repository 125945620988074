import React from 'react';
import { filter, get } from 'lodash-es';
import { graphql } from 'react-apollo';

import ContactPageComponent from '../../components/ContactPage';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

const ContactPage = ({ data }) => {
  const { categories, error, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }

  const companyInfo = get(data, 'globals.companyInfo');
  const eventContact = get(data, 'globals.companyInfo.eventContact[0]');
  const pressContact = get(data, 'globals.companyInfo.pressContact[0]');
  const filteredIssues = filter(categories, (category => category.issueSocialMediaAccount &&
                                                         category.issueSocialMediaAccount.length > 0 &&
                                                         !category.issueHidden));

  return (
    <ContactPageComponent
      issues={filteredIssues}
      companyInfo={companyInfo}
      eventContact={eventContact}
      pressContact={pressContact}
    />
  );
};

ContactPage.propTypes = {
  data: dataShape,
};

export default graphql(query)(ContactPage);
