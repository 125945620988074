import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.css';

/**
 * A general purpose button component.
 */
class Button extends Component {
  static propTypes = {
    /**
     * Occupy the full available width of a container.
     */
    block: PropTypes.bool,
    /**
     * A dark button.
     */
    dark: PropTypes.bool,
    /**
     * A disabled button.
     */
    disabled: PropTypes.bool,
    /**
     * A large button.
     */
    large: PropTypes.bool,
    /**
     * A handler for onClick events
     */
    onClick: PropTypes.func,
    /**
     * A button with primary branding colors.
     */
    primary: PropTypes.bool,
    /**
     * A button with secondary branding colors.
     */
    secondary: PropTypes.bool,
    /**
     * A small button.
     */
    small: PropTypes.bool,
    /**
     * Choose a different html tag besides button.
     */
    tag: PropTypes.string,
    /**
     * An extra large button.
     */
    xlarge: PropTypes.bool,
  };

  static defaultProps = {
    block: false,
    dark: false,
    disabled: false,
    large: false,
    onClick: () => {},
    primary: false,
    secondary: false,
    small: false,
    tag: null,
    xlarge: false,
  }

  render() {
    const {
      block,
      dark,
      disabled,
      large,
      onClick,
      primary,
      secondary,
      small,
      tag,
      xlarge,
    } = this.props;

    const Tag = `${tag ? tag : 'button'}`

    return(
      <Tag
        className={classNames('Button',
          this.props.className, {
            block,
            dark,
            large,
            primary,
            secondary,
            small,
            xlarge,
          })}
        disabled={disabled}
        onClick={onClick}
      >
        {this.props.children}
      </Tag>
    );
  }
}

export default Button;
