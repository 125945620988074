import React from 'react';
import { filter } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import CategoryListComponent from '../../components/CategoryList';

import query from './query';

const CategoryList = ({ data }) => {
  const { categories, error, loading } = data;

  if (error) { return (<Error error={error} />); }

  return (
    <CategoryListComponent
      categories={filter(categories, (item => !item.issueHidden))}
      loading={loading}
    />
  );
};

export default graphql(query)(CategoryList);
