import gql from 'graphql-tag';

export default gql`
  query JobsQuery {
    jobTabs: categoriesConnection(groupId: 6) {
      edges {
        node {
          title
          ... on PositionTypeCategory {
            body {
              content
            }
          }
        }
        relatedEntries(public: true) {
          entries {
            id
            title
            slug
          }
        }
      }
    }
    fellowPositions: entry(id: 4081) {
      id
      title
      uri

      ... on Fellows {
        fellowYear
        fellowsFormer {
          ...formerFellow
        }
        fellowsCurrent {
          ...fellow
        }
      }
    }
    cultureTabs: entries(section: culture) {
      ... on Culture {
        id
        slug
        title
        cultureBody {
          content
        }
      }
    }
  }

  fragment fellow on Staff {
    id
    title
    position
    url
    slug
    photo {
      id
      title
      url
    }
  }

  fragment formerFellow on Staff {
    id
    title
    position
    url
    slug
  }
`;
