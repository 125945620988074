import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import { imgixSource } from '../config/env';

const possibleIssues = [
  'clean-energy',
  'economy',
  'education',
  'health-care',
  'national-security',
  'politics',
  'social-policy',
];

// This provides a link to a fallback image based on whether an issue in the issues
// array is one of the possibleIssues listed above.
const fallbackImage = (issues = [], prefix = '') => {
  const url = imgixSource.substr(-1) === '/' ? imgixSource : `${imgixSource}/`; // checks to make sure there's a slash at the end of the url

  const issueSlug = find(issues, issue => includes(possibleIssues, issue));

  return `${url}fallback/${prefix}${issueSlug ? issueSlug : 'uncategorized'}.png`;
};

export default fallbackImage;
