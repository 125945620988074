import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Route } from 'react-router-dom';

import AboutPageBio from '../../containers/AboutPageBio';
import AboutPageLanding from '../../containers/AboutPageLanding';
import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Divider from '../Divider';
import JoinOurTeam from '../JoinOurTeam';
import SubscribeBox from '../../containers/SubscribeBox';
import SupportThirdWay from '../SupportThirdWay';
import TeamTabs from '../../containers/TeamTabs';

import './AboutPage.css';

const AboutPage = () => (
  <div className="AboutPage">
    <AppHelmet title="About" />

    <AppFixedHeader />

    <Grid fluid>
      <Route exact path="/about" component={AboutPageLanding} />
      <Route exact path="/about/:category/:slug" component={AboutPageBio} />
      <Route
        exact
        path="/about/:category/:slug/media"
        component={AboutPageBio}
      />

      <Row>
        <Col xs={12}>
          <Divider className="mb-5 mt-2" />
        </Col>
      </Row>

      <TeamTabs />

      <Row>
        <Col xs={12}>
          <JoinOurTeam />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="mb-4" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={6}>
          <SupportThirdWay className="mb-5 mb-lg-auto" />
        </Col>

        <Col xs={12} lg={6}>
          <SubscribeBox className="mb-5 mb-lg-auto" size={"small"} />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default AboutPage;
