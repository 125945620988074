import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Content from '../Content';

import './SponsoredMessage.css';

/**
 * Product sponsorship message.
 */
const SponsoredMessage = (props) => (
  <div className={classNames('SponsoredMessage', props.className)}>
    <Content content={props.message} />
  </div>
);

SponsoredMessage.propTypes = {
  /**
   * Message to display.
   */
  message: PropTypes.string.isRequired,
};

export default SponsoredMessage;
