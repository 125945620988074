import React from 'react';
import Slider from 'react-slick';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

import EntryCard from '../../EntryCard';
import './ContentCarousel.css';

const settings = {
  infinite: false,
  centerMode: true,
  dots: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        centerPadding: '32px',
      },
    },
    {
      breakpoint: 100000,
      settings: 'unslick',
    },
  ],
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
};

const ContentCarousel = ({ items, heading, url }) => {
  if (!items) { return null; }
  const { title } = heading;

  return (
    <div className="ContentCarousel outset inset-lg mb-3">
      <Grid fluid>
        <Row>
          {title &&
            <Col xs={6}>
              <h2 className="mb-3">{title}</h2>
            </Col>
          }
          {url &&
            <Col xs={6} className="text-right">
              <Link to={url} className="label text-uppercase mb-3">
                See All&hellip;
              </Link>
            </Col>
          }
        </Row>
      </Grid>

      <Slider {...settings} className="pb-5">
        {items.map((item, i) => (
          <div className="px-2" key={i}>
            <EntryCard item={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ContentCarousel;
