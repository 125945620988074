import React, { Component } from 'react';
import { filter, find } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import TeamTabsComponent from '../../components/TeamTabs';

import query from './query';

class TeamTabs extends Component {
  constructor(props) {
    super(props);

    this.staffTypes = [
      {
        field: 'leadership',
        slug: 'leadership',
        title: 'Leadership',
      },
      {
        field: 'staff',
        slug: 'staff',
        title: 'Staff',
      },
      {
        field: 'trustees',
        slug: 'board-of-trustees',
        title: 'Board of Trustees',
      },
      {
        field: 'chairs',
        slug: 'honorary-co-chairs',
        title: 'Honorary Co-chairs',
      },
    ];

    this.state = {
      selectedIndex: 0,
      selectedTeam: '',
    };

    this.filterByTeam = this.filterByTeam.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onTeamChange = this.onTeamChange.bind(this);
  }

  filterByTeam(staff) {
    const { selectedTeam } = this.state;
    const { teams } = this.props.data;

    if (!selectedTeam) { return staff; }

    return find(teams.edges, team => team.node.slug === selectedTeam).relatedEntries.entries;
  }

  onTabChange(e) {
    return this.setState({ selectedIndex: e.selectedIndex, selectedTeam: '' });
  }

  onTeamChange(e) {
    return this.setState({ selectedTeam: e.target.value });
  }

  render() {
    const { data } = this.props;

    if (data.loading) { return (<LoadingSpinner fullPage={false} />); }
    if (data.error) { return (<Error error={data.error} />); }

    const sortedStaff = filter(this.staffTypes.map(staffType => {
      // Staff can exist in more than one category, but
      // canonically, there can only be one author
      // As a result, we sometimes may reference an author
      // in a separate category, but maintain a single, true author
      const staff = data[staffType.field].map((member) => {
        if (member.staffReference) { return member.staffReference[0]; }
        return member;
      });

      return ({
        slug: staffType.slug,
        title: staffType.title,
        staff,
      });
    }), staffType => staffType.staff.length > 0);

    const filteredTeams = filter(data.teams.edges, team => team.relatedEntries.totalCount > 0).map(team => ({
      id: team.node.id,
      slug: team.node.slug,
      title: team.node.title,
    }));

    return (
      <TeamTabsComponent
        filterByTeam={this.filterByTeam}
        onTabChange={this.onTabChange}
        onTeamChange={this.onTeamChange}
        selectedIndex={this.state.selectedIndex}
        selectedTeam={this.state.selectedTeam}
        staffTypes={sortedStaff}
        teams={filteredTeams}
      />
    );
  }
}

export default graphql(query)(TeamTabs);
