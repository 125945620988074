import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import CategoryList from '../../containers/CategoryList';

import './WhoWeAre.css';

const WhoWeAre = () => (
  <section className="WhoWeAre pt-3 pt-lg-5 pb-2 pb-lg-5 mb-5">
    <Grid fluid>
      <Row>
        <Col xs={12}>
          {/*
            <h1 className="inverse mb-2">Who We Are</h1>

            <p className="lead inverse mb-5">
              In a time of polarization and populism, Americans deserve better than what they often get from the extremes. And American prosperity and security depend on solutions that are not defined by ideological orthodoxy or narrow interests. Our agenda: economic growth and opportunity, progress on social issues, deep decarbonization to battle climate change, an approach to national security that is both tough and smart, and electoral reforms that empower the middle.
            </p>

            <h2 className="inverse mb-4">Our Areas of Focus</h2>
          */}

          <h1 className="inverse mb-4">Our Areas of Focus</h1>

          <CategoryList />
        </Col>
      </Row>
    </Grid>
  </section>
);

export default WhoWeAre;
