import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import IssuePageFeaturedItem from '../../IssuePageFeaturedItem';

const OneUpLayout = ({ items }) => {
  return (
    <Row className="mb-4">
      {items.map((featuredItem, i) => (
        <Col xs={12} key={i}>
          <IssuePageFeaturedItem
            featuredItem={featuredItem}
            orientation="horizontal"
            showBlurb={true}
          />
        </Col>
      ))}
    </Row>
  );
};

export default OneUpLayout;
