import 'babel-polyfill';

import React, { Component } from 'react';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import AboutPage from '../AboutPage';
import ContactPage from '../../containers/ContactPage';
import DonatePage from '../../containers/DonatePage';
import EventPage from '../../containers/EventPage';
import EventsPage from '../../containers/EventsPage';
import HomePage from '../../containers/HomePage';
import ImpactPage from '../../containers/ImpactPage';
import IssuePage from '../../containers/IssuePage';
import SearchPage from '../SearchPage';
import SeriesPage from '../../containers/SeriesPage';
import Layout from '../Layout';
import NotFoundPage from '../../containers/NotFoundPage';
import OpportunitiesPage from '../../containers/OpportunitiesPage';
import PressPage from '../../containers/PressPage';
import PressReleasePage from '../../containers/PressReleasePage';
import LegalPage from '../../components/LegalPage';
import ProductPage from '../../containers/ProductPage';
import ScrollToTop from '../ScrollToTop';
import StyleguidePage from '../StyleguidePage';
import TweetableRedirect from '../TweetableRedirect';
import { OG_DESCRIPTION, OG_IMAGE } from '../AppHelmet';
import MapPage from '../../containers/MapPage';

// Global styles
import '../../global/reset.css';
import '../../global/carousels.css';
import '../../global/colors.css';
import '../../global/fonts.css';
import '../../global/forms.css';
import '../../global/grid.css';
import '../../global/icons.css';
import '../../global/images.css';
import '../../global/layout.css';
import '../../global/lists.css';
import '../../global/links.css';
import '../../global/outset-inset.css';
import '../../global/overflow.css';
import '../../global/spacing.css';
import '../../global/tables.css';
import '../../global/typography.css';
import '../../global/utility.css';

// Font-Awesome Icons
import fontawesome from '@fortawesome/fontawesome';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';
import faBookmark from '@fortawesome/fontawesome-free-solid/faBookmark';
import faCaretLeft from '@fortawesome/fontawesome-free-solid/faCaretLeft';
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight';
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope';
import faHeart from '@fortawesome/fontawesome-free-solid/faHeart';
import faHome from '@fortawesome/fontawesome-free-solid/faHome';
import faPrint from '@fortawesome/fontawesome-free-solid/faPrint';
import faReply from '@fortawesome/fontawesome-free-solid/faReply';
import faRetweet from '@fortawesome/fontawesome-free-solid/faRetweet';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import faShare from '@fortawesome/fontawesome-free-solid/faShare'
import faThList from '@fortawesome/fontawesome-free-solid/faThList';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faUser from '@fortawesome/fontawesome-free-solid/faUser';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faLinkedinIn from '@fortawesome/fontawesome-free-brands/faLinkedinIn';

fontawesome.library.add(
  faBars,
  faBookmark,
  faCaretLeft,
  faCaretRight,
  faEnvelope,
  faHeart,
  faHome,
  faPrint,
  faReply,
  faRetweet,
  faSearch,
  faShare,
  faThList,
  faTimes,
  faTwitter,
  faUser,
  faYoutube,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
);

class App extends Component {
  constructor() {
    super();

    this.state = {
      googleAnalyticsInitialized: false,
    };
  }

  componentDidMount() {
    ReactGA.initialize('UA-3227100-1');
    this.setState({ googleAnalyticsInitialized: true });

    window.piAId = '887923';
    window.piCId = '3748';
    window.piHostname = 'pi.pardot.com';

    // Initialize the pardot script
    function asyncLoad(){
      const s = document.createElement('script'); s.type = 'text/javascript';

      s.src = ('https:' === document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';

      const c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
    }

    if (window.attachEvent) { window.attachEvent('onload', asyncLoad); }

    else { window.addEventListener('load', asyncLoad, false); }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && window.piTracker) {
      window.piTracker(this.props.location.pathname);
    }
  }

  render() {
    const Analytics = ({ location }) => {
      if (!this.state.googleAnalyticsInitialized) {
        return null;
      }
      const page = location.pathname + location.search;
      ReactGA.set({ page });
      ReactGA.pageview(page);

      return null;
    };

    return (
      <div className="App">
        <ScrollToTop>
          <Helmet
            defaultTitle="Third Way"
            titleTemplate="%s – Third Way"
          >
            <meta property="og:title" content="Third Way" />
            <meta property="og:description" content={OG_DESCRIPTION} />
            <meta property="og:image" content={`${OG_IMAGE}?h=900&w=450`} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>

          <Route component={Analytics} />

          <Layout>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/about/fellows-program" component={OpportunitiesPage} />
              <Route exact path="/about/fellows-program/:slug" component={OpportunitiesPage} />
              <Route exact path="/about/:category/:slug" component={AboutPage} />
              <Route exact path="/about/:category/:slug/media" component={AboutPage} />
              <Route exact path="/contact" component={ContactPage} />
              <Route exact path="/donate" component={DonatePage} />
              <Route exact path="/events" component={EventsPage} />
              <Route exact path="/events/:slug" component={EventPage} />
              <Route exact path="/impact" component={ImpactPage} />
              <Route exact path="/impact/:slug" component={ImpactPage} />
              <Route exact path="/issue/:slug" component={IssuePage} />
              <Route exact path="/series/:slug" component={SeriesPage} />
              <Route exact path="/internships" component={OpportunitiesPage} />
              <Route exact path="/internships/:slug" component={OpportunitiesPage} />
              <Route exact path="/careers" component={OpportunitiesPage} />
              <Route exact path="/careers/:slug" component={OpportunitiesPage} />
              <Route exact path="/culture" component={OpportunitiesPage} />
              <Route exact path="/culture/:slug" component={OpportunitiesPage} />
              <Route exact path="/styleguide" component={StyleguidePage} />
              <Route exact path="/search" component={SearchPage} />
              <Route exact path="/search/events" component={SearchPage} />
              <Route exact path="/search/press-releases" component={SearchPage} />
              <Route exact path="/search/media" component={SearchPage} />
              <Route exact path="/privacy-policy" component={LegalPage} />
              <Route exact path="/terms-of-service" component={LegalPage} />
              <Route exact path="/press" component={PressPage} />
              <Route exact path="/press/media" component={PressPage} />
              <Route exact path="/press/:slug" component={PressReleasePage} />
              <Route exact path="/twitter-redirect" component={TweetableRedirect} />
              <Route exact path="/clean-investment-map" component={MapPage}/>

              {/* Product routes */}
              <Route exact path="/:type/:slug" component={ProductPage} />

              {/* If it doesn't match any of these return NotFound, which also will handle redirects */}
              <Route component={NotFoundPage} />
            </Switch>
          </Layout>
        </ScrollToTop>
      </div>
    );
  }
}

export default withRouter(App);
