import React from 'react';
import classNames from 'classnames';

import './Highlight.css';

const Highlight = (props) => (
  <div className={classNames('Highlight', props.className)}>
    {props.children}
  </div>
)

export default Highlight;
