import React from 'react';
import classNames from 'classnames';

import './ContentLabel.css';

const ContentLabel = (props) => {
  const classes = classNames('ContentLabel', props.className);

  return(
    <div className={classes}>
      {props.children}
    </div>
  );
};

export default ContentLabel;
