import React from 'react';
import PropTypes from 'prop-types';
import { Desktop, Mobile } from '../Devices';
import { Row, Col } from 'react-flexbox-grid';

import ContentCarousel from './ContentCarousel';
import ContentRow from './ContentRow';

const RelatedContent = ({ items, heading, url }) => {
  if (!items) { return null; }

  return (
    <div className="RelatedContent">
      <Row>
        <Desktop>
          <Col xs={12}>
            <ContentRow items={items} heading={heading} url={url} />
          </Col>
        </Desktop>

        <Mobile>
          <Col xs={12}>
            <ContentCarousel items={items} heading={heading} url={url} />
          </Col>
        </Mobile>
      </Row>
    </div>
  );
};

RelatedContent.propTypes = {
  items: PropTypes.array,
  url: PropTypes.string,
  title: PropTypes.shape({
    title: PropTypes.string,
    size: PropTypes.oneOf(['small', 'large'])
  }),
};

export default RelatedContent;
