import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import issueFilterShape from '../../shapes/issueFilterShape';
import IssueFiltersComponent from '../../components/IssueFilters';
import searchShape from '../../shapes/searchShape';
import withContentFilters from '../../wrappers/withContentFilters';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withSearch from '../../wrappers/withSearch';

import query from './query';

class IssueFilters extends Component {
  static propTypes = {
    data: dataShape.isRequired,
    issueFilter: issueFilterShape.isRequired,
    issueID: PropTypes.number,
    parentID: PropTypes.number,
    search: searchShape.isRequired,
  };

  constructor() {
    super();

    this.onShowLess = this.onShowLess.bind(this);
    this.onShowMore = this.onShowMore.bind(this);

    this.state = {
      showMore: false,
    };
  }

  onShowLess() {
    this.setState({ showMore: false });
  }

  onShowMore() {
    this.setState({ showMore: true });
  }

  render() {
    const { data, filters = ['issues'], issueFilter = false, contentTypeFilter = false, authorFilter, team } = this.props;
    const { error, loading, categoriesConnection, allTopics } = data;

    if (loading) { return null; }
    if (error) { return (<Error error={error} />); }

    const tags = filter(get(categoriesConnection, 'edges'),
      issue => get(issue, 'relatedEntries.totalCount') > 0
    ).map(issue => ({
      count: get(issue, 'relatedEntries.totalCount'),
      id: get(issue, 'node.id'),
      slug: get(issue, 'node.slug'),
      title: get(issue, 'node.title'),
      uri: get(issue, 'node.uri'),
    }));

    tags.sort((a, b) => b.count - a.count);

    return (
      <IssueFiltersComponent
        authorFilter={filters.includes('author') ? authorFilter : false}
        contentTypeFilter={filters.includes('contentType') ? contentTypeFilter : false}
        issueFilter={issueFilter}
        showMore={this.state.showMore}
        onShowLess={this.onShowLess}
        onShowMore={this.onShowMore}
        tags={tags}
        team={team}
        totalCount={allTopics.totalCount}
      />
    );
  }
}

const withGraphql = graphql(query, {
  options: ({
    contentTypeFilter: { currentContentType },
    issueID,
    match: { path },
    search = {},
    parentID,
  }) => {
    const variables = {
      issueID: null,
      searchQuery: null,
      section: null,
      slug: null,
      parentID: parentID ? [{ targetElement: parentID }] : undefined,
    };

    // This filter is used on an assortment of pages, and the variables change
    // according to where it's being used.
    // Make sure to add appropriate variables here whenever you add the issue
    // filters to a new route.
    switch(path) {
      case '/issue':
        variables.issueID = issueID;
        variables.section = currentContentType ? [currentContentType] : ['products', 'series', 'inTheNews', 'pressReleases', 'events'];
        break;

      case '/issue/:slug':
        variables.issueID = issueID;
        variables.section = currentContentType ? [currentContentType] : ['products', 'series', 'inTheNews', 'pressReleases', 'events'];
        break;

      case '/press':
        variables.section = 'pressReleases';
        break;

      case '/press/media':
        variables.section = 'inTheNews';
        break;

      case '/search':
        variables.searchQuery = search.currentSearchQuery;
        variables.section = ['products', 'series'];
        break;

      case '/search/events':
        variables.searchQuery = search.currentSearchQuery;
        variables.section = 'events';
        break;

      case '/search/press-releases':
        variables.searchQuery = search.currentSearchQuery;
        variables.section = 'pressReleases';
        break;

      case '/search/media':
        variables.searchQuery = search.currentSearchQuery;
        variables.section = 'inTheNews';
        break;

      default:
        break;
    }

    return ({
      variables
    });
  },
})(IssueFilters);

export default withContentFilters(
  withIssueFilter(
    withSearch(withGraphql)
  )
);
