import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import LoadingSpinner from '../LoadingSpinner';
import PaginatedEntry from '../PaginatedEntry';
import Pagination from '../Pagination';
import paginationShape from '../../shapes/paginationShape';
import itemShape from '../../shapes/itemShape';

const PaginatedEntries = (props) => {
  const {
    items = [],
    loading,
    displayThumbnail = true,
    noItemsMessage,
    pagination,
    title,
    totalItems
  } = props;

  if (totalItems === 0) {
    return noItemsMessage ? (
      <h3 className="mb-5 no-results">
        {noItemsMessage}
      </h3>
    ) : null;
  }

  return (
    <Row className="PaginatedEntries">
      <Col xs={12}>
        {title &&
          <h2 className="mb-3">{title}</h2>
        }

        {loading ? (
          <LoadingSpinner fullPage={false} />
        ) : (
          items.map((item, i) => (
            <PaginatedEntry key={i} item={item} displayThumbnail={displayThumbnail} />
          ))
        )}

        <Pagination
          className="mb-5"
          pagination={pagination}
          total={totalItems}
        />
      </Col>
    </Row>
  );
};

PaginatedEntries.propTypes = {
  items: PropTypes.arrayOf(itemShape),
  displayThumbnail: PropTypes.bool,
  loading: PropTypes.bool,
  noItemsMessage: PropTypes.string,
  pagination: paginationShape.isRequired,
  title: PropTypes.string,
  totalItems: PropTypes.number,
};

export default PaginatedEntries;
