import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';

import CardStackComponent from '../../components/CardStack';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

class CardStack extends Component {
  static propTypes = {
    data: dataShape,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  };

  constructor() {
    super();

    this.state = {
      currentIndex: 0,
    };

    this.setIndex = this.setIndex.bind(this);
  }

  setIndex(i) {
    return this.setState({ currentIndex: i });
  }

  render() {
    const { cardStack, error, loading } = this.props.data;

    if (loading) return <LoadingSpinner fullPage={false} />;
    if (error) return <Error error={error} />;
    if (!cardStack) return null;

    return (
      <CardStackComponent
        cardStack={cardStack}
        currentIndex={this.state.currentIndex}
        setIndex={this.setIndex}
      />
    );
  }
}

export default graphql(query, {
  options: ({ id }) => ({
    variables: { id: [Number(id)] },
  }),
})(CardStack);
