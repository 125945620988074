import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Responsive from 'react-responsive';
import { Link } from 'react-router-dom';

import AppLogo from '../AppLogo';
import AppSearch from '../../containers/AppSearch';
import IssuesNav from '../../containers/IssuesNav';
import JumpToSection from '../JumpToSection';
import SocialSharing from '../SocialSharing';

import './AppFixedHeader.css';

const AppFixedHeader = (props) => {
  const {
    fixedHeaderVisible,
    issuesMenuOpen,
    jumpToOpen,
    mainIssue,
    onSearchClose,
    onSearchOpen,
    onShareClose,
    searchOpen,
    sections,
    setFixedHeaderRef,
    shareOpen,
    sharing,
    toggleIssues,
    toggleJumpToOpen,
    toggleShareOpen,
    title,
  } = props;

  return (
    <header
      className={classNames("AppFixedHeader", { "header-visible": fixedHeaderVisible })}
      ref={setFixedHeaderRef}
    >
      <div className="header-container">
        {title ? (
          <Link to="/">
            <div className="logo" />
          </Link>
        ) : (
          <AppLogo />
        )}

        {title &&
          <h4 className="title">
            {title}
          </h4>
        }

        {issuesMenuOpen &&
          <IssuesNav fixed />
        }

        <ul className="navigation list-unstyled">
          {!searchOpen && sections && sections.length > 0 &&
            <li>
              <JumpToSection
                header
                menuOpen={jumpToOpen}
                sections={sections}
                toggleMenu={toggleJumpToOpen}
              />
            </li>
          }

          {!searchOpen && sharing &&
            <Responsive maxWidth={989}>
              <li className={classNames('share', { "share-open": shareOpen })}>
                <h4
                  onClick={toggleShareOpen}
                >
                  <FontAwesomeIcon icon={['fas', 'share']} size="lg" className="mr-2" />
                  Share
                </h4>

                <SocialSharing
                  body={sharing.body}
                  onClick={onShareClose}
                  title={sharing.title}
                />
              </li>
            </Responsive>
          }

          <Responsive minWidth={990}>
            <li className="main-issue">
              <h4 onClick={toggleIssues}>
                {mainIssue ? (
                  <span>
                    <i className={`icon icon-${mainIssue.issueIcon}`} /> {mainIssue.title}
                  </span>
                ) : (
                  <span>Issues</span>
                )}
              </h4>
            </li>
          </Responsive>

          <Responsive minWidth={990}>
            <li>
              <h4>
                <Link to="/about">
                  About
                </Link>
              </h4>
            </li>
          </Responsive>

          <Responsive maxWidth={959}>
            <li>
              <span className="menu-toggle" onClick={toggleIssues}>
                {issuesMenuOpen &&
                  <i className="icon icon-close" />
                }
                {!issuesMenuOpen &&
                  <FontAwesomeIcon icon={['fas', 'bars']} size="lg" className="c-pointer" />
                }
              </span>
            </li>
          </Responsive>

          <li>
            <AppSearch
              onSearchClose={onSearchClose}
              onSearchOpen={onSearchOpen}
            />
          </li>

          {sharing &&
            <Responsive minWidth={990}>
              <li>
                <SocialSharing
                  body={sharing.body}
                  title={sharing.title}
                />
              </li>
            </Responsive>
          }
        </ul>
      </div>
    </header>
  );
};

export default AppFixedHeader;
