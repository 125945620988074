import React, { Component } from 'react';
import { googleMapsClient } from '../../config/env';
import GoogleMapComponent from './GoogleMap';

import './Map.css';

class MapComponent extends Component {
  constructor() {
    super();
    this.state = {
      geocode: null,
    }
  }

  componentWillMount() {
    googleMapsClient.geocode({address: this.props.address}, (err, response) => {
      if (err) { return null; }

      const geocode =  response.json.results[0].geometry.location;
      this.setState({ geocode });
    });
  }

  render() {
    const { geocode } = this.state;

    if (!geocode) { return null; }

    return <GoogleMapComponent location={geocode} />;
  }
}

export default MapComponent;
