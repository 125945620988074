const originPattern = /^(?:https?:\/\/)(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/;

const urlIsExternal = (baseUrl) => {
  if (!baseUrl) return false;

  const baseDomain = baseUrl.match(originPattern);

  // Relative URLs wont have a domain
  if (baseDomain === null) return false;

  // We were returning false here if the baseDomain matched the window's location's domain, but
  // this is causing an issue where we're passing a full url to the Link component. Better to just
  // assume urls with a domain / https are going to be external links.
  return true;
};

export default urlIsExternal;
