import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query {
    globals {
      companyInfo {
        globalAddressLine1
        globalAddressLine2
        globalAddressLine3
        globalEmail
        globalPhone
        globalFax
        globalTumblr
        globalTwitter
        globalFacebook
        globalLinkedin

        pressContact {
          id
          ...on Staff {
            ...Staff
          }
        }

        eventContact {
          id
          ...on Staff {
            ...Staff
          }
        }
      }
    }

    categories(groupId: 3, level: 1) {
      id
      slug
      title
      ... on IssuesCategory {
        issueHidden
        issueIcon
        issueSocialMediaAccount {
          ...socialMedia
        }
      }
    }
  }

  fragment socialMedia on IssueSocialMediaAccountUnion {
    ... on IssueSocialMediaAccountTwitterAccount {
      twitterHandle
    }
    ... on IssueSocialMediaAccountInstagramAccount {
      instagramName
    }
  }

  ${StaffFragment}
`;
