import React from 'react';
import Imgix from 'react-imgix';
import { Row, Col } from 'react-flexbox-grid';

import fallbackImage from '../../utils/fallbackImage';

import './EventFeaturedImage.css';

const EventFeatureImage = ({ event }) => {
  if (!event) { return null; }

  let imageTitle, imageUrl;

  if (event.eventPhotos[0]) {
    imageTitle = event.eventPhotos[0].title;
    imageUrl = event.eventPhotos[0].url;
  } else {
    imageTitle = event.title;
    imageUrl = fallbackImage(event.eventIssue ? event.eventIssue.map(issue => issue.slug) : []);
  }

  const imageSources = [
   {
    height: 640,
    width: 960,
    minWidth: 1200,
   },
   {
    height: 510,
    width: 1080,
    minWidth: 770,
   },
   {
    height: 510,
    width: 770,
    minWidth: 500,
   },
  ];

  return (
    <div className="EventFeatureImage">
      <Row>
        <Col xs={12}>
          <div className="mb-2 outset inset-xl">
            <Imgix
              aggressiveLoad
              src={imageUrl}
              type='picture'
            >
              {imageSources.map((source, i) => (
                <Imgix
                  aggressiveLoad
                  height={source.height}
                  imgProps={{
                    alt: imageTitle,
                    media: `(min-width: ${source.minWidth}px)`
                  }}
                  key={i}
                  crop="center"
                  src={imageUrl}
                  type="source"
                  width={source.width}
                />
              ))}

              <Imgix
                aggressiveLoad
                height={255}
                imgProps={{
                  alt: imageTitle,
                }}
                crop="center"
                src={imageUrl}
                type="img"
                width={500}
              />
            </Imgix>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventFeatureImage;
