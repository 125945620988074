// formatMoney()
// Yanked from: http://www.josscrowcroft.com/2011/code/format-unformat-money-currency-javascript/
//
// n = Number
// p = Places
// s = Symbol
// t = Thousand
// d = Decimal
const formatMoney = (n, p, s, t, d) => {
  let number = n || 0;
  let places = !isNaN((p = Math.abs(p))) ? p : 2;
  let symbol = s !== undefined ? s : '$';
  let thousand = t || ',';
  let decimal = d || '.';
  let negative = number < 0 ? '-' : '',
    i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + '';

  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (places
      ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : '')
  );
};

export default formatMoney;
