import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query {
    leadership: entries(section: staff, descendantOf: 4934) {
      ...StaffEntry
    }

    staff: entries(section: staff, descendantOf: 4935) {
      ...StaffEntry
    }

    trustees: entries(section: staff, descendantOf: 4936) {
      ...StaffEntry
    }

    chairs: entries(section: staff, descendantOf: 4937) {
      ...StaffEntry
    }

    teams: entriesConnection(type: Team, hiddenTeam: false) {
      edges {
        node {
          id
          slug
          title
        }

        relatedEntries(type: Staff) {
          totalCount

          entries {
            ...StaffEntry
          }
        }
      }
    }
  }

  fragment StaffEntry on EntryInterface {
    ... on StaffStaffReference {
      staffReference {
        __typename
        ...Staff
      }
    }

    ... on Staff {
      ...Staff
    }
  }

  ${StaffFragment}
`;
