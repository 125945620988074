import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query EventPageQuery($slug: String) {
    event: entry(
      orderBy: "eventStartDate desc",
      slug: $slug,
      type: Events,
    ) {
      id
      slug
      title
      postDate
      ...on Events {
        eventDescription {
          content
        }
        eventBriteId
        eventEndDate
        eventStartDate
        eventHidePressContact
        eventLocationAddress
        eventLocationCity
        eventLocationState
        eventLocationName
        eventLocationZip

        eventContact {
          __typename
          id
          title

          ...on Staff {
            ...Staff
          }

          ...on ExternalAuthors {
            email
            position
          }
        }

        eventPhotos {
          url
          id
        }

        eventIssue {
          id
          issueIcon
          slug
          title
        }

        featuredVideo {
          ... on FeaturedVideoYoutubeVideo {
            youtubeId
          }
           ... on FeaturedVideoFacebookVideo {
            facebookVideoUrl
          }
        }
      }
    }

    globals {
      companyInfo {
        pressContact {
          id
          title
          uri

          ...on Staff {
            ...Staff
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
