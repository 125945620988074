import React from 'react';
import { graphql } from 'react-apollo';

import CaseStudyComponent from '../../components/CaseStudy';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import dataShape from '../../shapes/dataShape';
import query from './query';

const CaseStudy = ({ data }) => {
  const { error, caseStudy, loading } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!caseStudy) { return (<NotFoundPage />); }

  return (
    <CaseStudyComponent caseStudy={caseStudy} />
  );
};

CaseStudy.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ slug }) => ({
    variables: {
      slug,
    },
  }),
})(CaseStudy);
