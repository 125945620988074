import React from 'react';
import MediaQuery from 'react-responsive';
import sanitizeHtml from 'sanitize-html';
import { filter, get } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import AuthorList from '../AuthorList';
import Content from '../Content';
import Divider from '../Divider';
import fallbackImage from '../../utils/fallbackImage';
import PaginatedEntry from '../PaginatedEntry';
import SectionLabel from '../SectionLabel';
import SeriesPageFeaturedImage from '../SeriesPageFeaturedImage';
import SeriesPageIssueFilters from '../../containers/SeriesPageFilters';
import SeriesGroup from '../SeriesGroup';
import SubscribeBox from '../../containers/SubscribeBox';

import './SeriesPage.css';

const SeriesPage = ({ items, groups, series, team}) => {
  if (!series) { return null; }

  return (
    <div className="SeriesPage">
      <AppHelmet
        description={get(series, 'body.content') ? sanitizeHtml(get(series, 'body.content'), { allowedTags: [] }) : undefined}
        image={get(series, 'seriesFeaturedImage[0].url') ? `https:${series.seriesFeaturedImage[0].url}` : fallbackImage(series.issues.map(issue => issue.slug))}
        title={get(series, 'title')}
      />

      <AppFixedHeader
        mainIssue={filter(series.issues, issue => issue.issueIcon)[0] || null}
        title={series.title}
      />

      <SeriesPageFeaturedImage
        series={series}
      />

      <Grid fluid>
        <Row>
          <Col xs={12}>
            <SectionLabel className="mb-3" name="Series" />

            <h1 className="mb-4">{series.title}</h1>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Content
              className="deck"
              content={series.body ? series.body.content : ''}
              sanitizeContent
            />
          </Col>
        </Row>

        <Divider className="my-4" />

        <Row className="mb-5">
          <MediaQuery minWidth={768}>
            <Col xs={12} md={5} lg={3}>
              <div className="pr-lg-3 pr-md-3">
                <SubscribeBox heading={"Stay Updated"} size={"compact"} />
              </div>
              <div className="mb-5 topic-tags">
                <SeriesPageIssueFilters
                  team={team}
                  parentID={series.id}
                  />
              </div>

              {team && (
                <>
                  <SectionLabel name="Team" className="mb-3" />
                  <div className="team-avatars">
                    <Row>
                      <Col xs={12}>
                        <AuthorList
                          authors={team}
                          itemClassName="mb-2"
                          small
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Col>
          </MediaQuery>

          <Col xs={12} md={7} lg={9}>
            <div className="series-items">
              {groups && groups.length > 0 && (
                groups.filter((group) => group.relatedProducts.length).map((group, i) =>
                  <React.Fragment key={i}>
                    <SeriesGroup group={group} />
                    {i === 0 && (
                      <MediaQuery maxWidth={767}>
                        <div className="mt-5 mb-5">
                          <SubscribeBox heading={"Stay Updated"} size={"compact"} />
                        </div>
                      </MediaQuery>
                    )}
                  </React.Fragment>
                )
              )}
              {(!groups || groups.length === 0) && (
                <>
                  {items.map((item) =>
                    <PaginatedEntry key={item.id} item={item} displayThumbnail />
                  )}
                  <MediaQuery maxWidth={767}>
                    <div className="mt-5">
                      <SubscribeBox heading={"Stay Updated"} size={"compact"} />
                    </div>
                  </MediaQuery>
                </>
              )}
            </div>
            <MediaQuery maxWidth={767}>
            <SectionLabel name="Team" className="mb-3" />
              <AuthorList
                authors={team}
                itemClassName="mb-2"
                simple
              />
            </MediaQuery>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default SeriesPage;
