import React from 'react';
import classNames from 'classnames';
import { NavLink, Route } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../AppHelmet';
import CaseStudy from '../../containers/CaseStudy';
import Divider from '../Divider';
import JoinOurTeam from '../JoinOurTeam';
import SideNavList from '../SideNavList';
import SupportThirdWay from '../SupportThirdWay';
import SubscribeBox from '../../containers/SubscribeBox';
import TopicLabel from '../TopicLabel';

import './ImpactPage.css';

const ImpactPage = ({ match, entries = [] }) => {
  return (
    <div className="ImpactPage">
      <AppHelmet title="Impact" />
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <h1 className="mb-4">Our Impact</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Divider className="mb-4 mb-lg-5" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className="pr-lg-5">
            <TopicLabel name="Impact Case Studies" className="mb-2" />
            <SideNavList className="mb-4">
              <ul className="list-unstyled">
                {entries.map((entry, index) => {
                  const active =
                    RegExp('^/impact/?$').test(match.url) && index === 0;
                  return (
                    <li key={entry.id}>
                      <NavLink
                        to={{
                          pathname: `/impact/${entry.slug}`,
                          state: { noScroll: true },
                        }}
                        className={classNames({ active })}
                      >
                        {entry.impactShortTitle}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </SideNavList>
          </Col>
          <Col xs={12} lg={8}>
            <Route
              exact
              path="/impact"
              component={() => {
                if (entries.length > 0) {
                  return <CaseStudy slug={entries[0].slug} />;
                }
                return <h1>No Current Case Studies were found.</h1>;
              }}
            />
            <Route
              exact
              path="/impact/:slug"
              component={() => <CaseStudy slug={match.params.slug} />}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <JoinOurTeam className="mt-4" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Divider className="mb-4 mb-lg-5" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <SupportThirdWay className="mb-5 mb-lg-auto" />
          </Col>
          <Col xs={12} lg={6}>
            <SubscribeBox className="mb-5 mb-lg-auto" size={"small"} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ImpactPage;
