import gql from 'graphql-tag';

export default gql`
  query IssueFiltersQuery(
    $issueIDs: [Int],
  ) {
    categoriesConnection(
      id: $issueIDs,
      groupId: 3,
      level: 2
    ) {
      edges {
        node {
          id
          slug
          title
          uri
        }

        relatedEntries(
          public: true,
        ) {
          totalCount
        }
      }
    }
  }
`;
