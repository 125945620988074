import gql from 'graphql-tag';

export default gql`
  query CultureQuery($slug: String) {
    culture: entry(slug: $slug) {
      id
      slug
      title
      ... on Culture {
        cultureBody {
          content
        }
      }
    }
  }
`;
