import React from 'react';

import './OpportunitiesHeroImage.css';

const OpportunitiesHeroImage = ({ src }) => (
  <div
    className="OpportunitiesHeroImage"
    style={{ backgroundImage: 'url(' + src + ')' }}
  />
);

export default OpportunitiesHeroImage;
