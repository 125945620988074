import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IssueTag from '../IssueTag';
import tagShape from '../../shapes/tagShape';

import './IssueTagList.css';

const IssueTagList = ({ className, currentIssueID, onClick, tags = [], totalCount }) => (
  <ul className={classNames('IssueTagList', className)}>
    <li>
      <IssueTag
        active={currentIssueID === 0}
        count={totalCount}
        onClick={() => onClick(0)}
        tag={{title: 'All Topics'}}
      />
    </li>
    {tags.map((tag) => (
      <li key={tag.id}>
        <IssueTag
          active={currentIssueID === tag.id}
          count={tag.count}
          onClick={() => onClick(tag)}
          tag={tag}
        />
      </li>
    ))}
  </ul>
);

IssueTagList.propTypes = {
  /**
   * A callback function when a tag is selected.
   */
  onClick: PropTypes.func,
  /**
   * The list of tags to display.
   */
  tags: PropTypes.arrayOf(tagShape).isRequired,
};

IssueTagList.defaultProps = {
  onClick: () => {},
}

export default IssueTagList;
