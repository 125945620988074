import gql from 'graphql-tag';

export default gql`
  query CategoriesQuery {
    globals {
      subscriptions {
        globalSubscribe {
          content
        }
      }
    }
  }
`;
