import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

export const OG_DESCRIPTION = "Third Way is a center-left think tank that champions modern solutions to the most challenging problems in US public policy, including the economy, climate and energy, national security, social policy, and politics.";
export const OG_IMAGE = "https://thirdway.imgix.net/fallback/uncategorized.png";

// This provides some defaults for our sharing meta tags so we don't have to rewrite them on every component
const AppHelmet = ({ description, image, title }) => {
  // Ensure we have http/https
  const cmsImage =
    image && image.indexOf('://') === -1 ? `https:${image}` : image;
  // Ensure we strip craft generated cache stamp
  const ogImage = cmsImage ? cmsImage.split('?')[0] : OG_IMAGE;

  return (
    <Helmet title={title}>
      <meta
        property="og:title"
        content={title ? `${title} – Third Way` : 'Third Way'}
      />
      <meta
        property="og:description"
        content={description || OG_DESCRIPTION}
      />
      <meta property="og:image" content={`${ogImage}?w=900&h=450&fit=crop`} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

AppHelmet.propTypes = {
  // The description of the page
  description: PropTypes.string,
  // The image to use as a thumbnail on share
  image: PropTypes.string,
  // The page title
  title: PropTypes.string,
};

export default AppHelmet;
