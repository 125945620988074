import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Button from '../Button'
import ContentLabel from './ContentLabel';
import Divider from '../Divider';
import ElementLabel from './ElementLabel';
import Input from '../Input';
import Label from '../Label';
import IssueTag from '../IssueTag';

import './StyleguidePage.css';

const StyleguidePage = () => (
  <div className="StyleguidePage pt-5">
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <ContentLabel className="mb-2">Colors</ContentLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6} md={3} lg className="mb-3">
              <div className="color-box" style={{backgroundColor: '#2B2B2B'}}>#2B2B2B</div>
            </Col>
            <Col xs={12} sm={6} md={3} lg className="mb-3">
              <div className="color-box" style={{backgroundColor: '#F7F3EC', color: '#2B2B2B'}}>#F7F3EC</div>
            </Col>
            <Col xs={12} sm={6} md={3} lg className="mb-3">
              <div className="color-box" style={{backgroundColor: '#FFA11E'}}>#FFA11E</div>
            </Col>
            <Col xs={12} sm={6} md={3} lg className="mb-3">
              <div className="color-box" style={{backgroundColor: '#3A6596'}}>#3A6596</div>
            </Col>
            <Col xs={12} sm={6} md={3} lg className="mb-5">
              <div className="color-box" style={{backgroundColor: '#F3F3F3', color: '#2B2B2B'}}>#F3F3F3</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ContentLabel className="mb-3">Typefaces</ContentLabel>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs className="mb-3">
          <div className="typeface-box">
            <h1 className="brand-sans-serif">League Spartan</h1>
            <ElementLabel>Typeface used for headings, labels, etc</ElementLabel>
          </div>
        </Col>
        <Col xs className="mb-3">
          <div className="typeface-box">
            <h1 className="brand-serif" style={{fontWeight: 'normal'}}>Merriweather</h1>
            <ElementLabel>Typeface used for body text, user-entered text, etc</ElementLabel>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ContentLabel className="mb-3">Text Styles</ContentLabel>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={6}>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h1>H1 Heading</h1>
            </Col>
            <Col xs={5}>
              <ElementLabel>38/46pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h2>H2 Heading</h2>
            </Col>
            <Col xs={5}>
              <ElementLabel>28/38pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h3>H3 Heading</h3>
            </Col>
            <Col xs={5}>
              <ElementLabel>24/30pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h4>H4 Heading</h4>
            </Col>
            <Col xs={5}>
              <ElementLabel>20/30pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h5>H5 Heading</h5>
            </Col>
            <Col xs={5}>
              <ElementLabel>17/20pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <h6>H6 Heading</h6>
            </Col>
            <Col xs={5}>
              <ElementLabel>14/21pt League Spartan Bold</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <dt>DT Label</dt>
            </Col>
            <Col xs={5}>
              <ElementLabel>12/18pt League Spartan Bold, uppercase, 45% opacity, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <dt><a href="/styleguide">DT Label Link</a></dt>
            </Col>
            <Col xs={5}>
              <ElementLabel>12/18pt League Spartan Bold, uppercase, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4 align-baseline">
            <Col xs={7} className="text-right">
              <dt><a href="/styleguide" className="label">Label Link</a></dt>
            </Col>
            <Col xs={5}>
              <ElementLabel>#3A6596 League Spartan</ElementLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row className="align-baseline">
            <Col xs>
              <ContentLabel className="mb-2 mr-3">Body Type</ContentLabel>
              <ElementLabel>16/30pt Merriweather</ElementLabel>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs>
              <p className="mb-3">In certain parts of the country, <a href="/">entrepreneurship is booming</a>. A well-educated creative class clusters in tight networks that easily attract bank loans, birth new businesses and create exciting jobs. But this opportunity paradise is far too concentrated, and even within these areas a whole segment of the population seems forgotten, namely women and women of color.</p>
            </Col>
            <Col xs={3}>
              <ContentLabel>Link</ContentLabel>
              <br />
              <ElementLabel>2pt #FFA11E<br />underline</ElementLabel>
            </Col>
          </Row>
          <Row className="align-baseline">
            <Col xs>
              <ContentLabel className="mb-2 mr-3">Small Type</ContentLabel>
              <ElementLabel>13/20pt</ElementLabel>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <small className="text-serif">Choose the Issues and Topics for which you wish to receive updates. As always, we&rsquo;ll never share your info with anyone.</small>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12}>
          <ContentLabel className="mr-2">HR (Horizontal Rule)</ContentLabel>
          <ElementLabel>15pt #2b2b2b</ElementLabel>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider className="mb-5" />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ContentLabel className="mb-3">Form &amp; Button Styles</ContentLabel>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={7}>
          <Row middle="xs" className="mb-3">
            <Col xs={6} className="text-right">
              <Button primary large>
                Primary Button
              </Button>
            </Col>
            <Col xs={6}>
              <ElementLabel>16pt League Spartan Bold, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row middle="xs" className="mb-3">
            <Col xs={6} className="text-right">
              <Button secondary>
                Secondary Button
              </Button>
            </Col>
            <Col xs={6}>
              <ElementLabel>12pt League Spartan Bold, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row middle="xs" className="mb-5">
            <Col xs={6} className="text-right">
              <Button primary small>
                Tertiary Button
              </Button>
            </Col>
            <Col xs={6}>
              <ElementLabel>12pt League Spartan Bold, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row top="xs" className="mb-3">
            <Col xs={6} className="text-right">
              <IssueTag name="Content Tag" count={42} />
            </Col>
            <Col xs={6}>
              <ElementLabel>Tag: 12pt League Spartan Bold, 0.5 character spacing</ElementLabel>
              <ElementLabel>Number: 11pt Merriweather Heavy, 45% opacity</ElementLabel>
            </Col>
          </Row>
          <Row top="xs" className="mb-3">
            <Col xs={6} className="text-right">
              <IssueTag name="Content Tag" count={42} active />
            </Col>
            <Col xs={6}>
              <ElementLabel>Tag: 12pt League Spartan Bold, 0.5 character spacing</ElementLabel>
              <ElementLabel>Number: 11pt Merriweather Heavy, 60% opacity</ElementLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={5}>
          <Row className="align-baseline mb-3">
            <Col xs={6}>
              <Label className="mr-3">Text Field Label</Label>
            </Col>
            <Col xs={6}>
              <ElementLabel>11/17pt League Spartan Bold, uppercase, 0.5 character spacing</ElementLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Label>Text Field Label</Label>
            </Col>
          </Row>
          <Row middle="xs" className="mb-3">
            <Col xs={6}>
              <Input defaultValue="User-entered text" className="mr-2" />
            </Col>
            <Col xs={6}>
              <ElementLabel>13/20pt Merriweather</ElementLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Label>Text Field Label</Label>
            </Col>
          </Row>
          <Row middle="xs" className="mb-3">
            <Col xs={6}>
              <Input placeholder="Placeholder text" className="mr-2" />
            </Col>
            <Col xs={6}>
              <ElementLabel>13/20pt Merriweather, 50% Opacity</ElementLabel>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default StyleguidePage;
