import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import IssuePageFeaturedItem from '../../IssuePageFeaturedItem';

const TwoUpLayout = ({ items }) => {
  return (
    <Row>
      {items.map((featuredItem, i) => (
        <Col xs={12} md={6} key={i}>
          <IssuePageFeaturedItem
            featuredItem={featuredItem}
            orientation="vertical"
            showBlurb={false}
            showAuthors={false}
          />
        </Col>
      ))}
    </Row>
  );
};

export default TwoUpLayout;
