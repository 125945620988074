import gql from 'graphql-tag';

export default gql`
  query AboutPageBioIssueFiltersQuery(
    $section: [SectionsEnum],
    $slug: String,
  ) {
    entriesConnection(slug: $slug, type: Staff) {
      entries {
        __typename
        id
        slug
        title
      }

      edges {
        relatedEntries(
          section: $section,
          public: true,
        ) {
          totalCount

          entries {
            id

            ...on Products {
              issues: productIssue {
                id
                slug
                title
              }
            }

            ...on InTheNews {
              issues {
                title
                id
              }
            }
          }
        }
      }
    }
  }
`;
