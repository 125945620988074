import gql from 'graphql-tag';

export default gql`
  query CaseStudyQuery($slug: String) {
    caseStudy: entry(slug: $slug) {
      id
      title
      ...on Impact {
        body {
          content
        }
      }
    }
  }
`;
