import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';

import './index.css';
import { hmrEnabled } from './config/env';
import App from './components/App';
// import registerServiceWorker from './registerServiceWorker';
import client from './apollo';

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
, document.getElementById('root'));

// registerServiceWorker();

if (hmrEnabled && module.hot) {
  module.hot.accept();
}
