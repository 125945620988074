import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Modal';
import Content from '../../Content';
import CloseIcon from '../../../icons/Close-X.svg'
import TechnologyIcon from '../TechnologyIcon';
import './MapPageSnapshotModal.css';

const SnapshotModal = ({snapshot, closeHandler}) => {
  return (
    <Modal className="SnapshotModal" label={snapshot.title} onClickOutside={closeHandler}>
      <div className="modal-body">
        <button className="close-button c-pointer" aria-label={`Close ${snapshot.title} details`} onClick={closeHandler}>
          <img src={CloseIcon} alt="" aria-hidden="true" />
        </button>
        <div className="content">
          <div className="heading">
            {snapshot.energyTechnology.map((tech) => (
              <TechnologyIcon slug={tech.slug} />
            ))}
            <h2 className="text-paragraph-large">{snapshot.title}</h2>
            <p className="location text-caption mt-2 mb-xl-0">{snapshot.city}, {snapshot.state}</p>
          </div>
          <div className="content-container">
            <Content content={snapshot.body.content} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

SnapshotModal.propTypes = {
  snapshot: PropTypes.object,
  onClickOutside: PropTypes.func,
};

export default SnapshotModal;
