import React from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { googleMapsApiKey } from '../../config/env';

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleMapsApiKey}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className="googleMap" />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={props.location}
    options={{
      mapTypeEnabled: false,
      streetViewEnabled: false,
      zoomEnabled: false,
    }}
  >
    <Marker position={props.location} />
  </GoogleMap>
);
