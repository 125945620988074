import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash-es/get';

import ApplyForThisPosition from '../ApplyForThisPosition';
import Content from '../Content';
import SocialSharing from '../SocialSharing';

const Career = ({ career }) => (
  <div className="Career">
    <div>
      <Row>
        <Col xs={12}>
          <h1 className="mb-4">{career.title}</h1>
        </Col>
      </Row>
      <Row className="hidden-print">
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={career.positionUrl} />
            <SocialSharing
              trackClicks={true}
              title={career.title}
              postDate={career.postDate}
              productType="Career"
            />
          </div>
        </Col>
      </Row>
      <Row className="hidden-screen">
        <Col xs={12} className="mb-4">
          <small>
            <a href={career.positionUrl}>{career.positionUrl}</a>
          </small>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Content content={get(career, 'body.content', 'No content.')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h2 className="mb-4">Interested?</h2>
        </Col>
      </Row>
      <Row className="hidden-print">
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={career.positionUrl} />
            <SocialSharing />
          </div>
        </Col>
      </Row>
      <Row className="hidden-screen">
        <Col xs={12} className="mb-4">
          <small>
            <a href={career.positionUrl}>{career.positionUrl}</a>
          </small>
        </Col>
      </Row>
    </div>
  </div>
);

export default Career;
