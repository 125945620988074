import React from 'react';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import EventPageComponent from '../../components/EventPage';

import query from './query';

const EventPage = ({ data }) => {
  const { error, event, globals, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }
  if (!event) { return (<NotFoundPage />); }

  const defaultPressContact = globals.companyInfo && globals.companyInfo.pressContact ? globals.companyInfo.pressContact[0] : null;

  return (
    <EventPageComponent
      event={event}
      defaultPressContact={defaultPressContact}
    />
  );
}

export default graphql(query, {
  options: ({ match: { params } }) => ({
    variables: { slug: params.slug },
  }),
})(EventPage);
