import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';

import OutboundLink from '../OutboundLink';

const ApplyForThisPosition = ({ url }) => {
  if (isEmpty(url)) { return null; }

  return(
    <OutboundLink
      className="ApplyForThisPosition Button small primary text-uppercase"
      eventLabel={url}
      to={url}
    >
      Apply
    </OutboundLink>
  );
};

ApplyForThisPosition.propTypes = {
  url: PropTypes.string.isRequired,
}

export default ApplyForThisPosition;
