import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { OutboundLink } from 'react-ga';

import TopicLabel from '../TopicLabel';
import urlIsExternal from '../../utils/urlIsExternal';
import './SideNav.css';

const SideNav = ({ className, items, title }) => (
  <div className={classNames('SideNav mb-4', className)}>
    <h3>{title}</h3>
    {items.map((item, i) => {
      return (
        <div className="item" key={item.id}>
          {item.label && (
            <TopicLabel name={item.label} prefix={item.labelPrefix} />
          )}
          <h5 className="mb-3">
            {urlIsExternal(item.uri) ? (
              <OutboundLink eventLabel={item.uri} to={item.uri}>
                {item.title}
              </OutboundLink>
            ) : (
              <Link to={`/${item.uri}`}>{item.title}</Link>
            )}
          </h5>
        </div>
      );
    })}
  </div>
);

SideNav.propTypes = {
  /**
   * A title for the Side Nav
   */
  title: PropTypes.string.isRequired,
  /**
   * An array of items.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
      labelPrefix: PropTypes.string,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  ),
};

export default SideNav;
