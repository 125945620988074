import sanitizeHtml from 'sanitize-html';

export default (html) => ({
  __html: sanitizeHtml(html, {
    allowedAttributes: {
      a: ['href', 'target', 'data-flickr-embed'],
      div: ['data-js-card-stack', 'style'],
      h2: ['id'],
      iframe: ['frameborder', 'height', 'scrolling', 'src', 'width'],
      img: ['alt', 'src', 'class'],
      table: ['cellspacing', 'cellpadding'],
      td: ['valign'],
    },
    allowedClasses: {
      blockquote: ['twitter-tweet'],
      div: [
        'article-image-banner',
        'article-image-banner-with-caption',
        'content',
        'entry-image',
        'entry-images',
        'entry-image-offset',
        'entry-image-left',
        'entry-image-right',
        'full-width',
        'full-width-image',
        'Highlight',
        'image-captions',
        'sketch',
        'w-100',
      ],
      iframe: ['no-offset'],
      img: ['entry-image-left', 'tableau-fallback-image'],
      h3: ['table-heading'],
      h4: ['table-heading'],
      p: ['answer', 'caption', 'footnote', 'pullquote', 'question'],
      span: ['entry-image-caption', 'entry-image-left-caption'],
    },
    allowedTags: [
      'a',
      'blockquote',
      'br',
      'caption',
      'div',
      'em',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'h7',
      'h8',
      'iframe',
      'img',
      'li',
      'ol',
      'p',
      'span',
      'strong',
      'sub',
      'sup',
      'table',
      'tbody',
      'td',
      'th',
      'thead',
      'tfoot',
      'tr',
      'ul',
    ],
  }),
});
