import React from 'react';
import { graphql } from 'react-apollo';

import RelatedContent from '../../components/RelatedContent';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

const AboutPagePress = ({ data }) => {
  const { error, loading, entry } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!entry || !entry.inTheNews) { return null; }

  const items = itemsFromEntries(entry.inTheNews);

  return (
    <RelatedContent
      items={items}
      heading={{
        title: 'Press'
      }}
      url="/press/media"
    />
  );
};

export default graphql(query)(AboutPagePress);
