import React from 'react';
import { graphql } from 'react-apollo';
import get from 'lodash-es/get';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import ImpactPageComponent from '../../components/ImpactPage';

import query from './query';

const ImpactPage = ({ data, match }) => {
  const { error, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }

  return (
    <ImpactPageComponent
      match={match}
      entries={get(data, 'entries', [])}
    />
  );
};

ImpactPage.propTypes = {
  data: dataShape,
};

export default graphql(query)(ImpactPage);
