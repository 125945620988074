import React from 'react';
import { graphql } from 'react-apollo';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import MapPageRelatedMaterialsComponent from '../../components/MapPage/MapPageRelatedMaterials';

import query from './query';
import { itemsFromEntries } from '../../utils/itemFromEntry';

const MapPageRelatedMaterials = ({ data, content, defaultItems, selectedEnergyTechnologyEntry }) => {
  const { error, loading, entries } = data;

  if (loading) { return <LoadingSpinner />; }
  if (error) { return <Error error={error} />; }

  const filteredItems = itemsFromEntries(entries);

  return (
      <MapPageRelatedMaterialsComponent
        content={content}
        defaultItems={defaultItems}
        filteredItems={filteredItems}
        selectedEnergyTechnologyEntry={selectedEnergyTechnologyEntry}
      />
  );
};

MapPageRelatedMaterials.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ selectedEnergyTechnologyEntry }) => {
      const variables = {
          relatedElement: selectedEnergyTechnologyEntry ? [{ targetElement: selectedEnergyTechnologyEntry.id }] : [{ targetElement: 0 }],
      }

      return ({variables});
  }
})(MapPageRelatedMaterials)

