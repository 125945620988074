import PropTypes from 'prop-types';

import authorShape from './authorShape';
import imageShape from './imageShape';
import productTypeShape from './productTypeShape';

const productShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  postDate: PropTypes.number,
  productAuthors: PropTypes.arrayOf(authorShape),
  productFeaturedImage: PropTypes.arrayOf(imageShape),
  productFeaturedImageUrl: PropTypes.string,
  productType: PropTypes.arrayOf(productTypeShape),
  title: PropTypes.string,
});

export default productShape;
