import React from 'react';
import classNames from 'classnames';

import './LoadingSpinner.css';

const LoadingSpinner = ({ fullPage = true }) => (

  <div className={classNames('LoadingSpinner', { fullPage })}>
    <div className="fountainGParent">
      <div className="fountainG_1 fountainG"></div>
      <div className="fountainG_2 fountainG"></div>
      <div className="fountainG_3 fountainG"></div>
      <div className="fountainG_4 fountainG"></div>
      <div className="fountainG_5 fountainG"></div>
      <div className="fountainG_6 fountainG"></div>
      <div className="fountainG_7 fountainG"></div>
      <div className="fountainG_8 fountainG"></div>
    </div>
  </div>
);

export default LoadingSpinner;
