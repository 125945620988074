import React from 'react';
import Imgix from 'react-imgix';
import { Row, Col } from 'react-flexbox-grid';

import './ProductPageFeatureImage.css';

const ProductPageFeatureImage = ({ imageTitle, imageUrl }) => {
  if (!imageUrl || !imageTitle) {
    return null;
  }

  const imageSources = [
    {
      height: 640,
      width: 960,
      minWidth: 1200,
    },
    {
      height: 715,
      width: 1080,
      minWidth: 770,
    },
    {
      height: 513,
      width: 770,
      minWidth: 500,
    },
  ];

  return (
    <div className="ProductPageFeatureImage">
      <Row>
        <Col xs={12}>
          <div className="mb-3 outset inset-xl">
            <Imgix aggressiveLoad src={imageUrl} type="picture">
              {imageSources.map((source, i) => (
                <Imgix
                  aggressiveLoad
                  height={source.height}
                  imgProps={{
                    alt: imageTitle,
                    media: `(min-width: ${source.minWidth}px)`,
                  }}
                  key={i}
                  crop="center"
                  src={imageUrl}
                  type="source"
                  width={source.width}
                />
              ))}

              <Imgix
                aggressiveLoad
                height={333}
                imgProps={{
                  alt: imageTitle,
                }}
                crop="center"
                src={imageUrl}
                type="img"
                width={500}
              />
            </Imgix>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductPageFeatureImage;
