import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tabs.css';
import { Desktop, Mobile } from '../Devices';
import Tab from './Tab';

class Tabs extends Component {
  static defaultProps = {
    selectedIndex: 0,
    onChange: () => {},
  };

  static propTypes = {
    selectedIndex: PropTypes.number,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: this.props.selectedIndex,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange(event) {
    const { onChange } = this.props;
    const { selectedIndex } = this.state;
    const index = Number(event.target.value);

    this.setState({
      selectedIndex: (index === selectedIndex) ? this.props.selectedIndex : index,
    }, () => {
      onChange({selectedIndex: index});
    });
  }

  handleSelect(index) {
    const { onChange } = this.props;
    const { selectedIndex } = this.state;

    this.setState({
      selectedIndex: (index === selectedIndex) ? this.props.selectedIndex : index,
    }, () => {
      onChange({selectedIndex: index});
    });
  }

  renderActiveContent() {
    const { children } = this.props;
    const { selectedIndex } = this.state;
    if (children[selectedIndex]) {
      return children[selectedIndex].props.children;
    }
  }

  renderTabs() {
    return React.Children.map(this.props.children, (child, index) => {
        return React.cloneElement(child, {
            onSelect : this.handleSelect,
            tabIndex: index,
            active: index === this.state.selectedIndex,
        });
    });
  }

  renderTabAsOptions() {
    return React.Children.map(this.props.children, (child, index) => {
        return (
          <option value={index}>
            {child.props.title}
          </option>
        );
    });
  }

  render() {
    const { selectedIndex } = this.state;

    return (
      <div className={classNames('Tabs', this.props.className)}>
        <Desktop>
          <div className="controls">
            {this.renderTabs()}
          </div>
        </Desktop>
        <Mobile>
          <select className="form-select mb-3" value={selectedIndex} onChange={this.handleChange}>
            {this.renderTabAsOptions()}
          </select>
        </Mobile>
        <div className="content">
          {this.renderActiveContent()}
        </div>
      </div>
    );
  }
}

export { Tab, Tabs };
