import React from 'react';
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import { Row, Col } from 'react-flexbox-grid';

import './TwitterFeed.css';

const TwitterFeed = ({ data = [] }) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <Row className="TwitterFeed">
      {data.map((tweet, i) => (
        <Col xs={12} md={4} lg={3} key={i}>
          <TwitterTweetEmbed tweetId={tweet.id_str} key={tweet.id} />
        </Col>
      ))}
    </Row>
  );
};

TwitterFeed.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TwitterFeed;
