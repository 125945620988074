import React from 'react';
import Imgix from 'react-imgix';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import formatDate from '../../utils/formatDate';
import itemShape from '../../shapes/itemShape';
import OutboundLink from '../OutboundLink';
import TopicLabel from '../TopicLabel';
import urlIsExternal from '../../utils/urlIsExternal';

import './EntryCard.css';

const EntryCard = ({ item, large, reduceCardGutters=false }) => {
  if (!item) { return null; }

  const imgixDefaults = {
    aggressiveLoad: true,
    className: 'responsive',
    crop: 'center',
    src: item.imageUrl,
  };

  const imageSources = [
    {
      height: 280,
      width: 420,
      minWidth: 992,
    },
    {
      height: 500,
      width: 980,
      minWidth: 500,
    },
  ];

  const EntryCardImage = (
    <Imgix
      src={item.imageUrl}
      type="picture"
    >
      {imageSources.map((source, i) => (
        <Imgix
          height={source.height}
          imgProps={{
            alt: item.imageTitle,
            media: `(min-width: ${source.minWidth}px)`
          }}
          key={i}
          type="source"
          width={source.width}
          {...imgixDefaults}
        />
      ))}

      <Imgix
        height={280}
        imgProps={{
          alt: item.imageTitle,
        }}
        type="img"
        width={420}
        {...imgixDefaults}
      />
    </Imgix>
  );

  const EntryCardPublicationLogo = item.itemPublicationLogo ? (
    <div className="publication-logo">
      <Imgix
        aggressiveLoad
        className="responsive w-100"
        crop="center"
        height={96}
        imgProps={{
          alt: item.itemPublicationName || item.topicName
        }}
        src={item.itemPublicationLogo}
        width={96}
      />
    </div>
  ) : null;

  return (
    <div className={`EntryCard mb-4 ${ reduceCardGutters ? "px-0" : "px-2 px-lg-0" }`}>
      <div className="image mb-2">
        {urlIsExternal(item.itemUrl) ? (
          <OutboundLink eventLabel={item.itemUrl} target="_blank" to={item.itemUrl}>
            {EntryCardImage}
            {EntryCardPublicationLogo}
          </OutboundLink>
        ) : (
          <Link to={item.itemUrl}>
            {EntryCardImage}
            {EntryCardPublicationLogo}
          </Link>
        )}
      </div>

      <div>
        <TopicLabel
          className="mb-1"
          name={item.typename === 'InTheNews' ? `Via ${item.itemPublicationName}` : item.topicName}
          engagement={item.typename === 'Products' ? item.itemReadingTime : null}
        />

        {item.eventStartDate &&
          <span className="event-start-date text-right">
            <em>
              {formatDate(item.eventStartDate, 'MMM DD, YYYY')}
            </em>
          </span>
        }
      </div>

      {large ? (
        <h3>
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} target="_blank" to={item.itemUrl}>
              {item.itemTitle}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>
              {item.itemTitle}
            </Link>
          )}
        </h3>
      ) : (
        <h5>
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} target="_blank" to={item.itemUrl}>
              {item.itemTitle}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>
              {item.itemTitle}
            </Link>
          )}
        </h5>
      )}
    </div>
  );
};

EntryCard.propTypes = {
  item: itemShape,
  large: PropTypes.bool,
};

export default EntryCard;
