import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash-es/get';

import AppHelmet from '../AppHelmet';
import ApplyForThisPosition from '../ApplyForThisPosition';
import Content from '../Content';
import SocialSharing from '../SocialSharing';

const Fellowship = ({ fellowship }) => (
  <div className="Fellowship">
    <AppHelmet
      title={
        fellowship.title
          ? `Fellows Program - ${fellowship.title}`
          : 'Fellows Program'
      }
    />

    <div>
      <Row>
        <Col xs={12}>
          <h1 className="mb-4">{fellowship.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={fellowship.positionUrl} />
            <SocialSharing
              trackClicks={true}
              title={fellowship.title}
              postDate={fellowship.postDate}
              productType="Fellowship"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Content content={get(fellowship, 'body.content', 'No content.')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h2 className="mb-4">Interested?</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={fellowship.positionUrl} />
            <SocialSharing />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Fellowship;
