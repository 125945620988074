import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query PressReleasesAndMediaQuery {
    contactInfo: globals {
      companyInfo {
        globalPhone
        globalFax
        globalEmail
      }
    }
    pressInfo: globals {
      companyInfo {
        pressContact {
          id
          title

          ...on Staff {
            ...Staff
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
