import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import './AppLogo.css';

const AppLogo = ({ footer, inverse }) => (
  <div className="AppLogo">
    <NavLink to="/">
      <div
        className={classNames('logo', {
          footer: footer,
          inverse: inverse,
        })}
      >
        Third Way
      </div>
    </NavLink>
  </div>
);

export default AppLogo;
