import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query IssuePage($slug: String) {
    categoriesConnection(groupId: 3, level: 1, slug: $slug) {
      edges {
        node {
          id
          slug
          title
          ... on IssuesCategory {
            issueIcon
            featuredContent {
              ... on FeaturedContentEntry {
                ...featuredContent
              }
              ... on FeaturedContentExternalContent {
                ...featuredExternal
              }
            }
            body {
              content
              totalPages
            }
            issueTeam {
              ... on Team {
                team {
                  ... on Staff {
                    ...Staff
                  }
                }
              }
            }
            issueSocialMediaAccount {
              ...socialMedia
            }
          }
        }
      }
    }

    subscriptionForms: entries(section: subscriptionForms) {
      ... on SubscriptionForms {
        subscriptionFormUrl
        subscriptionFormIssues {
          id
          slug
        }
      }
    }
  }

  fragment featuredExternal on FeaturedContentExternalContent {
    description

    issue {
      id
      slug
      title
    }

    image {
      id
      title
      url
    }

    externalUrl
    externalName
    externalType
  }

  fragment featuredContent on FeaturedContentEntry {
    entry {
      __typename
      id
      postDate
      slug
      title
      uri

      ... on Events {
        eventStartDate
        eventIssue {
          id
          slug
          title
        }
        eventPhotos {
          id
          url
          title
        }
      }
      ... on InTheNews {
        postDate
        publicationName
        publicationLogo {
          url
          id
        }
        newsItemUrl
        newsItemFeaturedImage {
          id
          url
          title
        }
        issues {
          id
          slug
          title
        }
      }
      ... on Products {
        ...product
      }
    }
  }

  fragment product on Products {
    productBlurb
    productReadingTime
    productAuthors {
      __typename
      id
      slug
      title

      ... on Staff {
        ...Staff
      }

      ... on ExternalAuthors {
        twitterHandle
        position
        photo {
          id
          url
        }
      }
    }
    productFeaturedImage {
      id
      url
      title
    }
    productFeaturedImageUrl
    productIssue {
      id
      slug
      title
    }
    productType {
      id
      slug
      title
    }
  }

  fragment socialMedia on IssueSocialMediaAccountUnion {
    ... on IssueSocialMediaAccountTwitterAccount {
      twitterHandle
    }
    ... on IssueSocialMediaAccountInstagramAccount {
      instagramName
    }
  }

  ${StaffFragment}
`;
