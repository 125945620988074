import React, { Component } from 'react';
import AdobePdfSdk from '../../utils/pdfSdk';
import './PdfDeck.css';
import uuidV1 from 'uuid/v1';
import scriptLoader from 'react-async-script-loader'

class PdfDeck extends Component {
    constructor(props) {
        super(props);

        this.pdfUrl = props.url;
        this.pdfTitle = props.title;
        this.id = uuidV1();
    }

    componentDidMount() {
        const viewSDKClient = new AdobePdfSdk();

        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            viewSDKClient.previewFile(`pdf-deck-wrapper-${this.id}`, this.pdfUrl, this.pdfTitle, {
                /* Pass the embed mode option here */
                embedMode: "SIZED_CONTAINER"
            });
        });
    }

    render() {
        return (
            <div id={`pdf-deck-wrapper-${this.id}`} className="pdf-deck-wrapper my-5"></div>
        )
    }
}

export default scriptLoader("https://acrobatservices.adobe.com/view-sdk/viewer.js")(PdfDeck);
