import React from 'react';
import { ReactSVG } from 'react-svg';
import find from 'lodash-es/find';
import AdvancedNuclear from './icons/Advanced-Nuclear.svg';
import AdvancedNuclearMarker from './icons/Advanced-Nuclear-Marker';
import CarbonCapture from './icons/Carbon-Capture-Utilization-and-Storage.svg';
import CarbonCaptureMarker from './icons/Carbon-Capture-Utilization-and-Storage-Marker';
import CleanSteel from './icons/Clean-Steel.svg';
import CleanSteelMarker from './icons/Clean-Steel-Marker';
import DirectAirCapture from './icons/Direct-Air-Capture.svg';
import DirectAirCaptureMarker from './icons/Direct-Air-Capture-Marker';
import ElectricVehicles from './icons/Electric-Vehicles.svg';
import ElectricVehiclesMarker from './icons/Electric-Vehicles-Marker';
import Geothermal from './icons/Geothermal.svg';
import GeothermalMarker from './icons/Geothermal-Marker';
import LongDurationEnergyStorage from './icons/Long-Duration-Energy-Storage.svg';
import LongDurationEnergyStorageMarker from './icons/Long-Duration-Energy-Storage-Marker';
import LowCarbonHydrogen from './icons/Low-carbon-hydrogen.svg';
import LowCarbonHydrogenMarker from './icons/Low-Carbon-Hydrogen-Marker';
import OffshoreWind from './icons/Offshore-wind.svg';
import OffshoreWindMarker from './icons/Offshore-Wind-Marker';
import Semiconductors from './icons/Semiconductors.svg';
import SemiconductorsMarker from './icons/Semiconductor-Marker';
import Solar from './icons/Solar.svg';
import SolarMaker from './icons/Solar-Marker';
import './TechnologyIcon.css';

/**
 * The technologyId value contains the ID of the project type returned from the getProjects() endpoint
 */
export const technologyStyles = {
  'advanced-nuclear': {
    'title': 'Advanced Nuclear',
    'technologyId': 2,
    'icon': AdvancedNuclear,
    'marker': AdvancedNuclearMarker(),
    'color': '#CE2B7C',
    'hoverColor': '#B9206C',
    'activeColor': '#2B2B2B',
    'label': 'Advanced Nuclear'
  },
  'carbon-capture-utilization-and-storage-ccus': {
    'title': 'Carbon Capture Utilization and Storage (CCUS)',
    'technologyId': 14,
    'icon': CarbonCapture,
    'marker': CarbonCaptureMarker(),
    'color': '#501E89',
    'hoverColor': '#44127E',
    'activeColor': '#2B2B2B',
    'label': 'Carbon Capture Utilization and Storage (CCUS)'
  },
  'clean-steel': {
    'title': 'Clean Steel',
    'technologyId': 24,
    'icon': CleanSteel,
    'marker': CleanSteelMarker(),
    'color': '#9D5400',
    'hoverColor': '#814500',
    'activeColor': '#2B2B2B',
    'label': 'Clean Steel'
  },
  'direct-air-capture-dac': {
    'title': 'Direct Air Capture (DAC)',
    'technologyId': 15,
    'icon': DirectAirCapture,
    'marker': DirectAirCaptureMarker(),
    'color': '#CF4900',
    'hoverColor': '#B54000',
    'activeColor': '#2B2B2B',
    'label': 'Direct Air Capture (DAC)'
  },
  'electric-vehicles': {
    'title': 'Electric Vehicles',
    'technologyId': 6,
    'icon': ElectricVehicles,
    'marker': ElectricVehiclesMarker(),
    'color': '#1578A2',
    'hoverColor': '#0A6B94',
    'activeColor': '#2B2B2B',
    'label': 'Electric Vehicles'
  },
  'geothermal': {
    'title': 'Geothermal',
    'technologyId': 5,
    'icon': Geothermal,
    'marker': GeothermalMarker(),
    'color': '#5B7B75',
    'hoverColor': '#486B64',
    'activeColor': '#2B2B2B',
    'label': 'Geothermal'
  },
  'long-duration-energy-storage-ldes': {
    'title': 'Long-Duration Energy Storage (LDES)',
    'technologyId': 11,
    'icon': LongDurationEnergyStorage,
    'marker': LongDurationEnergyStorageMarker(),
    'color': '#348037',
    'hoverColor': '#236925',
    'activeColor': '#2B2B2B',
    'label': 'Long-Duration Energy Storage (LDES)'
  },
  'low-carbon-hydrogen': {
    'title': 'Low-Carbon Hydrogen',
    'technologyId': 4,
    'icon': LowCarbonHydrogen,
    'marker': LowCarbonHydrogenMarker(),
    'color': '#721B37',
    'hoverColor': '#721B37',
    'activeColor': '#2B2B2B',
    'label': 'Low-Carbon Hydrogen'
  },
  'offshore-wind': {
    'title': 'Offshore Wind',
    'technologyId': 1,
    'icon': OffshoreWind,
    'marker': OffshoreWindMarker(),
    'color': '#335A89',
    'hoverColor': '#20436D',
    'activeColor': '#2B2B2B',
    'label': 'Offshore Wind'
  },
  'semiconductors': {
    'title': 'Semiconductors',
    'technologyId': 10,
    'icon': Semiconductors,
    'marker': SemiconductorsMarker(),
    'color': '#AD3A33',
    'hoverColor': '#9B2922',
    'activeColor': '#2B2B2B',
    'label': 'Semiconductors'
  },
  'solar': {
    'title': 'Solar',
    'technologyId': 23,
    'icon': Solar,
    'marker': SolarMaker(),
    'color': '#7B7423',
    'hoverColor': '#676115',
    'activeColor': '#2B2B2B',
    'label': 'Solar'
  }
}

export default function TechnologyIcon ({slug}) {
  const icon = technologyStyles[slug];

  if (icon) {
    return (
      <div className="technology-icon">
          <ReactSVG
            src={icon.icon}
            style={{'--fill-color': icon.color}}
          />
      </div>
    )
  }

  return '';
}

export const getMapIconElement = (technology, isSnapshot = false) => {
  const icon = find(technologyStyles, (technologyIconStyle) => technologyIconStyle.title === technology)

  if (!icon) {
    return false;
  }

  const parser = new DOMParser();
  const pinSvg = parser.parseFromString(
    icon.marker,
    "image/svg+xml",
  ).documentElement;

  pinSvg.setAttribute('class', `technology-map-marker ${(isSnapshot) ? 'snapshot':''}`);
  pinSvg.setAttribute('style', `--fill-color: ${icon.color}; --hover-color: ${icon.hoverColor}; --active-color: ${icon.activeColor};`);

  return pinSvg;

  // return fetch(icon.marker)
  //   .then(file => file.text())
  //   .then(contents => {
  //     const pinSvg = parser.parseFromString(
  //       contents,
  //       "image/svg+xml",
  //     ).documentElement;

  //     pinSvg.setAttribute('class', `technology-map-marker ${(isSnapshot) ? 'snapshot':''}`);
  //     pinSvg.setAttribute('style', `--fill-color: ${icon.color}; --hover-color: ${icon.hoverColor}; --active-color: ${icon.activeColor};`);

  //     return pinSvg;
  //   });
}
