import gql from 'graphql-tag';

import { RelatedProductsSeries } from '../../fragments/RelatedProducts';

const SeriesSideNavQuery = gql`
  query SeriesSideNavQuery($id: [Int]) {
    series: entries(type: Series, relatedTo: [{ targetElement: $id }]) {
      ... on Series {
        title
        uri
        seriesFeaturedImage {
          id
          url
          title
        }
        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
`;

export default SeriesSideNavQuery;
