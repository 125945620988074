import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Highlight from '../Highlight';
import './JoinOurTeam.css';

const JoinOurTeam = ({ className }) => (
  <Highlight className={classNames('JoinOurTeam mb-5', className)}>
    <div className="content">
      <div className="title">
        <h2>Join our Team</h2>
      </div>

      <div className="buttons">
        <Link to="/careers" className="Button block secondary large undecorated text-uppercase careers">
          Careers
        </Link>

        <Link to="/internships" className="Button block large undecorated text-uppercase internships">
          Internships
        </Link>

        <Link to="/about/fellows-program" className="Button block large dark undecorated text-uppercase fellows-program text-uppercase">
          Fellows Programs
        </Link>
      </div>
    </div>
  </Highlight>
);

export default JoinOurTeam;
