import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';
import { RelatedProductsSeries, RelatedProductsSeriesGroup } from '../../fragments/RelatedProducts';

// search should be siteFeed:["mainSiteOnly",""] but craftQL doesn't allow that
// Instead it will search on what it shouldn't be which is micrositeOnly
const SeriesPageQuery = gql`
  query SeriesPageQuery($slug: String) {
    entry(type: Series, slug: $slug, search:"-siteFeed:micrositeOnly") {
      id
      slug
      title
      uri

      ... on Series {
        body {
          totalPages
          content
        }

        seriesFeaturedImage {
          id
          url
          title
        }

        issues {
          id
          slug
          issueIcon
          title
        }

        seriesGroups {
          title

          ... on SeriesGroupsGroup {
            description {
              content
            }

              ...RelatedProductsSeriesGroup
          }
        }

        authors {
          __typename
          id
          slug
          title

          ... on Staff {
            ...Staff
          }

          ... on ExternalAuthors {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }

        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
  ${StaffFragment}
  ${RelatedProductsSeriesGroup}
`;

export default SeriesPageQuery;
