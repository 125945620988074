import PropTypes from 'prop-types';

const dataShape = PropTypes.shape({
  error: PropTypes.object,
  fetchMore: PropTypes.func,
  loading: PropTypes.bool,
  networkStatus: PropTypes.number,
  refetch: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  subscribeToMore: PropTypes.func,
  updateQuery: PropTypes.func,
  variables: PropTypes.object,
});

export default dataShape;
