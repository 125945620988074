import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { NavLink, Route } from 'react-router-dom';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Divider from '../Divider';
import LegalPageTermsOfService from '../../containers/LegalPageTermsOfService';
import LegalPagePrivacyPolicy from '../../containers/LegalPagePrivacyPolicy';

import './LegalPage.css';

const LegalPage = ({ location }) => (
  <div className="LegalPage">
    <AppFixedHeader />

    <Route exact path ="/privacy-policy" component={() => (
      <AppHelmet title="Privacy Policy" />
    )} />

    <Route exact path ="/terms-of-service" component={() => (
      <AppHelmet title="Terms of Service" />
    )} />

    <Grid fluid>
      <Row>
        <Col xs={12}>
          <h1 className="title mb-4">Legal</h1>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="mb-5 mt-2" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <nav className="legal-nav mb-4">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-of-service">Terms of Service</NavLink>
          </nav>
        </Col>

        <Col xs={12} md={8}>
          <Row>
            <Col xs={12}>
              <Route
                exact
                path="/privacy-policy"
                component={() => <h1 className="mb-4">Privacy Policy</h1>}
              />

              <Route
                exact
                path="/terms-of-service"
                component={() => <h1 className="mb-4">Terms of Service</h1>}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Route
                exact
                path="/privacy-policy"
                component={LegalPagePrivacyPolicy}
              />
              <Route
                exact
                path="/terms-of-service"
                component={LegalPageTermsOfService}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default LegalPage;
