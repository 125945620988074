import gql from 'graphql-tag';

const relatedFields = `
  id
  slug
  __typename

  ...on Events {
    title
    uri
    eventStartDate
    issues: eventIssue {
      id
      slug
      title
    }
    eventPhotos {
      id
      url
      title
    }
  }

  ...on Products {
    title
    uri
    postDate
    productFeaturedImageUrl
    productPublicationUpdateDate
    productReadingTime
    issues: productIssue {
      slug
      title
      id
    }
    productFeaturedImage {
      id
      title
      url
    }
    productType {
      slug
      id
      title
    }

    productAuthors {
      ...authors
    }
    
    energyTechnology {
      title
      id
    }
  }

  ...on PressReleases {
    title
    uri
    postDate
    issues {
      title
      id
    }
    
    energyTechnology {
      title
      id
    }
  }

  ...on ExternalContentDefault {
    externalName: title
    externalUrl: newsItemUrl
    externalType: publicationName
    image: newsItemFeaturedImage {
      url
      id
    }

    energyTechnology {
      title
      id
    }
  }
  
  ...on InTheNews {
    title
    uri: newsItemUrl

    publicationName
    issues {
      title
      id
    }
    newsItemFeaturedImage {
      url
      id
    }
    publicationLogo {
      url
      id
    }
    energyTechnology {
      title
      id
    }
  }
`;

const fragmentFields = `
  fragment authors on EntryInterface {
    id
    title
    uri

    ...staff
    ...external
  }

  fragment staff on Staff {
    position
    photo {
      url
      id
    }
  }

  fragment external on ExternalAuthors {
    id
    position
    photo {
      url
      id
    }
  }
`;

export const RelatedProductsProducts = gql`
  fragment RelatedProductsProducts on Products {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedMapProducts = gql`
  fragment RelatedMapProducts on Map {
    relatedContent {
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedProductsSeries = gql`
  fragment RelatedProductsSeries on Series {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedProductsSeriesGroup = gql`
fragment RelatedProductsSeriesGroup on SeriesGroupsGroup {
  relatedProducts{
    ${relatedFields}
  }
}

${fragmentFields}
`;

export const RelatedProductsPressReleases = gql`
  fragment RelatedProductsPressReleases on PressReleases {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export default {
  RelatedProductsSeries,
  RelatedProductsProducts,
  RelatedProductsPressReleases,
};
