import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query EventsPageQuery {
    event: entry(
      orderBy: "eventStartDate desc",
      public: true,
      type: Events,
    ) {
      id
      slug
      title

      ...on Events {
        eventBriteId
        eventEndDate
        eventStartDate
        eventLocationAddress
        eventLocationCity
        eventLocationState
        eventLocationName
        eventLocationZip

        eventContact {
          __typename
          id
          title

          ...on Staff {
            ...Staff
          }

          ...on ExternalAuthors {
            email
            position
          }
        }

        eventPhotos {
          url
          id
        }

        eventIssue {
          id
          title
        }
      }
    }
  }

  ${StaffFragment}
`;
