import React from 'react';
import classNames from 'classnames';

const SupportContact = ({ className }) => (
  <div className={classNames('SupportContact', className)}>
    <p>
      <strong>Emily Sternfeld</strong>
      <br />
      <em>Vice President of Development and External Affairs</em>
      <br />
      (202) 384 1700
      <br />
      <a href="mailto:esternfeld@thirdway.org">esternfeld@thirdway.org</a>
      <br />
    </p>
  </div>
);

export default SupportContact;
