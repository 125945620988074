import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Imgix from 'react-imgix';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import get from 'lodash-es/get';

import authorShape from '../../shapes/authorShape';
import fallbackImage from '../../utils/fallbackImage';
import OutboundLink from '../OutboundLink';

import './Author.css';

const Author = (props) => {
  const {
    author,
    className = '',
    issues = [],
    medium = false,
    small = false,
    showImage = true,
  } = props;

  if (!author) {
    return null;
  }

  const { photo, position, previousStaffMember, title, twitterHandle } = author;

  const imageUrl = get(
    photo,
    '[0].url',
    fallbackImage(
      issues.map((issue) => issue.slug),
      'author-',
    ),
  );

  const imgixDefaults = {
    aggressiveLoad: true,
    customParams: { facepad: 2 },
    fit: 'facearea',
    imgProps: { alt: `Photo of ${title ? title : 'person'}` },
    src: imageUrl,
  };

  // Determine whether a bio page should be served
  // Previous team members, and pseudo groups are excluded
  const displayBio =
    author.__typename !== 'ExternalAuthors' &&
    author.uri &&
    !previousStaffMember &&
    !/Team\b|Program\b|Way\b/.test(author.title);

  const authorImage = () => (
    <div className="image">
      {small || medium ? (
        <Imgix height={60} width={60} {...imgixDefaults} />
      ) : (
        <Imgix height={162} width={162} {...imgixDefaults} />
      )}
    </div>
  );

  return (
    <div className={classNames('Author', { medium, small }, className)}>
      {showImage && (
        <div className="image-wrapper">
          {displayBio ? (
            <Link to={`/${author.uri}`}>{authorImage()}</Link>
          ) : (
            authorImage()
          )}
        </div>
      )}

      {(title || position) && (
        <div className="meta mb-2">
          {displayBio ? (
            <div className="title">
              <Link to={`/${author.uri}`}>{title}</Link>
            </div>
          ) : (
            <div className="title">{title}</div>
          )}

          {position && <div className="position">{position}</div>}

          {twitterHandle && (
            <div className="twitter">
              <OutboundLink
                eventLabel={`https://www.twitter.com/${twitterHandle}`}
                to={`https://www.twitter.com/${twitterHandle}`}
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />@
                {twitterHandle}
              </OutboundLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Author.propTypes = {
  author: authorShape,
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default Author;
