import gql from 'graphql-tag';

export default gql`
  query EventPageRelatedContentQuery($slug: String) {
    event: entry(
      slug: $slug,
      type: Events,
    ) {
      id
      slug
      title

      ...on Events {
        eventRelatedContent {
          ...on EventRelatedContentExternalContent {
            __typename
            description

            issue {
              id
              title
            }

            image {
              id
              title
              url
            }

            externalUrl
            externalName
            externalType
          }

          ...on EventRelatedContentEntry {
            entry {
              __typename
              id
              postDate
              slug
              title

              ...on Events {
                eventStartDate

                eventIssue {
                  id
                  slug
                  title
                }

                eventPhotos {
                  id
                  url
                  title
                }
              }

              ...on Products {
                productBlurb
                productReadingTime

                productFeaturedImage {
                  id
                  url
                  title
                }

                productFeaturedImageUrl

                productIssue {
                  id
                  slug
                  title
                }

                productType {
                  id
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
