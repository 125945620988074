import React from 'react';

import SectionLabel from '../../SectionLabel';

const Notice = () => (
  <div>
    <SectionLabel name="Our Commitment" />
    <p className="mb-0">
      <em>
        We understand what it means to bring different voices together to work towards a common goal. Third Way celebrates, supports, and promotes diversity, equity, inclusion and belonging and encourages all qualified applicants to apply to join our team. We are committed to equal employment opportunity regardless of race, color, gender, sexual orientation, gender identity, lawful alien status, age, marital status, parental status, or protected veteran status and will not discriminate against anyone on the basis of a disability. All employment selections are determined on the basis of qualifications and program needs.
      </em>
    </p>
  </div>
);

export default Notice;
