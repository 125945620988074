import gql from 'graphql-tag';

export default gql`
  query SearchPageQuery(
    $limit: Int,
    $offset: Int,
    $relatedElement: [RelatedToInputType],
    $searchQuery: String,
    $section: [SectionsEnum],
  ) {
    entriesConnection(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc",
      public: true,
      relatedTo: $relatedElement,
      search: $searchQuery,
      section: $section,
    ) {
      totalCount
      results: entries {
        __typename
        id
        postDate
        slug
        title
        uri

        ...on Events {
          eventStartDate

          eventIssue {
            slug
            title
            id
          }

          eventPhotos {
            url
            id
          }
        }

        ...on Products {
          productReadingTime

          productIssue {
            slug
            title
            id
          }

          productFeaturedImage {
            url
            id
          }

          productFeaturedImageUrl

          productType {
            slug
            id
            title
          }

          productAuthors {
            ...authors
          }
        }

        ...on Series {
          seriesBlurb

          seriesFeaturedImage {
            url
            id
          }

          issues {
            slug
            title
            id
          }

          authors {
            ...authors
          }
        }

        ...on PressReleases {
          postDate

          issues {
            title
            id
          }
        }

        ...on InTheNews {
          publicationName
          newsItemUrl

          newsItemFeaturedImage {
            url
            id
          }

          publicationLogo {
            url
            id
          }

          issues {
            title
            id
          }
        }
      }
    }
  }

  fragment authors on EntryInterface {
    __typename
    id
    title
    uri

    ...staff
    ...external
  }

  fragment staff on Staff {
    position
    photo {
      url
      id
    }
  }

  fragment external on ExternalAuthors {
    id
    position
    photo {
      url
      id
    }
  }
`;
