import React, { useRef } from 'react';
import MediaQuery from 'react-responsive';
import rehype from 'rehype';
import sanitizeHtml from 'sanitize-html';
import slug from 'rehype-slug';
import { filter, get } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useOnLoadImages } from '../../hooks/useOnLoadImages';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Author from '../Author';
import Board from './Board';
import authorTitlesFromCollection from '../../utils/authorTitlesFromCollection';
import Content from '../Content';
import IssuesRelated from '../../containers/IssuesRelated';
import fallbackImage from '../../utils/fallbackImage';
import Highlight from '../../components/Highlight';
import JumpToSection from '../../containers/JumpToSection';
import ProductDownloads from '../ProductDownloads';
import ProductEndnotes from '../../containers/ProductEndnotes';
import ProductPageFeatureImage from '../ProductPageFeatureImage';
import ProductSideNav from '../ProductSideNav';
import SectionLabel from '../SectionLabel';
import SeriesSideNav from '../../containers/SeriesSideNav';
import SocialSharing from '../SocialSharing';
import SponsoredMessage from '../SponsoredMessage';
import SubscribeBox from '../../containers/SubscribeBox';
import VideoEmbed from '../VideoEmbed';

import './ProductPage.css';

const matchHeadings = /<h2.*<\/h2>/g;
const matchTitle = />(.*)?</;
const matchId = /(id="(.*?)")/;

const ProductPage = ({ product, related, subscriptionUrl }) => {
  if (!product) {
    return null;
  }

  const wrapperRef = useRef(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);
  const productType = get(product, 'productType[0]')
  ? product.productType[0].title
  : '';
  const postDate = product.postDate ? Number(product.postDate) : null;

  window.productDataLoaded = imagesLoaded;

  const imageUrl =
    product.productFeaturedImageUrl ||
    get(
      product,
      'productFeaturedImage[0].url',
      fallbackImage(product.productIssue.map((issue) => issue.slug)),
    );

  const videoUrl = product.featuredVideo
    ? get(product, 'featuredVideo[0]')
    : null;

  const imageTitle = get(
    product,
    'productFeaturedImage[0].title',
    product.title,
  );

  const downloads = Array.from(product.productDownloads);
  let productHtml;
  let productSections = [];
  let productPdf;

  if (product.productPdfOverrideFile[0]) {
    productPdf = {
      ...product.productPdfOverrideFile[0],
      title: product.title,
    };
  } else if (product.autogeneratedPdfUrl) {
    productPdf = {
      id: product.id,
      url: product.autogeneratedPdfUrl,
      mimeType: 'pdf/application',
      title: product.title,
    };
  } else if (product.productPdfStatus === '1') {
    productPdf = {
      id: product.id,
      url: `http://thirdway.imgix.net/pdfs/${product.slug}.pdf`,
      mimeType: 'pdf/application',
      title: product.title,
    };
  }

  if (productPdf) {
    downloads.unshift(productPdf);
  }

  // Create indexable content
  rehype()
    .use(slug)
    .process(product.productBody, (err, data) => {
      if (err) {
        productHtml = product.productBody;
      }

      productHtml = data.contents;

      // Retrieve headings/sections
      productSections = productHtml.match(matchHeadings);
      productSections = productSections
        ? productSections.map((section) => {
            const title = section.match(matchTitle).length
              ? sanitizeHtml(section.match(matchTitle)[1], {
                  allowedTags: [],
                  decodeEntities: false,
                })
              : null;
            const url = section.match(matchId).length
              ? section.match(matchId)[1].split('id="')[1].slice(0, -1)
              : null;

            return { title, url };
          })
        : [];
    });

  return (
    <div className="ProductPage" ref={wrapperRef}>
      <AppHelmet
        image={imageUrl}
        description={
          product.productBlurb
            ? sanitizeHtml(get(product, 'productBlurb'), { allowedTags: [] })
            : undefined
        }
        title={product.title}
      />

      <AppFixedHeader
        mainIssue={
          filter(product.productIssue, (issue) => issue.issueIcon)[0] || null
        }
        sections={productSections}
        sharing={{
          body: sanitizeHtml(get(product, 'productBlurb'), { allowedTags: [] }),
          title: product.title,
        }}
        title={product.title}
      />

      <Grid fluid>
        <Row>
          <Col xs={12} xl={9} className="col-xs-print-12">
            <Row>
              <Col xs={12}>
                <SectionLabel
                  className="py-2"
                  engagement={product.productReadingTime}
                  name={productType}
                  publishedAt={postDate}
                  updatedAt={
                    product.productPublicationUpdateDate
                      ? product.productPublicationUpdateDate
                      : null
                  }
                />

                <h1 className="mb-4">{product.title}</h1>

                {videoUrl && (
                  <VideoEmbed
                    aspectRatio="16by9"
                    className="mb-3"
                    url={
                      product.featuredVideo[0].youtubeId
                        ? `https://www.youtube.com/embed/${videoUrl.youtubeId}`
                        : videoUrl.facebookVideoUrl
                    }
                  />
                )}

                {imageUrl && !videoUrl && (
                  <div>
                    <ProductPageFeatureImage
                      imageTitle={imageTitle}
                      imageUrl={imageUrl}
                    />
                  </div>
                )}

                <SeriesSideNav id={product.id} bannerStyle={true} />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4} className="col-xs-print-12">
                <MediaQuery query="screen and (max-width: 500px)">
                  <span className="authors-short mb-4">
                    {authorTitlesFromCollection(product.productAuthors)}
                  </span>
                </MediaQuery>

                <MediaQuery query="print, screen and (min-width: 500px)">
                  {product.productAuthors && product.productAuthors.length > 0 && (
                    <React.Fragment>
                      <div className="authors-list mb-4">
                        <Row>
                          {product.productAuthors.map((author) => (
                            <Col
                              key={author.id}
                              xs={12}
                              sm={6}
                              lg={12}
                              className="col-xs-print-6"
                            >
                              <Author
                                author={author}
                                issues={product.itemIssues}
                                medium
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                      <ul className="print-authors">
                        {product.productAuthors.map((author, i) => (
                          <li className="author" key={i}>
                            <strong>{author.title}, </strong>
                            <span>{author.position}</span>
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  )}
                </MediaQuery>

                {productSections.length > 0 && (
                  <JumpToSection
                    className="hidden-print"
                    sections={productSections}
                  />
                )}

                <MediaQuery maxWidth={1199}>
                  {downloads && downloads.length > 0 && (
                    <ProductDownloads productDownloads={downloads} />
                  )}
                </MediaQuery>
              </Col>

              <Col xs={12} lg={8} className="col-xs-print-12">
                {product.productTakeaways && (
                  <Highlight>
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: `
                          <h2>Takeaways</h2>
                          ${product.productTakeaways}
                        `,
                      }}
                    />
                  </Highlight>
                )}

                <Content
                  content={productHtml}
                  description={
                    product.productBlurb
                      ? sanitizeHtml(get(product, 'productBlurb'), {
                          allowedTags: [],
                        })
                      : undefined
                  }
                  sanitizeContent={true}
                  title={product.title}
                />

                {product.productSponsor && product.productSponsor.content && (
                  <SponsoredMessage message={product.productSponsor.content} />
                )}

                <Board board={product.board} />

                {product.productIssue.length > 0 && (
                  <IssuesRelated
                    issueIDs={product.productIssue.map((issue) => issue.id)}
                  />
                )}

                {product.productEndnotes && (
                  <div className="mb-5">
                    <ProductEndnotes endnotes={product.productEndnotes} />
                  </div>
                )}

                {subscriptionUrl && (
                  <Row>
                    <Col xs={12}>
                      <SubscribeBox iframeUrl={subscriptionUrl} size={"small"} />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>

          <MediaQuery minWidth={1200}>
            <Col xs={12} xl={3} className="pl-5 mt-2 col-xs-print-12">
              <SocialSharing
                body={sanitizeHtml(get(product, 'productBlurb'), {
                  allowedTags: [],
                })}
                className="mb-4 pull-right"
                trackClicks={true}
                title={product.title}
                postDate={postDate}
                productType={productType}
                engagement={product.productReadingTime}
              />

              {downloads && downloads.length > 0 && (
                <ProductDownloads productDownloads={downloads} />
              )}

              {related && related.length > 0 && (
                <ProductSideNav items={related} />
              )}

              <SeriesSideNav id={product.id} />
            </Col>
          </MediaQuery>
        </Row>
      </Grid>
    </div>
  );
};

export default ProductPage;
