import React from 'react';
import Imgix from 'react-imgix';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import classNames from 'classnames';

import Author from '../../Author';
import Content from '../../Content';
import itemFromEntry from '../../../utils/itemFromEntry';
import OutboundLink from '../../OutboundLink';
import urlIsExternal from '../../../utils/urlIsExternal';
import TopicLabel from '../../TopicLabel';

import './IssuePageFeaturedItem.css';

const IssuePageFeaturedItem = ({
  featuredItem,
  location,
  orientation = 'horizontal',
  showAuthors = true,
  showBlurb = true,
  totalFeaturedItems,
}) => {
  if (!featuredItem) {
    return null;
  }

  const item = itemFromEntry(featuredItem);

  if (!item) {
    return null;
  }

  const FeaturedHomeEntryItemImage = (
    <Imgix
      aggressiveLoad
      className="responsive"
      crop="center"
      height={513}
      imgProps={{
        alt: item.imageTitle,
      }}
      src={item.imageUrl}
      width={770}
    />
  );

  const PublicationLogo = item.itemPublicationLogo ? (
    <div className="publication-logo">
      <Imgix
        aggressiveLoad
        className="responsive w-100"
        crop="center"
        height={96}
        imgProps={{
          alt: item.itemPublicationName || item.topicName,
        }}
        src={item.itemPublicationLogo}
        width={96}
      />
    </div>
  ) : null;

  return (
    <Row className="IssuePageFeaturedItem">
      <Col xs={12} md={orientation === 'horizontal' ? 6 : 12}>
        <div className="image mb-2">
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
              {FeaturedHomeEntryItemImage}
              {PublicationLogo}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>
              {FeaturedHomeEntryItemImage}
              {PublicationLogo}
            </Link>
          )}
        </div>
      </Col>

      <Col xs={12} md={orientation === 'horizontal' ? 6 : 12}>
        <TopicLabel
          className="mb-2"
          name={item.topicName}
          engagement={
            item.typename === 'Products' ? item.itemReadingTime : null
          }
          publicationName={item.itemPublicationName}
          publishedAt={item.itemPublicationDate}
        />

        <h2
          className={classNames('mb-3', { h3: orientation === 'horizontal' })}
        >
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
              {item.itemTitle}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>{item.itemTitle}</Link>
          )}
        </h2>

        {showBlurb && item.itemBlurb && (
          <Content className="feature-body mb-3" content={item.itemBlurb} />
        )}

        {item.itemAuthors && showAuthors && (
          <div className="authors-list mb-2">
            <Row>
              {item.itemAuthors.map((author, i) => (
                <Col key={i} xs={12}>
                  <Author author={author} className="mb-3" small />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default IssuePageFeaturedItem;
