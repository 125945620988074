import gql from 'graphql-tag';
import { RelatedProductsSeries, RelatedProductsSeriesGroup } from '../../fragments/RelatedProducts';

export default gql`
  query SeriesPageFiltersQuery($slug: String) {
    entry(type: Series, slug: $slug) {
      id

      ... on Series {
        seriesGroups {
          title

          ... on SeriesGroupsGroup {
            description {
              content
            }

            ...RelatedProductsSeriesGroup
          }
        }
        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
  ${RelatedProductsSeriesGroup}
`;
