import React, { Component } from 'react';

import ProductEndnotesComponent from '../../components/ProductEndnotes';

class ProductEndnotes extends Component {
  constructor() {
    super();

    this.toggleEndnotes = this.toggleEndnotes.bind(this);

    this.state = {
      endnotesOpen: false,
    };
  }

  toggleEndnotes() {
    this.setState({ endnotesOpen: !this.state.endnotesOpen });
  }

  render() {
    return (
      <ProductEndnotesComponent
        endnotes={this.props.endnotes}
        endnotesOpen={this.state.endnotesOpen}
        toggleEndnotes={this.toggleEndnotes}
      />
    );
  }
};

export default ProductEndnotes;
