import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader'
import classNames from 'classnames';


class RsvpModal extends Component {
  constructor() {
    super();

    this.state = {
      modalCreated: false,
    }
  }

  componentDidUpdate(isScriptLoaded, isScriptLoadSucceed) {
    // Once everything is loaded, create the modal
    if(isScriptLoaded && isScriptLoadSucceed && !this.state.modalCreated) {
      this.createModal()
    }
  }

  componentDidUnmount() {
    // Destroy modal
  }

  createModal() {
    window.EBWidgets.createWidget({
      widgetType: 'checkout',
      eventId: this.props.eventBriteId,
      modal: true,
      modalTriggerElementId: `eb-modal-${this.props.eventBriteId}`,
    });

    this.setState({modalCreated: true});
  }

  render() {
    // We hide the element, because it needs to be in the DOM
    // for the EB widget to attach the event to
    return(
      <div
        class={classNames({'hidden': !this.state.modalCreated})}
        id={`eb-modal-${this.props.eventBriteId}`}
      >
        {this.props.children}
      </div>
    );
  }
}

export default scriptLoader(
  'https://www.eventbrite.com/static/widgets/eb_widgets.js'
)(RsvpModal)
