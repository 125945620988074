import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import classNames from 'classnames';

import OutboundLink from '../OutboundLink';

import './SocialLink.css';

const SocialLink = ({ className, faLibrary = 'fab', icon, large = false, title, url }) => (
  <div className={classNames('SocialLink', { large }, className)}>
    <div className="brand-icon">
      {url ? (
        <OutboundLink
          eventLabel={url}
          rel="noopener noreferrer"
          target="_blank"
          to={url}
        >
          <FontAwesomeIcon icon={[faLibrary, icon]} color="white" />
        </OutboundLink>
      ) : (
        <FontAwesomeIcon icon={[faLibrary, icon]} color="white" />
      )}
    </div>

    {title &&
      <div className="brand-title">
        <OutboundLink
          eventLabel={url}
          rel="noopener noreferrer"
          target="_blank"
          to={url}
        >
          {title}
        </OutboundLink>
      </div>
    }
  </div>
);

export default SocialLink;
