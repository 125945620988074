import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../AppHelmet';
import CategoryList from '../../containers/CategoryList';

import './NotFoundPage.css';

const NotFoundPage = ({ categories = [], loading }) => (
  <div className="NotFoundPage">
    <AppHelmet title="404 Page Not Found" />

    <div className="pb-4 pt-5 not-found">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <h1>404</h1>
            <h2>Page not found</h2>
            <p>We&rsquo;re sorry... for some reason we couldn&rsquo;t find the page you&rsquo;re looking for.</p>
            <h3 className="mb-3">Browse our areas of focus</h3>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <CategoryList />
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
);

export default NotFoundPage;
