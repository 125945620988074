import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import has from 'lodash-es/has';
import get from 'lodash-es/get';
import Imgix from 'react-imgix';

import './SeriesBar.css';

const SeriesBar = ({ series, currentIndex }) => (
  <div className="SeriesBar mb-4">
    {has(series, 'seriesFeaturedImage[0].url') && (
      <div className="image">
        <Link to={`/${series.uri}`}>
          <Imgix
            aggressiveLoad
            className="w-100"
            crop="faces,center"
            imgProps={{ alt: get(series, 'seriesFeaturedImage[0].title', '') }}
            src={get(series, 'seriesFeaturedImage[0].url')}
          />
        </Link>
      </div>
    )}
    <div className="content">
      <div className="title">
        <Link to={`/${series.uri}`}>{series.title}</Link>
      </div>
    </div>
    <div className="link">
      <Link to={`/${series.uri}`} className="label text-uppercase">
        <FontAwesomeIcon
          icon={['fas', 'th-list']}
          className="icon-th-list mr-2"
        />
        View Series
      </Link>
    </div>
  </div>
);

SeriesBar.propTypes = {
  series: PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  currentIndex: PropTypes.number.isRequired,
};

export default SeriesBar;
