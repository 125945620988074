import React, { Component } from 'react';
import JumpToSectionComponent from '../../components/JumpToSection';

class JumpToSection extends Component {
  constructor() {
    super();

    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      menuOpen: false,
    }
  }

  toggleMenu(e) {
    e.preventDefault();
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  render() {
    const { className, header, sections } = this.props;

    return <JumpToSectionComponent
              className={className}
              header={header}
              menuOpen={this.state.menuOpen}
              sections={sections}
              toggleMenu={this.toggleMenu}
            />
  }
}

export default JumpToSection;
