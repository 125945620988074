import React from 'react';
import EntryCard from '../../EntryCard';
import Slider from 'react-slick';

import './TopicCarousel.css';

const carouselSettings = {
  infinite: false,
  centerMode: true,
  dots: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        centerPadding: '32px',
      },
    },
    {
      breakpoint: 100000,
      settings: 'unslick',
    },
  ],
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
};

const TopicCarousel = ({ items = [] }) => {
  if (!items) { return null; }

  return (
    <Slider {...carouselSettings} className="TopicCarousel">
      {items.map((item, i) => (
        <div key={i}>
          <EntryCard item={item} />
        </div>
      ))}
    </Slider>
  );
};

export default TopicCarousel;
