import React from 'react';
import Slider from 'react-slick';

import './TeamCarousel.css';
import Author from '../Author';

const settings = {
  infinite: false,
  arrows: true,
  centerMode: true,
  dots: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        centerPadding: '60px',
      },
    },
    {
      breakpoint: 100000,
      settings: 'unslick',
    },
  ],
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
};

// The Slider needs a +key+ attribute in order to re-mount
// when content changes. See:
//   https://github.com/akiran/react-slick/issues/27#issuecomment-326792340
const TeamCarousel = ({data, selectedTabIndex, staffType}) => (
  <div className="TeamCarousel outset inset-lg mb-3">
    <Slider {...settings} key={selectedTabIndex}>
      {data.map((item) => (
        <div key={item.id}>
          <Author
            author={item}
            className={`${staffType}`}
            showImage={staffType !== 'board-of-trustees'}
          />
        </div>
      ))}
    </Slider>
  </div>
);

export default TeamCarousel;
