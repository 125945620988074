import gql from 'graphql-tag';

export default gql`
  fragment Staff on Staff {
    email
    id
    phoneNumber
    position
    previousStaffMember
    preventProductDisplay
    slug
    title
    twitterHandle
    uri

    description {
      content
    }

    photo {
      filename
      id
      url
      title
    }
  }
`;
