import React, { Component } from 'react';
import { withRouter } from 'react-router';

class AppMenu extends Component {
  constructor() {
    super();

    this.toggleIssues = this.toggleIssues.bind(this);

    this.state = {
      issuesMenuOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.state.issuesMenuOpen) return false;

    if (this.props.location.pathname !== prevProps.location.pathname ||
        this.props.location.search !== prevProps.location.search) {
      this.setState({ issuesMenuOpen: false });
    }
  }

  toggleIssues(e) {
    e.preventDefault();
    this.setState({
      issuesMenuOpen: !this.state.issuesMenuOpen
    })
  }

  render() {
    return React.cloneElement(this.props.children, {
      toggleIssues: this.toggleIssues,
      issuesMenuOpen: this.state.issuesMenuOpen
    });
  }
}

export default withRouter(AppMenu);
