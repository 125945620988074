import React from 'react';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Route } from 'react-router-dom';

import AppFixedHeader from '../../containers/AppFixedHeader';
import CareersContent from './CareersContent';
import CultureContent from './CultureContent';
import FellowshipsContent from './FellowshipsContent';
import InternshipsContent from './InternshipsContent';
import OpportunitiesHeroImage from '../OpportunitiesHeroImage';
import OpportunitiesNav from '../OpportunitiesNav';
import './OpportunitiesPage.css';

const OpportunitiesPage = ({
  jobTabs,
  cultureTabs,
  fellowPositions,
  match,
}) => {
  const careersTab = find(
    jobTabs.edges,
    (e) => get(e, 'node.title', '') === 'Careers',
  );
  const fellowsTab = find(
    jobTabs.edges,
    (e) => get(e, 'node.title', '') === 'Fellows Program',
  );
  const internshipsTab = find(
    jobTabs.edges,
    (e) => get(e, 'node.title', '') === 'Internships',
  );

  return (
    <div className="OpportunitiesPage">
      <AppFixedHeader sharing={{}} />

      <OpportunitiesHeroImage src="https://thirdway.imgix.net/about/CareersPagePhoto2021.jpg" />

      <div className="primary">
        <Grid fluid className="first">
          <Row className="mb-4">
            <Col xs={12}>
              <OpportunitiesNav className="mb-3" />

              <Route
                path="/careers"
                component={() => (
                  <CareersContent data={careersTab} match={match} />
                )}
              />

              <Route
                path="/about/fellows-program"
                component={() => (
                  <FellowshipsContent
                    data={fellowsTab}
                    fellowPositions={fellowPositions}
                    match={match}
                  />
                )}
              />

              <Route
                path="/internships"
                component={() => (
                  <InternshipsContent data={internshipsTab} match={match} />
                )}
              />

              <Route
                path="/culture"
                component={() => (
                  <CultureContent data={cultureTabs} match={match} />
                )}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default OpportunitiesPage;
