import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash-es/get';

import './IssueTagMenu.css';

const IssueTagMenu = ({ className, currentIssueID, onChange, totalCount, tags = [] }) => (
  <select
    className={classNames('IssueTagMenu form-select', className)}
    onChange={(event) => onChange(event.target.value)}
    value={currentIssueID}
  >
    <option value="">{`All Topics (${totalCount})`}</option>

    {tags.map((tag) => (
      <option
        key={tag.id}
        value={tag.id}
      >
        {tag.title}
        {get(tag, 'count', null) ? ` (${tag.count})` : ''}
      </option>
    ))}
  </select>
);


IssueTagMenu.propTypes = {
  /**
   * A callback function when a tag item is selected.
   */
  onSelect: PropTypes.func,
  /**
   * The list of tags to display.
   */
  tags: PropTypes.array.isRequired,
};

export default IssueTagMenu;
