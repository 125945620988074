import React from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import AboutPageLandingComponent from '../../components/AboutPageLanding';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

const AboutPageLanding = ({ data }) => {
  if (data.loading) { return (<LoadingSpinner fullPage={false} />); }
  if (data.error) { return (<Error error={data.error} />); }

  const aboutBlocks = get(data, 'aboutContent.aboutBlock') || [];

  return (
    <AboutPageLandingComponent
      blocks={aboutBlocks}
    />
  );
};

export default graphql(query)(AboutPageLanding);
