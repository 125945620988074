import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash-es';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import SideNav from '../SideNav';

import { itemsFromRelated } from '../../utils/itemFromEntry';

const SeriesSideNav = ({ className, items, selectedId, series }) => {
  if (items.length < 2) return null;

  let nextPrevItems = [];

  const arrayPos = findIndex(items, function(i) {
    return i.id === selectedId;
  });

  if (items[arrayPos + 1]) items[arrayPos + 1].labelPrefix = 'Next';
  if (items[arrayPos - 1]) items[arrayPos - 1].labelPrefix = 'Previous';

  const filteredItems = itemsFromRelated(items);

  // Two items in a series, do not show both next/previous
  if (items.length === 2) {
    nextPrevItems = filteredItems.filter((item, index) => {
      return index !== arrayPos;
    });
  }

  // If we have over three items, display typical next/previous
  if (items.length > 2) {
    switch (arrayPos) {
      case 0:
        nextPrevItems.push(
          filteredItems[arrayPos + 1],
          filteredItems[arrayPos + 2],
        );
        break;
      case items.length - 1:
        nextPrevItems.push(
          filteredItems[arrayPos - 2],
          filteredItems[arrayPos - 1],
        );
        break;
      default:
        nextPrevItems.push(
          filteredItems[arrayPos - 1],
          filteredItems[arrayPos + 1],
        );
    }
  }

  return (
    <div>
      <SideNav
        className={className}
        items={nextPrevItems}
        title="Also in this Series"
      />

      <div className="link">
        <Link to={`/${series.uri}`} className="label text-uppercase">
          <FontAwesomeIcon
            icon={['fas', 'th-list']}
            className="icon-th-list mr-2"
          />
          View All {items.length}
        </Link>
      </div>
    </div>
  );
};

SeriesSideNav.propTypes = {
  /**
   * The currently selected item's `id`.
   */
  selectedId: PropTypes.number.isRequired,
  /**
   * An array of series items. This should be the entire series,
   * as this component will hide the currently selected item.
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  ),
};

export default SeriesSideNav;
