import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';

import { Desktop, Mobile } from '../Devices';
import { Tab, Tabs } from '../Tabs';
import TeamCarousel from '../TeamCarousel';
import TeamGrid from '../TeamGrid';

import './TeamTabs.css';

const TeamTabs = (props) => {
  const {
    filterByTeam,
    onTabChange,
    onTeamChange,
    selectedIndex,
    selectedTeam,
    staffTypes,
    teams
  } = props;

  return (
    <Row className="TeamTabs">
      <Col xs={12}>
        <h1 className="mb-4">Team</h1>
      </Col>

      <Col xs={12}>
        <Tabs onChange={onTabChange}>
          {staffTypes.map((staffType, i) => (
            <Tab title={staffType.title} key={i} active>
              {staffType.slug === 'staff' && (
                <div className="team-select mb-5">
                  <label htmlFor="team">Show:</label>

                  <select
                    className="form-select"
                    name="team"
                    onChange={onTeamChange}
                    value={selectedTeam}
                  >
                    <option value="">All Staff</option>

                    {
                      teams.map(((team, i) => (
                        <option key={i} value={team.slug}>
                          {team.title}
                        </option>
                      )))
                    }
                  </select>
                </div>
              )}

              <Desktop>
                <TeamGrid data={filterByTeam(staffType.staff)} staffType={staffType.slug} />
              </Desktop>

              <Mobile>
                <TeamCarousel data={filterByTeam(staffType.staff)} selectedTabIndex={selectedIndex} staffType={staffType.slug}  />
              </Mobile>
            </Tab>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
}

TeamTabs.propTypes = {
  filterByTeam: PropTypes.func,
  onTabChange: PropTypes.func,
  onTeamChange: PropTypes.func,
  selectedIndex: PropTypes.number,
  selectedTeam: PropTypes.string,
  staffTypes: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    staff: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  teams: PropTypes.array,
};

export default TeamTabs;
