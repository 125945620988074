import gql from 'graphql-tag';

export default gql`
  query {
    globals {
      companyInfo {
        globalFacebook
        globalInstagram
        globalLinkedin
        globalTwitter
        globalYoutube
      }
    }
  }
`;
