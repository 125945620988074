import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Button from '../Button';
import Divider from '../Divider';
import EventContact from '../EventContact';
import EventDetails from '../EventDetails';
import EventFeaturedImage from '../EventFeaturedImage';
import EventsPageEventList from '../../containers/EventsPageEventList';
import formatDate from '../../utils/formatDate';
import TopicLabel from '../TopicLabel';
import RsvpModal from '../RsvpModal';

import './EventsPage.css';

const EventsPage = ({ event }) => (
  <div className="EventsPage">
    <AppHelmet title="Events" />

    <AppFixedHeader />

    <Grid fluid>
      <Row>
        <Col xs={12}>
          <h1 className="mb-4">Events</h1>
        </Col>
      </Row>

      {event &&
        <Row className="FeaturedEvent">
          <Col xs={12} xl={9}>
            <EventFeaturedImage
              event={event}
            />

            <TopicLabel
              className="mb-2"
              name={`${(event.eventStartDate > moment().unix()) ? "Upcoming" : "Featured"} Event`}
            />

            <h2 className="mb-2">
              <Link to={`/events/${event.slug}`}>
                {event.title}
              </Link>
            </h2>

            <h5 className="event-start-date mb-3">
              {formatDate(event.eventStartDate)}
            </h5>
          </Col>

          <Col xs={12} xl={3}>
            <Row>
              <Col xs={12} md={4} xl={12}>
                {event.eventStartDate > moment().unix() && event.eventBriteId &&
                  <RsvpModal eventBriteId={event.eventBriteId}>
                    <Button
                      block
                      className="text-uppercase mb-4"
                      primary
                      large
                    >
                      RSVP For This Event
                    </Button>
                  </RsvpModal>
                }
              </Col>

              {event.eventContact && event.eventContact[0] &&
                <Col xs={12} md={4} xl={12}>
                  <EventContact
                    event={event}
                  />
                </Col>
              }

              <Col xs={12} md={4} xl={12}>
                <EventDetails
                  event={event}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }

      {event &&
        <Divider className="my-5" />
      }

      <Row>
        <Col xs={12}>
          <h2 className="mb-4">Past Events</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={9}>
          <EventsPageEventList />
        </Col>
      </Row>
    </Grid>
  </div>
);


export default EventsPage;
