import gql from 'graphql-tag';

export default gql`
  query($id: [Int]) {
    cardStack: entry(type:[Cards, CardsCardSet], id: $id) {
      id
      slug
      title

      children {
        id
        slug
        title

        ...on Cards {
          cardImage {
            id
            title
            url
          },
          cardThumbnail {
            id
            title
            url
          }
        }
      }
    }
  }
`;
