import moment from 'moment-timezone';

// dateTime should be a unix number
const formatDate = (dateTime = undefined, format = 'LL') => {
  if (!dateTime) { return null; }
  if (!moment.unix(dateTime).isValid()) { return dateTime; }
  return moment.unix(dateTime,).tz('America/New_York').format(format);
};

export default formatDate;
