import React from 'react';

import './EventContact.css';

const EventContact = ({ contact, title = 'Event Contact' }) => {
  if (!contact) { return null; }

  return (
    <div className="EventContact mb-4">
      <h3 className="mb-3">{title}</h3>

      {contact.title &&
        <li className="strong">{contact.title}</li>
      }

      {contact.position &&
        <li>{contact.position}</li>
      }

      {contact.phoneNumber &&
        <li>{contact.phoneNumber}</li>
      }

      {contact.email &&
        <li>
          <a href={`mailto:${contact.email}`}>
            {contact.email}
          </a>
        </li>
      }
    </div>
  );
}

export default EventContact;
