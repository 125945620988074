export default () => {
    return `
        <svg width="35" height="35" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23.5" cy="23.5" r="23.5" fill="#FFAE3B" class="outer-ring"/>
            <circle cx="23.5" cy="23.5" r="20.5" fill="white" class="inner-ring"/>
            <circle cx="23.5" cy="23.5" r="17.5" fill="#5B7B75" class="ring"/>
            <g transform="translate(6,6)">
                <path d="M27.9207 15.8121C26.2647 12.285 22.3713 10 18.0003 10C13.6293 10 9.73599 12.285 8.07994 15.8121C7.89682 16.2102 8.06402 16.6879 8.46211 16.871C8.86019 17.0621 9.32994 16.8869 9.52102 16.4889C10.9223 13.5111 14.2503 11.5924 18.0003 11.5924C21.7503 11.5924 25.0863 13.5191 26.4796 16.4889C26.615 16.7755 26.9016 16.9427 27.2041 16.9427C27.3156 16.9427 27.435 16.9188 27.5385 16.871C27.9366 16.6799 28.1038 16.2102 27.9207 15.8121Z" fill="white"/>
                <path d="M17.5 23.5C18.5 22.5 18.5 21.5 17.5 20.5C16 18.5 19 18.5 18 16.5C18 15.5 18 14.4 18 14M18 14L20 16M18 14L16 16" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>
    `;
}
