import React, { useRef, useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import TechnologyIcon from '../TechnologyIcon';
import { uniqBy } from 'lodash-es'
import SnapshotModal from '../MapPageSnapshotModal';
import './MapPageProjectSnapshots.css';

const MapPageProjectSnapshots = ({ content, recentSnapshots, activeSnapshot, setActiveSnapshot, selectedCategoryFilter }) => {
  const allSnapshots = useRef([]);
  const [visibleSnapshots, setVisibleSnapshots] = useState([]);

  // Combine and deduplicate snapshots
  useEffect(() => {
      const snapshots = [].concat(content.featuredProjectSnapshots).concat(recentSnapshots);
      allSnapshots.current = uniqBy(snapshots, 'id');
      // Initialize with the 4 most recent snapshots
      setVisibleSnapshots(allSnapshots.current.slice(0, 4));
  }, [content.featuredProjectSnapshots, recentSnapshots]);

  // Filter snapshots based on selected technology category
  useEffect(() => {
      if (selectedCategoryFilter) {
          const filteredSnapshots = allSnapshots.current
            .filter(snapshot =>
              snapshot.energyTechnology.some(tech => Number(tech.technologyId) === Number(selectedCategoryFilter))
            )
            .slice(0, 4) // Limit to 4 most recent
          setVisibleSnapshots(filteredSnapshots);
      } else {
        setVisibleSnapshots(allSnapshots.current.slice(0, 4));
      }
  }, [selectedCategoryFilter]);

  const FeaturedSnapshots = () => {
    return visibleSnapshots.map(snapshot => (
      <Col xs={12} sm={6} xl={3} className="snapshot-wrapper mb-3 mb-xl-0" key={snapshot.id}>
        <button
          className="c-pointer snapshot pr-xl-3"
          aria-label={`Open ${snapshot.title} details`}
          onClick={() => setActiveSnapshot(snapshot.id)}
        >
          {snapshot.energyTechnology.map((tech, index) => (
            <TechnologyIcon slug={tech.slug} key={`tech-${index}`} />
          ))}
          <h2 className="text-paragraph-large">{snapshot.title}</h2>
          <p className="text-caption mt-2 mb-xl-0">{snapshot.city}, {snapshot.state}</p>
        </button>
      </Col>
    ));
  }

  return (
    <section className="MapPageProjectSnapshots">
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <div className="heading pt-3 mb-4">
              <h2 className="text-delta">{content.projectSnapshotsHeading}</h2>
            </div>
          </Col>
        </Row>
        <Row>
          {FeaturedSnapshots(content, recentSnapshots)}
        </Row>
        <Row>
          {activeSnapshot &&
            <SnapshotModal
              snapshot={allSnapshots.current.find((snapshot) => snapshot.id === activeSnapshot)}
              closeHandler={() => {setActiveSnapshot(null)}}
            />
          }
        </Row>
      </Grid>
    </section>
  )
}

export default MapPageProjectSnapshots;
