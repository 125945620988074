import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import './JumpToSection.css';

const scroll = el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })

const JumpToSection = ({ className, header, menuOpen, sections = [], toggleMenu }) => (
  <div className={classNames('JumpToSection', className, { 'expanded': menuOpen, header })}>
    <div className="section-toggle c-pointer" onClick={toggleMenu}>
      <FontAwesomeIcon icon={['fas', 'bookmark']} className="icon-bookmark" />
      <h4 className="label">{header ? "Jump to" : "Jump to section"}&hellip;</h4>
      {menuOpen && !header &&
        <FontAwesomeIcon icon={['fas', 'times']} className="icon-times" />
      }
    </div>

    <div className="section-list">
      <ul className="list-unstyled" onClick={toggleMenu}>
        {menuOpen && header &&
          <li className="section-close">
            <FontAwesomeIcon icon={['fas', 'times']} className="icon-times" />
          </li>
        }

        {sections.map((section) => (
          <li key={section.url}>
            <NavLink
              scroll={scroll}
              to={`#${section.url}`}
            >
              {section.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

JumpToSection.propTypes = {
  /**
   * The sections contained on the page.
   */
  sections: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default JumpToSection;
