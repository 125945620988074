import gql from 'graphql-tag';
import { RelatedMapProducts} from '../../fragments/RelatedProducts';

const MapPageQuery = gql`
query mapQuery {
    mapContent: entry(section: map) {
      id
      enabled
      slug
      title
      ... on Map {
        heroOverline
        heroHeading
        heroBody {
          content
        }
        heroImage {
          id
          title
          url
        }
        secondaryImages {
          url
          title
          id
        }
        mapOverline
        mapHeading
        mapBody {
          content
        }
        caption {
          content
        }
        projectSnapshotsHeading
        featuredProjectSnapshots {
          ... on ProjectSnapshotsDefault {
            id
            slug
            title
            city
            state
            mapCoordinates
            body {
              content
            }
            energyTechnology {
              ... on EnergyTechnologyDefault {
                id
                slug
                title
                technologyId
              }
            }
          }
        }
        relatedContentHeading
        ...RelatedMapProducts
        methodologyHeading
        methodologyBody {
          content
        }
      }
    }
    recentSnapshots: entries(section: projectSnapshots, orderBy: "dateCreated DESC") {
      ... on ProjectSnapshotsDefault {
        id
        slug
        title
        city
        state
        mapCoordinates
        body {
          content
        }
        energyTechnology {
          ... on EnergyTechnologyDefault {
            id
            slug
            title
            technologyId
          }
        }
      }
    }
    energyTechnology: entries(section: energyTechnology) {
      ... on EnergyTechnologyDefault {
        id
        slug
        title
        technologyId
        technologyPage {
          uri
        }
      }
    }
  }

  ${RelatedMapProducts}
`;

export default MapPageQuery;
