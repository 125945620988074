import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query pressReleasesAndMedia($slug: String!) {
    contactInfo: globals {
      companyInfo {
        globalPhone
        globalFax
        globalEmail
      }
    }

    pressInfo: globals {
      companyInfo {
        pressContact {
          id
          title

          ... on Staff {
            ...Staff
          }
        }
      }
    }

    pressRelease: entry(slug: $slug, section: pressReleases) {
      id
      title
      slug
      section {
        id
        name
      }
      ... on PressReleases {
        postDate
        authors {
          __typename
          id
          slug
          title
          ... on Staff {
            position
            twitterHandle
            uri
            photo {
              id
              url
            }
          }
          ... on ExternalAuthors {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }
        body {
          totalPages
          content
        }
        relatedProducts {
          id
          title
          uri
          type {
            name
          }
          __typename
          ... on Products {
            productType {
              slug
            }
          }
          ... on InTheNews {
            newsItemUrl
          }
        }
        issues {
          id
          title
          issueIcon
          ... on IssuesCategory {
            issueSocialMediaAccount {
              ... on IssueSocialMediaAccountTwitterAccount {
                twitterHandle
              }
              ... on IssueSocialMediaAccountInstagramAccount {
                instagramName
              }
            }
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
