import gql from 'graphql-tag';

export default gql`
  query FellowshipQuery($slug: String) {
    fellowship: entry(slug: $slug) {
      id
      slug
      title
      postDate
      ...on Positions {
        body {
          content
        }
        positionUrl
      }
    }
  }
`;
