import gql from 'graphql-tag';

export default gql`
  query IssueFiltersQuery(
    $issueID: Int,
    $searchQuery: String,
    $section: [SectionsEnum],
    $parentID: [RelatedToInputType],
  ) {
    allTopics: entriesConnection(
      relatedTo: $parentID,
      public: true,
      section: $section,
    ) {
      totalCount
    }
    categoriesConnection(
      descendantOf: $issueID,
      groupId: 3,
    ) {
      edges {
        node {
          id
          slug
          title
          uri
        }

        relatedEntries(
          public: true,
          search: $searchQuery,
          section: $section,
        ) {
          totalCount
        }
      }
    }
  }
`;
