import React from 'react';

import './ElementLabel.css';

const ElementLabel = ({ children }) => (
  <div className="ElementLabel">
    {children}
  </div>
);

export default ElementLabel;
