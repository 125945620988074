import React from 'react';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import HomePageComponent from '../../components/HomePage';
import { itemFromEntry, itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

const HomePage = ({ data }) => {
  const { entry, loading } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (!entry) { return (<NotFoundPage />); }

  const featuredEntryItem = itemFromEntry(entry.featuredContent[0]);
  const upperContent = itemsFromEntries(entry.featuredContent.slice(1, 4));
  const lowerContent = itemsFromEntries(entry.featuredContent.slice(4, 8));

  return (
    <HomePageComponent
      featuredEntryItem={featuredEntryItem}
      lowerContent={lowerContent}
      upperContent={upperContent}
    />
  );
};

HomePage.propTypes = {
  data: dataShape,
};

export default graphql(query)(HomePage);
