import PropTypes from 'prop-types';

const paginationShape = PropTypes.shape({
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
});

export default paginationShape;
