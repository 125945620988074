import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import IssueTagList from '../IssueTagList';
import TopicLabel from '../TopicLabel';

const IssuesRelated = ({ tags, history }) => {
  if (!tags.length > 0) { return null; }

  const loadIssuePage = (tag) => {
    history.push({
      pathname: `/${tag.uri.split('/').slice(0,-1).join('/')}`,
      search: `?issueID=${tag.id}`
    });
  }

  return (
    <div className="IssuesRelated mt-5 mb-5">
      <TopicLabel name="Topics" />
      <IssueTagList
        className="inline"
        onClick={loadIssuePage}
        tags={tags}
      />
    </div>
  );
};

IssuesRelated.propTypes = {
  tags: PropTypes.array,
};

export default withRouter(IssuesRelated);
