import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import './IssueMenu.css';

class IssueMenu extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    items: PropTypes.array,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    items: [],
  };

  constructor() {
    super();

    this.toggleMenu = this.toggleMenu.bind(this);

    this.state = {
      showing: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.showing) return false;

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ showing: false });
    }
  }

  toggleMenu() {
    this.setState({ showing: !this.state.showing });
  }

  render() {
    const { icon, items, slug, title } = this.props;

    const { showing } = this.state;

    return (
      <div className="IssueMenu">
        <h4 className="issue-label">
          <NavLink to={`/issue/${slug}`}>
            <div className="issue-title">
              <i className={`icon icon-${icon}`}></i> {title}
            </div>
          </NavLink>
          <div className="toggle" onClick={this.toggleMenu}>
            <FontAwesomeIcon
              icon={['fas', 'caret-left']}
              className={classNames('icon-caret c-pointer', {
                active: showing,
              })}
            />
          </div>
        </h4>

        <div className={classNames('issue-list', { collapsed: !showing })}>
          <ul className="list-unstyled">
            {items.map((item, i) => {
              return (
                <li key={item.id}>
                  <NavLink
                    activeClassName="active"
                    isActive={(match, location) =>
                      `/issue/${slug}?issueID=${item.id}` ===
                      `${location.pathname}${location.search}`
                    }
                    strict
                    to={`/issue/${slug}?issueID=${item.id}`}
                  >
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(IssueMenu);
