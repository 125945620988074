import PropTypes from 'prop-types';

import authorShape from './authorShape';
import issuesCategoryShape from './issuesCategoryShape';

const item = PropTypes.shape({
  eventStartDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  imageTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  itemAuthors: PropTypes.arrayOf(authorShape),
  itemBlurb: PropTypes.string,
  itemIssues: PropTypes.arrayOf(issuesCategoryShape),
  itemPublicationDate: PropTypes.string,
  itemPublicationLogo: PropTypes.string,
  itemPublicationName: PropTypes.string,
  itemTitle: PropTypes.string,
  itemUrl: PropTypes.string,
  topicName: PropTypes.string,
  typename: PropTypes.string,
});

export default item;
