import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';
import { RelatedProductsProducts } from '../../fragments/RelatedProducts';

const ProductPageQuery = gql`
  query ProductPageQuery($slug: String) {
    product: entry(slug: $slug, type: Products) {
      dateCreated
      id
      postDate
      slug
      title
      uri

      ... on Products {
        productBlurb
        productBody
        productEndnotes
        productPublicationSlug
        productPublicationUpdateDate
        productReadingTime
        productTakeaways
        slug

        featuredVideo {
          ... on FeaturedVideoYoutubeVideo {
            youtubeId
          }
          ... on FeaturedVideoFacebookVideo {
            facebookVideoUrl
          }
        }

        productIssue {
          id
          slug
          title
          uri

          ... on IssuesCategory {
            issueIcon
          }
        }

        productAuthors {
          __typename
          id
          slug
          title

          ... on Staff {
            ...Staff
          }

          ... on ExternalAuthors {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }

        productPdfStatus

        autogeneratedPdfUrl

        productPdfOverrideFile {
          id
          mimeType
          title
          url
        }

        productDownloads {
          id
          mimeType
          title
          url
        }

        ...RelatedProductsProducts

        productFeaturedImage {
          url
          id
          title
        }

        productFeaturedImageUrl

        productSponsor {
          content
        }

        productType {
          id
          slug
          title
        }

        board {
          ... on BoardBoardMember {
            id
            bio
            blurb {
              content
            }
            memberName
            twitter
            website
            photo {
              id
              height
              url
              width
            }
          }
        }
      }
    }

    subscriptionForms: entries(section: subscriptionForms) {
      ... on SubscriptionForms {
        subscriptionFormUrl
        subscriptionFormIssues {
          id
          slug
        }
      }
    }
  }

  ${RelatedProductsProducts}
  ${StaffFragment}
`;

export default ProductPageQuery;
