import React from 'react';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../components/NotFoundPage';
import IssuePageComponent from '../../components/IssuePage';
import withContentFilters from '../../wrappers/withContentFilters';

import query from './query';

const IssuePage = (props) => {
  const { contentTypeFilter, authorFilter, data } = props;
  const { categoriesConnection, subscriptionForms = [], error, loading } = data;
  const {
    match: {
      params: { slug },
    },
  } = props;

  if (loading && !categoriesConnection) {
    return <LoadingSpinner />;
  }
  // If switched issue variables
  if (loading && data.networkStatus === 2) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <Error error={error} />;
  }

  if (!categoriesConnection.edges || !categoriesConnection.edges.length) {
    return <NotFoundPage />;
  }

  // Find the subscription url for this Issue
  let subscriptionFormUrl;
  try {
    for (const form of subscriptionForms) {
      for (const formIssue of form.subscriptionFormIssues) {
        if (formIssue.slug === slug) {
          subscriptionFormUrl = form.subscriptionFormUrl;
        }
      }
    }
  } catch (error) {
    subscriptionFormUrl = null;
  }

  return (
    <IssuePageComponent
      contentTypeFilter={contentTypeFilter}
      authorFilter={authorFilter}
      issue={categoriesConnection.edges[0].node}
      subscriptionUrl={subscriptionFormUrl}
      {...props}
    />
  );
};

export default withContentFilters(
  graphql(query, {
    options: ({ match: { params } }) => ({
      variables: {
        slug: params.slug,
      },
    }),
  })(IssuePage),
);
