import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import formatDate from '../../utils/formatDate';
import formatDuration from '../../utils/formatDuration';

import './TopicLabel.css';

/**
 * A label that sits above a Topic title.
 */
class TopicLabel extends Component {
  static propTypes = {
    /**
     * The expected duration of engagement in seconds
     */
    engagement: PropTypes.string,
    /**
     * The topic label name.
     */
    name: PropTypes.string.isRequired,
    /**
     * The prefix to the topic label name.
     */
    prefix: PropTypes.string,
    /**
     * Publish date.
     */
    publishedAt: PropTypes.string,
    /**
     * Source publication name
     */
    publicationName: PropTypes.string,
  };

  static defaultProps = {
    engagement: null,
    publishedAt: null,
  };

  render() {
    const {
      engagement,
      name,
      prefix,
      publishedAt,
      publicationName,
    } = this.props;

    return (
      <div className={classNames('TopicLabel', this.props.className)}>
        {prefix && <div className="prefix">{prefix}:</div>}
        <div className="name">{name}</div>
        {publicationName && (
          <div className="publication-name">{publicationName}</div>
        )}
        {publishedAt && (
          <div className="published-at">
            Published {formatDate(publishedAt)}
          </div>
        )}
        {publishedAt && formatDuration(engagement) && (
          <span className="bullet">&bull;</span>
        )}
        {formatDuration(engagement) && (
          <div className="engagement">{formatDuration(engagement)} read</div>
        )}
      </div>
    );
  }
}

export default TopicLabel;
