import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const withContentFilters = (WrappedComponent) => {
  class ContentFiltersWrapper extends Component {
    constructor() {
      super();

      this.onContentTypeChange = this.onContentTypeChange.bind(this);
      this.onAuthorChange = this.onAuthorChange.bind(this);
      this.setCurrent = this.setCurrent.bind(this);
      this.setFilterFromQueryString = this.setFilterFromQueryString.bind(this);

      this.state = {
          contentType: null,
          author: null,
      };
    }

    componentWillMount() {
      this.setFilterFromQueryString('contentType');
      this.setFilterFromQueryString('author');
    }

    componentWillReceiveProps(nextProps) {
      const { contentType, author } = this.getCurrentFromQueryString(this.props.location.search);
      const nextContentType = this.getCurrentFromQueryString(nextProps.location.search).contentType;
      const nextAuthor = this.getCurrentFromQueryString(nextProps.location.search).author;

      if (author !== nextAuthor) {
        this.setCurrent('author', nextAuthor);
      }

      if (contentType !== nextContentType) {
        this.setCurrent('contentType', nextContentType);
      }
    }

    setCurrent(filter, selected = null) {
      return this.setState({ [filter]: selected });
    }

    currentSelected(filter) {
      return this.getCurrentFromQueryString(this.props.location.search)[`${filter}`] || null;
    }

    onContentTypeChange(contentType = null) {
      const { author, issueID, q } = queryString.parse(this.props.location.search);

      this.props.history.push({
        search: `?${queryString.stringify({ contentType, author, issueID, q })}`
      });
    }

    onAuthorChange(author = null) {
      const { issueID, contentType, q } = queryString.parse(this.props.location.search);

      this.props.history.push({
        search: `?${queryString.stringify({ contentType, author, issueID, q })}`
      });
    }

    getCurrentFromQueryString(search) {
      const { contentType = null, author = null } = queryString.parse(search);

      return { contentType, author };
    }

    setFilterFromQueryString(filter) {
      return this.setCurrent(filter, this.currentSelected(filter));
    }

    render() {
      const { contentType, author } = this.state;

      return (
        <WrappedComponent
          contentTypeFilter={{
            contentTypes: [
              { slug: 'products', title: 'Products' },
              { slug: 'events', title: 'Events' },
              { slug: 'inTheNews', title: 'Media' },
              { slug: 'pressReleases', title: 'Press Releases' },
              { slug: 'series', title: 'Series' },
            ],
            currentContentType: contentType || undefined,
            onContentTypeChange: this.onContentTypeChange,
          }}
          authorFilter={{
            currentAuthor: author || undefined,
            onAuthorChange: this.onAuthorChange,
          }}
          {...this.props}
        />
      );
    }
  }

  return withRouter(ContentFiltersWrapper);
}

export default withContentFilters;
