import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import chunk from 'lodash-es/chunk';
import get from 'lodash-es/get';

import IssuePageFeaturedItem from '../../IssuePageFeaturedItem';

const ThreeUpLayout = ({ items }) => {
  return (
    <div>
      {chunk(items, 3).map((group, i) => (
        <Row className="mb-3" key={i}>
          <Col xs={12} md={6}>
            <IssuePageFeaturedItem
              featuredItem={get(group, '[0]', null)}
              orientation="vertical"
              showBlurb={false}
              showAuthors={false}
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <IssuePageFeaturedItem
                  featuredItem={get(group, '[1]', null)}
                  orientation="horizontal"
                  showBlurb={false}
                  showAuthors={false}
                />
              </Col>
              <Col xs={12}>
                <IssuePageFeaturedItem
                  featuredItem={get(group, '[2]', null)}
                  orientation="horizontal"
                  showBlurb={false}
                  showAuthors={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default ThreeUpLayout;
