import React from 'react';
import { graphql } from 'react-apollo';
import { some } from 'lodash-es';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import NotFoundPage from '../../containers/NotFoundPage';
import ProductPageComponent from '../../components/ProductPage';
import { itemsFromRelated } from '../../utils/itemFromEntry';

import query from './query';

const ProductPage = ({ data, location, match }) => {
  const { error, loading, product, subscriptionForms } = data;
  const { params } = match;

  let related;

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (!product) {
    return <NotFoundPage data={data} location={location} />;
  }

  if (!some(product.productType, (type) => type.slug === params.type)) {
    return <NotFoundPage data={data} location={location} />;
  }

  if (product.relatedProducts && product.relatedProducts.length > 0) {
    related = itemsFromRelated(product.relatedProducts);
  }

  // Find the subscription url for this Product Issue
  const findSubscriptionFormUrl = () => {
    try {
      for (const { slug } of product.productIssue) {
        for (const form of subscriptionForms) {
          for (const formIssue of form.subscriptionFormIssues) {
            if (slug === formIssue.slug && form.subscriptionFormUrl) {
              return form.subscriptionFormUrl;
            }
          }
        }
      }
    } catch (error) {
      return null;
    }
  };
  const subscriptionFormUrl = findSubscriptionFormUrl();

  return (
    <ProductPageComponent
      product={product}
      related={related}
      subscriptionUrl={subscriptionFormUrl}
    />
  );
};

ProductPage.propTypes = {
  data: dataShape,
};

export default graphql(query, {
  options: ({ match: { params } }) => ({
    variables: { slug: params.slug },
  }),
})(ProductPage);
