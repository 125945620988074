import gql from 'graphql-tag';

export default gql`
  query {
    globals {
      companyInfo {
        globalAddressLine1
        globalAddressLine2
        globalAddressLine3
        globalEmail
        globalPhone
        globalFax
        globalTumblr
        globalTwitter
        globalFacebook
        globalLinkedin
      }
    }
  }
`;
