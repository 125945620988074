import React from 'react';
import Imgix from 'react-imgix';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import Author from '../Author';
import formatDate from '../../utils/formatDate';
import OutboundLink from '../OutboundLink';
import urlIsExternal from '../../utils/urlIsExternal';
import TopicLabel from '../TopicLabel';
import { Desktop } from '../Devices';

import './PaginatedEntry.css';

const PaginatedEntry = ({ item, displayThumbnail, headingLevel: Heading = 'h2' }) => {
  if (!item) { return null; }

  const PaginatedEntryImage = (
    <Imgix
      aggressiveLoad
      className="responsive"
      crop="center"
      height={513}
      imgProps={{
        alt: item.imageTitle,
      }}
      src={item.imageUrl}
      width={770}
    />
  );

  const PaginatedEntryPublicationLogo = item.itemPublicationLogo ? (
    <div className="publication-logo">
      <Imgix
        aggressiveLoad
        className="responsive w-100"
        crop="center"
        height={96}
        imgProps={{
          alt: item.itemPublicationName || item.topicName
        }}
        src={item.itemPublicationLogo}
        width={96}
      />
    </div>
  ) : null;

  return (
    <Row className="mb-4 PaginatedEntry">
      {displayThumbnail &&
        <Col xs={12} md={4}>
          <div className="image">
            {urlIsExternal(item.itemUrl) ? (
              <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
                {PaginatedEntryImage}
                {PaginatedEntryPublicationLogo}
              </OutboundLink>
            ) : (
               <Link to={item.itemUrl}>
                 {PaginatedEntryImage}
                 {PaginatedEntryPublicationLogo}
               </Link>
            )}
          </div>
        </Col>
      }

      <Col xs={12} md={displayThumbnail ? 8 : 12}>
        <TopicLabel
          name={item.topicName}
          publishedAt={item.itemPublicationDate}
          publicationName={item.itemPublicationName}
          engagement={item.typename === 'Products' ? item.itemReadingTime : null}
          className="mb-1"
        />

        <Heading className="mb-3">
          {urlIsExternal(item.itemUrl) ? (
            <OutboundLink eventLabel={item.itemUrl} to={item.itemUrl}>
              {item.itemTitle}
            </OutboundLink>
          ) : (
            <Link to={item.itemUrl}>
              {item.itemTitle}
            </Link>
          )}
        </Heading>

        {item.eventStartDate &&
          <h5 className="event-start-date">
            {formatDate(item.eventStartDate)}
          </h5>
        }

        {item.itemAuthors &&
          <Desktop>
            <Row>
              {item.itemAuthors.map((author) => (
                <Col xs={12} xl={6} className="pr-0 mb-2" key={author.id}>
                  <Author
                    author={author}
                    small
                  />
                </Col>
              ))}
            </Row>
          </Desktop>
        }
      </Col>
    </Row>
  );
};

export default PaginatedEntry;
