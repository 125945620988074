import React from 'react';
import classNames from 'classnames';
import Imgix from 'react-imgix';
import { get } from 'lodash-es';

import './NavItem.css';

const CardStackNavItem = ({ card, current, index, setIndex }) => (
  <li className="CardStackNavItem " onClick={() => setIndex(index)}>
    <div className="image-wrapper">
      <CardImage
        image={get(card, 'cardThumbnail').length ? get(card, 'cardThumbnail[0]') : get(card, 'cardImage[0]')}
        cardTitle={card.title}
      />
    </div>

    <span className={classNames('title', { current })}>{card.title}</span>
  </li>
);

const CardImage = ({image, cardTitle}) => (
  <Imgix
    aggressiveLoad
    crop="top"
    height={310}
    imgProps={{ alt: image.title.length ? image.title : cardTitle }}
    src={image.url}
    width={465}
  />
)

export default CardStackNavItem;
