import React from 'react';
import { NavLink } from 'react-router-dom';

import './Legal.css';

const Legal = () => (
  <div className="Legal">
    <div className="copyright">
      All content © {(new Date()).getFullYear()} Third Way
    </div>
    <div className="links">
      <NavLink to="/privacy-policy">
        Privacy Policy
      </NavLink>
      <NavLink to="/terms-of-service">
        Terms of Service
      </NavLink>
    </div>
  </div>
);

export default Legal;

