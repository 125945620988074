import gql from 'graphql-tag';

export default gql`
  query LegalPagePrivacyPolicyQuery {
    globals {
      legal {
        privacyPolicy {
          content
        }
      }
    }
  }
`;
