import gql from 'graphql-tag';

export default gql `
  query {
    globals {
      redirects {
        globalRedirects {
          ...on GlobalRedirectsRedirect {
            id
            requestedUrl
            redirectUrl
          }
        }
      }
    }
  }
`;
