import React, {useEffect, useRef, useState} from 'react';
import FilterButtons from './FilterButtons';
import { ReactSVG } from 'react-svg';
import { debounce } from 'lodash-es';
import arrowLeft from '../../../icons/arrow-left.svg';
import arrowRight from '../../../icons/arrow-right.svg';
import useScreenSize from '../../../utils/useScreenSize';

import './MapPageCategoryFilter.css';

const MapPageCategoryFilter = ({ onFilter, selectedCategoryFilter }) => {
  const wrapperRef = useRef(null);
  const stickyRef = useRef(null);
  const sectionRef = useRef(null);
  const sectionHeight = useRef(0);
  const isSticky = useRef(false);
  const [categoriesAreLoaded, setCategoriesAreLoaded] = useState(false);
  let previousScrollTop= 0;
  const scrollInterval = useRef(null);
  const scrollAmount = 10;
  const scrollSpeed = 10;
  const scrollOptions = {
    left: scrollAmount,
    behavior: 'instant'
  };
  const screenSize = useScreenSize();
  const [isMobile, setIsMobile] = useState(false);

  const startScroll = (dir) => {
    scrollOptions.left = dir === 'right' ? scrollAmount : scrollAmount * -1;

    if (scrollInterval.current) return;

    scrollInterval.current = setInterval(() => {
      if(wrapperRef.current) {
        wrapperRef.current.scrollBy(scrollOptions);
      }
    }, scrollSpeed);
  }

  const stopScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', setSticky);

    return () => {
      window.removeEventListener('scroll', setSticky);
    };
  }, [sectionRef]);

  const setSticky = () => {
    if (sectionRef.current.getBoundingClientRect().bottom <= 81 && !isSticky.current) {
      isSticky.current = true;
      stickyRef.current.classList.add('is-sticky');
      sectionRef.current.setAttribute('style', `height: ${sectionHeight.current}px`);
    } else if (sectionRef.current.getBoundingClientRect().bottom > 81 && isSticky.current) {
      isSticky.current = false;
      stickyRef.current.classList.remove('is-sticky');
      sectionRef.current.removeAttribute('style');
    }

    // if scrolling up the menu will appear, so adjust the filter position
    sectionRef.current.getBoundingClientRect().top >= previousScrollTop ? stickyRef.current.classList.add('header-visible') : stickyRef.current.classList.remove('header-visible');
    previousScrollTop = sectionRef.current.getBoundingClientRect().top;
  };

  useEffect(() => {
    if (categoriesAreLoaded) {
      sectionHeight.current = sectionRef.current.offsetHeight;
    }
  }, [categoriesAreLoaded]);

  useEffect(() => {
    if (isMobile && screenSize.width > 991) {
      setIsMobile(false);
    } else if (!isMobile && screenSize.width <= 991) {
      setIsMobile(true);
    }
  }, [screenSize]);

  useEffect(() => {
    if (sectionRef.current) {
      sectionHeight.current = sectionRef.current.offsetHeight;
    }
  }, [isMobile])

  return (
    <section className="MapPageCategoryFilter" ref={sectionRef}>
      <div id="category-sticky-container" ref={stickyRef}>
        <div className="heading">
          <h2 className="text-eta text-uppercase">Filter Page By Category</h2>
        </div>
        <div className="category-filter-container">
          <div className="category-filter-desktop-overlay"></div>
          <div className="category-filter-wrapper mb-xl-0">
            <div className="category-filter-list" ref={wrapperRef}>
              <FilterButtons
                onFilter={onFilter}
                selectedCategoryFilter={selectedCategoryFilter}
                setCategoriesAreLoaded={setCategoriesAreLoaded}
              />
            </div>
          </div>
          <div className="category-filter-scrollers">
            <button
              className="category-filter-scroller"
              aria-label="scroll categoeis left"
              onTouchStart={() => startScroll('left')}
              onMouseDown={(e) => startScroll('left')}
              onMouseUp={() => stopScroll()}
              onTouchEnd={() => stopScroll()}
              onMouseLeave={() => stopScroll()}
            >
              <ReactSVG src={arrowLeft} />
            </button>
            <button
              className="category-filter-scroller"
              aria-label="scroll categoies right"
              onTouchStart={() => startScroll('right')}
              onMouseDown={() => startScroll('right')}
              onMouseUp={() => stopScroll()}
              onTouchEnd={() => stopScroll()}
              onMouseLeave={() => stopScroll()}
            >
              <ReactSVG src={arrowRight} />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MapPageCategoryFilter;
