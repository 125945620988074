import React, { Component } from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';

import AppLogo from '../AppLogo';
import IssuesNav from '../../containers/IssuesNav';
import Legal from './Legal';
import SecondaryNav from '../SecondaryNav';
import SocialLinks from '../../containers/SocialLinks';

import './AppFooter.css';

class AppFooter extends Component {
  render() {
    const { issuesMenuOpen, toggleIssues } = this.props;

    return(
      <footer className={classNames('AppFooter', {'menu-is-visible': issuesMenuOpen})}>
        <div className="footer-row">
          <AppLogo inverse footer />
          <MediaQuery minWidth={992}>
            <SecondaryNav />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <div className="controls">
              <div className="menu-toggle" onClick={toggleIssues}>
                {issuesMenuOpen &&
                  <i className="icon icon-close" />
                }
                {!issuesMenuOpen &&
                  <FontAwesomeIcon icon={['fas', 'bars']} color="white" size="lg" className="c-pointer" />
                }
              </div>
            </div>
          </MediaQuery>
        </div>
        <div className="footer-row">
          <IssuesNav />
        </div>
        <div className="footer-row bottom-links">
          <Legal />
          <SocialLinks className="mr-lg-5 mb-4" />
        </div>
      </footer>
    )
  }
}

export default AppFooter;

