import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';
import { find } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import companyInfoShape from '../../shapes/companyInfoShape';
import Divider from '../Divider';
import IssueFilters from '../../containers/IssueFilters';
import OutboundLink from '../OutboundLink';
import PressPageResults from '../../containers/PressPageResults';
import SubscribeBox from '../../containers/SubscribeBox';

import './PressPage.css';

const PressPage = ({ contactInfo, location, pressInfo }) => {
  // Strip a possible trailing slash
  const pathname = location.pathname.replace(/\/$/, '');

  const navLinks = [
    { pathname: '/press', title: 'Press Releases' },
    { pathname: '/press/media', title: 'Media' },
  ];

  const currentPageTitle = find(
    navLinks,
    (navLink) => navLink.pathname === pathname,
  ).title;

  return (
    <div className="PressPage">
      <AppHelmet title={currentPageTitle || 'Press'} />

      <AppFixedHeader />

      <Grid fluid>
        <Row middle="xs">
          <Col xs={6}>
            <h1>Press</h1>
          </Col>
          <Col xs={6} className="text-right">
            <OutboundLink
              className="Button secondary small text-uppercase"
              eventLabel="https://thirdway.imgix.net/press/Third-Way-Press-Kit.zip"
              to="https://thirdway.imgix.net/press/Third-Way-Press-Kit.zip"
            >
              View Our Press Kit
            </OutboundLink>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Divider className="my-5" />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4} lg={3}>
            <IssueFilters />
          </Col>

          <Col xs={12} md={8} lg={9}>
            <Row>
              <Col xs={12} className="pb-4">
                {navLinks.map((link, i) => (
                  <h2
                    className={classNames('press-nav-link', {
                      active: pathname === link.pathname,
                    })}
                    key={i}
                  >
                    <Link to={link.pathname}>{link.title}</Link>
                  </h2>
                ))}
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                {/*
                  We could just render the PressPageResults component here, but in
                  doing so the pagination nav for the previous section will be displayed
                  while the next section you're navigating to is loading.
                */}
                {navLinks.map((link, i) => (
                  <Route
                    exact
                    path={link.pathname}
                    component={PressPageResults}
                    key={i}
                  />
                ))}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="my-5">
                  <SubscribeBox size={"small"} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider className="mb-5" />

        <Row>
          <Col xs={12} lg={7}>
            <Row>
              <Col xs={12} md={6}>
                <h2 className="mb-3">General</h2>
                <p className="mb-5 mb-lg-0">
                  {get(contactInfo, 'globalPhone') && (
                    <span>
                      {contactInfo.globalPhone}
                      <br />
                    </span>
                  )}
                  {get(contactInfo, 'globalFax') && (
                    <span>
                      {contactInfo.globalFax} (fax)
                      <br />
                    </span>
                  )}
                  {get(contactInfo, 'globalEmail') && (
                    <a
                      href={`mailto:${contactInfo.globalEmail}`}
                      className="link-secondary"
                    >
                      {contactInfo.globalEmail}
                    </a>
                  )}
                </p>
              </Col>

              {pressInfo && (
                <Col xs={12} md={6}>
                  <h2 className="mb-3">Press Contact</h2>
                  {pressInfo.map((contact) => (
                    <p className="mb-5 mb-lg-0" key={contact.id}>
                      {get(contact, 'title') && (
                        <span>
                          <strong>{contact.title}</strong>
                          <br />
                        </span>
                      )}
                      {get(contact, 'position') && (
                        <span>
                          <em>{contact.position}</em>
                          <br />
                        </span>
                      )}
                      {get(contact, 'phoneNumber') && (
                        <span>
                          {contact.phoneNumber}
                          <br />
                        </span>
                      )}
                      {get(contact, 'email') && (
                        <a
                          href={`mailto:${contact.email}`}
                          className="link-secondary"
                        >
                          {contact.email}
                        </a>
                      )}
                    </p>
                  ))}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

PressPage.propTypes = {
  contactInfo: companyInfoShape,
  pressInfo: PropTypes.arrayOf(companyInfoShape),
};

PressPage.defaultProps = {
  contactInfo: {},
  pressInfo: [],
};

export default PressPage;
