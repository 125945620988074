import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';

import Button from '../Button';
import LoadingSpinner from '../LoadingSpinner';

import './CategoryList.css';

const CategoryList = ({ categories = [], loading }) => {
  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (!categories.length) { return null; }

  return (
    <div className="CategoryList">
      <Row>
        {categories.map(category => (
          <Col xs={12} sm={6} lg={4} className="issue-button" key={category.id}>
            <Link to={`/issue/${category.slug}`}>
              <Button primary xlarge block tag="span">
                <i className={classNames('icon', `icon-${category.issueIcon}`)} /> {category.title}
              </Button>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CategoryList;
