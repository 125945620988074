import React from 'react';
import classNames from 'classnames';
import Imgix from 'react-imgix';
import MediaQuery from 'react-responsive';
import { get } from 'lodash-es';

import CardStackNavItem from './NavItem';

import './CardStack.css';

const CardStack = ({ cardStack, currentIndex, setIndex }) => (
  <div className="CardStack">
    <h2>{cardStack.title}</h2>

    <div className="card-stack-content">
      <MediaQuery maxWidth={575}>
        <select
          className="form-select"
          onChange={(event) => setIndex(parseInt(event.target.value, 10))}
          value={currentIndex}
        >
          {cardStack.children.map((card, index) => (
            <option
              key={card.id}
              value={index}
            >
              {card.title}
            </option>
          ))}
        </select>
      </MediaQuery>

      <MediaQuery minWidth={576}>
        <nav>
          <ul className="list-unstyled">
            {cardStack.children.map((card, index) => (
              <CardStackNavItem
                card={card}
                current={index === currentIndex}
                index={index}
                key={card.id}
                setIndex={setIndex}
              />
            ))}
          </ul>
        </nav>
      </MediaQuery>

      <div className="current-card">
        <div className="current-card-image">
          {cardStack.children.map((card, index) => (
            <div className={classNames({ hidden: index !== currentIndex })} key={index}>
              <Imgix
                aggressiveLoad
                fit="max"
                imgProps={{ alt: get(card, 'cardImage[0].title' || card.title) }}
                src={get(card, 'cardImage[0].url')}
                width={1350}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default CardStack;
