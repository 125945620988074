import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';

import Author from '../Author';
import Pagination from '../Pagination';
import './TeamGrid.css';

class TeamGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    staffType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10,
      totalCount: props.data.length,
      category: '',
    }

    this.changeSelectCategory = this.changeSelectCategory.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.totalCount !== nextProps.data.length) {
      this.setState({ currentPage: 1, totalCount: nextProps.data.length });
    }
  }

  onPageChange(page) {
    return this.setState({ currentPage: page });
  }

  changeSelectCategory(event) {
    return this.setState({category: event.target.value});
  }

  render() {
    const { currentPage, limit, totalCount } = this.state;
    const { staffType } = this.props;

    const offset = (currentPage - 1) * limit;

    const pagination = {
      currentPage,
      limit,
      offset,
      onPageChange: this.onPageChange,
      totalCount,
    };

    const visibleStaff = this.props.data.slice(offset, (currentPage * limit));

    return(
      <span className="TeamGrid">
        {/*
        <Row className="align-baseline">
          <Col xs={12}>
            <div className="category-select">
              <div className="label text-uppercase">Show:</div>
              <select className="form-select menu" onChange={this.changeSelectCategory} value={category}>
                <option value="clean-energy-issue">Clean Energy Issue</option>
                <option value="economic-issue">Economic Issue</option>
              </select>
            </div>
          </Col>
        </Row>
        */}
        <Row>
          <Col xs={12}>
            <div className="grid">
              {visibleStaff.map((item) => (
                <Author
                  author={item}
                  className={`px-3 mb-5 ${staffType}`}
                  key={item.id}
                  showImage={staffType !== 'board-of-trustees'}
                />
              ))}
            </div>

            {totalCount > 10 &&
              <Pagination
                className="mb-5"
                pagination={pagination}
                total={this.state.totalCount}
              />
            }
          </Col>
        </Row>
      </span>
    );
  }
}

export default TeamGrid;
