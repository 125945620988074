import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash-es/get';

import ApplyForThisPosition from '../ApplyForThisPosition';
import Content from '../Content';
import SocialSharing from '../SocialSharing';

const Internship = ({ internship }) => (
  <div className="Internship">
    <div>
      <Row>
        <Col xs={12}>
          <h1 className="mb-4">{internship.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={internship.positionUrl} />
            <SocialSharing />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Content content={get(internship, 'body.content', 'No content.')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h2 className="mb-4">Interested?</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="apply-and-share mb-4">
            <ApplyForThisPosition url={internship.positionUrl} />
            <SocialSharing />
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Internship;
