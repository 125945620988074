import React, { useEffect } from 'react';
import TechnologyIcon, { technologyStyles } from '../TechnologyIcon';
import classNames from 'classnames';

export default ({onFilter, setCategoriesAreLoaded, selectedCategoryFilter}) => {
  useEffect(() => {
    setCategoriesAreLoaded(true);
  },[]);

  return Object.keys(technologyStyles).map((key) => {
    const iconOption = technologyStyles[key];
    const isActive = iconOption.technologyId === selectedCategoryFilter;

    return (
      <button
        key={iconOption.technologyId}
        className={classNames('FilterButton', {
          active: isActive,
        })}
        style={{ '--background-color': iconOption.color }}
        onClick={() => onFilter(isActive ? '' : iconOption.technologyId)}
        aria-label={isActive ? `Remove ${iconOption.label} filter` : `Filter by ${iconOption.label} projects`}
      >
        <span className="button-text text-theta text-uppercase">{iconOption.label}</span>
        <div className="MapIcons ml-2">
          <TechnologyIcon slug={key} />
        </div>
      </button>
    )
  })
}
