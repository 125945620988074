import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import AppSearch from '../../containers/AppSearch';
import IssuesNav from '../../containers/IssuesNav';

import './AppNav.css';

class AppNav extends Component {
  render() {
    const { issuesMenuOpen, toggleIssues } = this.props;

    return(
      <span>
        {issuesMenuOpen &&
          <IssuesNav fixed />
        }
        <nav className="AppNav">
          <ul className="list-unstyled">
            <MediaQuery maxWidth={991}>
              <li>
                <span className="menu-toggle" onClick={toggleIssues}>
                  {issuesMenuOpen &&
                    <i className="icon icon-close" />
                  }
                  {!issuesMenuOpen &&
                    <FontAwesomeIcon icon={['fas', 'bars']} size="lg" className="c-pointer" />
                  }
                </span>
              </li>
            </MediaQuery>
            <MediaQuery minWidth={992}>
              <li>
                <NavLink to="/issues" onClick={toggleIssues}>
                  Issues
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/donate" className="Button secondary text-uppercase">
                  Donate
                </NavLink>
              </li>
            </MediaQuery>
            <li>
              <AppSearch />
            </li>
          </ul>
        </nav>
      </span>
    )
  }
}

export default AppNav;
