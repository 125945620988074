import React, { useState, useEffect } from 'react';
import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import MapPageHero from './MapPageHero';
import MapPageIntro from './MapPageIntro';
import MapPageCategoryFilter from './MapPageCategoryFilter';
import MapPageProjectSnapshots from './MapPageProjectSnapshots';
import CepMap from './CEPMap';
import MapPageRelatedMaterials from '../../containers/MapPageRelatedMaterials';
import MapPageMethodology from './MapPageMethodology';

const MapPage = ({ content, recentSnapshots, related, energyTechnology }) => {
  const [activeSnapshot, setActiveSnapshot] = useState(null);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState('');
  const [selectedEnergyTechnologyEntry, setSelectedEnergyTechnologyEntry] = useState(null);

  const onFilterCallback = (category) => {
    setSelectedCategoryFilter(category); // Update the selected category // technology in the parent component
  }

  useEffect(() => {
    setSelectedEnergyTechnologyEntry(energyTechnology.find(tech => parseInt(tech.technologyId, 10) === selectedCategoryFilter));
  }, [selectedCategoryFilter])

  return (
    <div className="MapPage">
      <AppHelmet title={content.title} />

      <AppFixedHeader />

      <MapPageHero content={content}/>
      <MapPageIntro content={content}/>
      <MapPageCategoryFilter
        onFilter={onFilterCallback}
        selectedCategoryFilter={selectedCategoryFilter}
        content={content}
      />
      <MapPageProjectSnapshots
        activeSnapshot={activeSnapshot}
        setActiveSnapshot={setActiveSnapshot}
        content={content}
        recentSnapshots={recentSnapshots}
        selectedCategoryFilter={selectedCategoryFilter}
      />
      <CepMap
        caption={content.caption}
        energyTechnology={energyTechnology}
        selectedCategoryFilter={selectedCategoryFilter}
        setSelectedCategoryFilter={setSelectedCategoryFilter}
        setActiveSnapshot={setActiveSnapshot}
        activeSnapshot={activeSnapshot}
        snapshots={[...recentSnapshots, ...content.featuredProjectSnapshots]}
      />

      <MapPageRelatedMaterials
        content={content}
        defaultItems={related}
        selectedEnergyTechnologyEntry={selectedEnergyTechnologyEntry}
      />

      <MapPageMethodology content={content}/>
    </div>
)}

export default MapPage;
